import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Country } from '../../models/country';
import { ManagementService } from '../management.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-country',
  templateUrl: './manage-country.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class ManageCountryComponent implements OnInit {
  updateCountry: boolean = false;
  countries: Country[];
  specificCountry: any = new Country();

  countryId = 0;
  isError: boolean = false;
  isToggle: boolean = false;
  imageUrl: string = "../../../assets/Images/no-image.jpg";
  fileToUpload: File = null;
  photoUrl: any;
  changeImage: boolean = false;
  noImage: boolean = true;
  ////==========base64formatedata Varibles=========////
  base64textString: any;
  FileName: any;
  FileType: any;
  
  addNewCountryPhoto: any = 0;
  countryFlag: any = [];
  noFlag:boolean[] = [];
  //When user clicks on edit it should scroll to top portion of Htnl
  @ViewChild('sectionNeedToScroll') sectionNeedToScroll: ElementRef;
  @ViewChild('removeImageName') removeImageName: ElementRef;
  
  constructor(private _managementService: ManagementService,
    private _toastr: ToastrService, private location: Location, private router: Router, private _route: ActivatedRoute) {

    // let type = this._route.routeConfig.path;
    // let add = type.split('/')[2];
    // if (add == "add") {
    //   this.getAllCountry();
    // }

    this.getAllCountry();

  }

  ngOnInit() {
    window.scrollTo(0, 0)
  }
  getAllCountry() {
    this._managementService.getAllCountry().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        // if(data[i].flagFileName !=null){
        //   data[i].fileType = data[i].flagFileName.split(/\.(?=[^\.]+$)/)[1];
        // }

        data[i].imageSource = this._managementService.PhotoUrl + '/' + data[i].flagFileName +"?" + Math.floor(Math.random() * 100) + 1;

        let photo = data[i].imageSource.split(/[\s/]+/);

        this.countryFlag[i] = photo[4];
        this.countryFlag[i] = this.countryFlag[i].split('?')[0];
        if(this.countryFlag[i] != "null"){
          this.noFlag[i] = false;
        }else{
          data[i].imageSource ='../../assets/Images/no-image.jpg';
          this.noFlag[i] =true;
        }
   
      }
      this.countries = data;
   
    });

  }
  // //Dental Notation
  // getDentalNotation() {
  //   this._managementService.getDentalNotation().subscribe((data: any) => {
  //     this.dentalNotation = data;

  //   })
  // }
  reset(){
    this.removeImageName.nativeElement.value ="";
  }
  onSubmit(f) {

    if (f.form.valid) {
      if (this.addNewCountryPhoto == 0) {
        if (!this.changeImage) {
          if (!this.noImage) {
            let photo = this.photoUrl.split(/[\s/]+/);
            this.specificCountry.imageSource = photo[5];
            this.specificCountry.Base64Data = null;
          }
        }
        else {
          this.specificCountry.Base64Data = this.photoUrl.split(",")[1];
          this.specificCountry.flagFileName = (this.specificCountry.FileName + '.' + this.specificCountry.FileType);
        }
      }
      else {
        if (!this.changeImage) {
          let photo = this.photoUrl.split(/[\s/]+/);
          this.specificCountry.imageSource = photo[5];
          this.specificCountry.Base64Data = null;

        }
        else {
          this.specificCountry.Base64Data = this.photoUrl.split(",")[1];
          this.specificCountry.flagFileName = (this.specificCountry.currencyShortName + '.' + this.specificCountry.FileType);
        }
      }

      this._managementService.addCountryInforamtion(this.specificCountry).subscribe((response: any) => {
        if (response == true) {
          if (this.updateCountry) {
            this._toastr.success("Country information has been updated successfully");
            this.updateCountry = false;

          }
          else {
            this._toastr.success("Country information has been added successfully");
          
          }

          f.submitted = false;
          this.specificCountry = new Country;
          this.noImage = true;
          this.reset();
          this.getAllCountry();
        }
        else {
          this._toastr.warning("Failed to Add/update country information");
        }

      })

    }


  }

  //Edit Country method
  editCountry(countryId) {

    let obj: any = this.countries.filter((x: any) => {
      return x.countryId == countryId;
    });
    this.updateCountry = true;

    this.specificCountry = obj[0];
    if (this.specificCountry.flagFileName == null) {
      this.noImage = true;
      this.addNewCountryPhoto = 0;
    }
    else {
      this.noImage = false;
      this.changeImage = false;
      //this.addNewCountryPhoto = 1;
      this.photoUrl = this._managementService.PhotoUrl +'/' + this.specificCountry.flagFileName +"?" + Math.floor(Math.random() * 100) + 1;;
    }
    this.updateCountry = true;
    //this will provide smooth animation for the scroll
    this.sectionNeedToScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

  }
  // Delete Country method
  deleteCountry(countryId) {
    if (confirm('Please note! This action can NOT be undone. Are you sure you want to delete?')) {
      let object ={
        'CountryId':countryId
      }
      this._managementService.deleteCountry(object).subscribe((response: any) => {
        if (response == true) {
          this.getAllCountry();
          this._toastr.success("Product has been deleted Successfully");
        }
        else {
          this.isError = true;
       
        }
      })
    }
  }
  //error
  error() {
    this.isToggle = !this.isToggle;
  }
  goBack() {
    this.location.back();
  }
  goToCountryPage() {
    this.isError = false;
  }
  // onChangeDentalnotation($event) {

  // }


  handleFileSelect(evt) {
    var File = evt.target.value;
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var FileType = subStringData.split('.')[1];

    this.specificCountry.FileName = FileName;
    this.specificCountry.FileType = FileType;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
      this.noImage = false;
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.specificCountry.Base64Data = btoa(binaryString);
  }

  //Image Upload
  handleFileInput(file: FileList) {
 
    if (file != undefined) {
      this.fileToUpload = file.item(0);
      var File = this.fileToUpload.name;
      this.specificCountry.FileName = File.split('.')[0];
      this.specificCountry.FileType = File.split('.')[1];

      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.photoUrl = event.target.result;
        this.noImage = false;
        this.changeImage = true;
      }
      reader.readAsDataURL(this.fileToUpload);

    }

  }

}
