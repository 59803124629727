import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CasesService } from '../cases.service';

@Component({
  selector: 'app-print-order',
  templateUrl: './print-order.component.html',
  styleUrls: ['../../home/home.component.css'],
})
export class PrintOrderComponent implements OnInit {
  count:number;
  noFilesAttached: boolean = true;
  dealerId: any;
  companyId:any;
  userID: any;
  files: any = [];
  Name:any;
  Id:any;
  Status:string="";
  constructor( private toastr: ToastrService,    private spinner: NgxSpinnerService,    private caseService: CasesService,) {
    setInterval(() => {
      if(this.Status=="InProgress" && this.Id!=0)
      {
        this.CheckStatus();
        //this.getfiles();
      }
     
   }, 10000);
   }

  ngOnInit() {
    this.userID = localStorage.getItem('userid');
    this.dealerId = localStorage.getItem('CompanyId');
    this.companyId=localStorage.getItem('DealerId');
    this.getfiles();
  }
  submit()
  {
    let object = {
      'NoOfCopies': this.count,
      'DentistCompanyId': this.dealerId,
      'DealerId':this.companyId,
      'PrintBy': this.userID,
       'Id':0

    }
    if(this.count<=0)
    {
      this.toastr.error("Minimum Copy is One");
      return;
    }
    // if(this.Name==null||this.Name==undefined)
    // {
    //   this.toastr.error("Please Add Name");
    //   return;
    // }
    this.spinner.show();
    this.Id=0;
    this.Status="InProgress";
    this.caseService.CreateFile(this.Id,this.count,this.companyId,this.dealerId,this.userID,this.Status).subscribe(data => {
      if(data.isSuccess)
      {
        this.Id=data.data;
        object.Id=data.data;
      this.caseService.CreateWorkOrderPrint(object).subscribe(data => {
        if(data.isSuccess)
        {
          this.CheckStatus();
        //   this.getfiles();
        // this.toastr.success(data.message);
        }
        else{          
          this.toastr.error(data.message);
          this.spinner.hide();
        }       
      }, err => {;
      
       });
      }
   });
    
  
   
     // this.ionViewDidLoad();
  }
 
downloadAttachedFiles(item) {
    var FileName= item.FilePath
  
  this.caseService.GetPdffile(FileName).subscribe((data: any) => {

    if (data != null) {
      const linkSource = 'data:application/pdf;base64,' + data;
      const downloadLink = document.createElement("a");
      const fileName = item.FileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    else {
      this.toastr.info("File Path Not Found");
    }
  })

}
ionViewDidLoad(){
  this.spinner.show();
  setInterval(() => {
     this.getfiles();
  }, 10000);
}
deleteCase(Id)
{
  this.caseService.DeleteFile(Id).subscribe((data: any) => {
this.getfiles();
   
  })
}
getfiles(){
  this.count=null;
  this.Name=null;
  this.spinner.show();
  this.caseService.GetAllFiles(this.companyId,this.dealerId).subscribe((data: any) => {
    if (data.length == 0) {
      this.noFilesAttached = true;
      this.files = data;
    } else {
      this.noFilesAttached = false;
      this.files = data;

    }
    this.spinner.hide();

  });
  
  
}
printPdf(item)
  {
    var FileName= item.FilePath
    this.spinner.show();
    this.caseService.printPdf(FileName).subscribe((response: any) => {
      if (response != null) { 
        var blob1 = new Blob([response], {type: 'application/pdf'});
        const blobUrl = URL.createObjectURL(blob1);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          this.spinner.hide();
          iframe.contentWindow.print();
        
      }
      else {
        this.toastr.info("File Path Not Found");
      }
    })
  }
  CheckStatus()
  {
    // if(this.Id!=0){
    this.caseService.CheckStatus(this.Id).subscribe((response: any) => {
        if(response.Status=="Completed")
        {
          this.spinner.show();
          this.Status="Completed";
          this.getfiles();
          this.toastr.success("work order print created successfully");
          this.printPdf(response);
        }
        else if(response.Status=="Error")
        {
          this.getfiles();
          this.toastr.success("Something went wrong pls try again"); 
        }
      
      
         
        });
      // };
  }

}
