import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-dentist-upload-files",
  templateUrl: "./dentist-upload-files.component.html",
  styleUrls: ["./dentist-upload-files.component.scss"],
})
export class DentistUploadFilesComponent {
  @Input() files;
  @Input() single;
  @Output() FileuploadData = new EventEmitter<any>();
  //files: any[] = [];
  totalFileSize: any = [];
  base64Data: any;
  file: any;
  fileType: any;

  constructor(private toastr: ToastrService) {}
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    //this.single?this.files=[]:'';
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    //this.single?this.files=[]:'';
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  // prepareFilesList(files: Array<any>) {

  //   if (files[0].size > 20000000) {
  //     this.toastr.warning("File size should be less than 25Mb");
  //     return;
  //   }
  //   this.totalFileSize.push(files[0].size);
  //   let totalMemory = this.totalFileSize.reduce((sum, item) => sum + parseInt(item), 0);
  //   if (totalMemory > 150000000) {
  //     this.toastr.warning("Total File size should be less than 150Mb");
  //     return;
  //   }

  //   let type=[];
  //   type= files[0].name.split(/[\s.]+/);
  //   this.fileType=type[type.length-1];
  //   this.file = files[0];
  //   if (files && this.file) {
  //     var reader = new FileReader();
  //     reader.onload = this._handleReaderLoaded.bind(this);
  //     reader.readAsBinaryString(this.file);
  //   }

  //   setTimeout(() => {

  //     for (const item of files) {
  //       item.progress = 0;
  //       item.base64Data = this.base64Data;
  //       item.fileType=this.fileType;
  //       this.files.push(item);
  //     }
  //     this.uploadFilesSimulator(0);
  //     this.FileuploadData.emit(this.files);
  //   }, 100);

  // }

  prepareFilesList(files: Array<any>) {
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > 101000000) {
        this.toastr.warning("File size should be less than 100Mb");
        return;
      }
      this.totalFileSize.push(files[x].size);
      let totalMemory = this.totalFileSize.reduce(
        (sum, item) => sum + parseInt(item),
        0
      );
      if (totalMemory > 150000000) {
        this.toastr.warning("Total File size should be less than 150Mb");
        return;
      }

      let type = [];
      type = files[x].name.split(/[\s.]+/);
      this.fileType = type[type.length - 1];
      this.file = files[x];

      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e: any) => {
        this.base64Data = e.target.result;
        this.base64Data = this.base64Data.split("base64,")[1];

        files[x].progress = 0;
        files[x].base64Data = this.base64Data;
        files[x].fileType = this.fileType;
        this.files.push(files[x]);
      };
      myReader.readAsDataURL(this.file);
    }

    setTimeout(() => {
      this.uploadFilesSimulator(0);
      this.FileuploadData.emit(this.files);
    }, 1000);

    // setTimeout(() => {

    //   for (const item of files) {
    //     item.progress = 0;
    //     item.base64Data = this.base64Data;
    //     item.fileType=this.fileType;
    //     this.files.push(item);
    //   }
    //   this.uploadFilesSimulator(0);
    //   this.FileuploadData.emit(this.files);
    // }, 100);
  }

  // prepareFilesList(files: Array<any>) {

  //   var myReader: FileReader = new FileReader();
  //   myReader.onloadend = (e) => {
  //     for (let x = 0; x < files.length; x++) {
  //       if (files[x].size > 20000000) {
  //         this.toastr.warning("File size should be less than 25Mb");
  //         return;
  //       }
  //       this.totalFileSize.push(files[x].size);
  //       let totalMemory = this.totalFileSize.reduce((sum, item) => sum + parseInt(item), 0);
  //       if (totalMemory > 150000000) {
  //         this.toastr.warning("Total File size should be less than 150Mb");
  //         return;
  //       }

  //       let type = [];
  //       type = files[x].name.split(/[\s.]+/);
  //       this.fileType = type[type.length - 1];
  //       this.file = files[x];
  //       this.base64Data = myReader.result;
  //       this.base64Data = this.base64Data.split('base64,')[1];

  //       files[x].progress = 0;
  //       files[x].base64Data = this.base64Data;
  //       files[x].fileType = this.fileType;
  //       this.files.push(files[x]);
  //     }
  //     this.uploadFilesSimulator(0);
  //     this.FileuploadData.emit(this.files);
  //   }
  //   myReader.readAsDataURL(this.file);

  // }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const fileSize =
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];

    return fileSize;
  }
}
