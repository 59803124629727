export  enum CaseStatus
    {
       // [Display(Name = "Unknown")]
        Unknown = -1,

      //  [Display(Name = "Not set")]
        NotSet = 0,

      //  [Display(Name = "Open")]
        Open = 1,

      //  [Display(Name = "Closed")]
        Closed = 2,

      //  [Display(Name = "Deleted")]
        Deleted = 3,
    
        Altered =4
    }     
