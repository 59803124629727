import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PrintService } from "./cases/print.service";
//import { ProductsModule } from '../app/products/products.module';
//import { ShipmentsModule } from '../app/shipments/shipments.module';
//import { CompaniesModule } from '../app/companies/companies.module';
//import { CasesModule } from '../app/cases/cases.module';
import { TodoModule } from "../app/todo/todo.module";
import { HomeModule } from "../app/home/home.module";
import { LoginComponent } from "../app/role/login/login.component";
import { ChangePasswordComponent } from "../app/role/change-password/change-password.component";
import { RoleModule } from "../app/role/role.module";
import { FormsModule } from "@angular/forms";
import { ForgotPasswordComponent } from "../app/role/forgot-password/forgot-password.component";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../app/auth.guard";
//import { ManagementModule } from './management/management.module';
//import {ReportsModule} from '../app/reports/reports.module';
import { ConfigService } from "./app.config";
import { ResetpasswordComponent } from "../app/role/resetpassword/resetpassword.component";
import { SetPasswordComponent } from "../app/role/set-password/set-password.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "./error-interceptor";
import { BaseService } from "./services/base.service";
import { InvoiceComponent } from "./invoice/invoice.component";
import { PrintLayoutComponent } from "./print-layout/print-layout.component";
import { PaginationService } from "./services/pagination.service";
import { ShareModule } from "./share/share.module";
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { OnlyNumber } from "./directives/onlyNumber";
import { TrackingShipmentComponent } from "./shared/tracking-shipment/tracking-shipment.component";
import { CompaniesService } from "./companies/companies.service";
import { CompaniesModule } from "./companies/companies.module";
import { OrderPickupComponent } from "./cases/order-pickup/order-pickup.component";
import { CasesService } from "./cases/cases.service";
import { TooltipModule } from "ng2-tooltip-directive";
import { OrderRequestlistComponent } from "./cases/order-requestlist/order-requestlist.component";
import { ProductsModule } from "./products/products.module";
import { ShipmentsModule } from "./shipments/shipments.module";
import { ManagementModule } from "./management/management.module";
import { ReportsModule } from "./reports/reports.module";
import { CasesModule } from "./cases/cases.module";
import { PreviousUrlGuard } from "./previousurl.guard";
import { MycurrencyPipe } from "./cases/customFiles/custom.currencypipe";
import { SelectReqiuredValidatorDirective } from "./directives/select-required-validator.directive";
import { QuestionComponent } from "./role/question/question.component";
import { AdminnoticeComponent } from "../app/admin/adminnotice/adminnotice.component";
// import { ModalModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CKEditorModule } from "ng2-ckeditor";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { EmployeeAdminNoticeComponent } from "./employee-admin-notice/employee-admin-notice.component";
import { PrintOrderComponent } from "./cases/print-order/print-order.component";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "angular2-moment"; // optional, provides moment-style pipes for date formatting
import { OrderPrintComponent } from "./cases/order-print/order-print.component";
import { ManageMeetingsComponent } from "./manage-meetings/manage-meetings.component";
import { ManageTasksComponent } from "./manage-tasks/manage-tasks.component";
import { AddTaskComponent } from "./manage-tasks/add-task/add-task.component";
import { dragDropTaskComponent } from "./manage-tasks/drag-drop-task/drag-drop-task.component";
import { CrmModule } from "./CRM/crm.module";
import { SalesRepDashboardComponent } from "./sales-rep-dashboard/sales-rep-dashboard.component";
import { NgxPaginationModule } from "ngx-pagination";
import {MatRadioModule} from '@angular/material/radio';
import { ChartsModule } from 'ng2-charts';
import { CICModule } from "./CIC/cic.module";
import { CICService } from "./CIC/cic.service";

const appRoutes: Routes = [
  /* {path:'management' , loadChildren:'./management/management.module#ManagementModule'},
  {path:'denthome' , loadChildren:'./home/home.module#HomeModule'},
  {path:'products' , loadChildren:'./products/products.module#ProductsModule'},
  {path:'companies' , loadChildren:'./companies/companies.module#CompaniesModule'},
  {path:'shipments' , loadChildren:'./shipments/shipments.module#ShipmentsModule'},
  {path:'cases' , loadChildren:'./cases/cases.module#CasesModule'},
  {path:'reports' , loadChildren:'./reports/reports.module#ReportsModule'}, */
  { path: "login", component: LoginComponent },
  {
    path: "changepassword",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard, PreviousUrlGuard],
  },
  { path: "", component: LoginComponent },

  {
    path: "companies",
    loadChildren: "./companies/companies.module#CompaniesModule",
  },
  { path: "reports", loadChildren: "./reports/reports.module#ReportsModule" },
  {
    path: "shipments",
    loadChildren: "./shipments/shipments.module#ShipmentsModule",
  },
  {
    path: "products",
    loadChildren: "./products/products.module#ProductsModule",
  },
  { path: "denthome", loadChildren: "./home/home.module#HomeModule" },
  // { path: 'management', loadChildren: './management/management.module#ManagementModule' },
  { path: "todo", loadChildren: "./todo/todo.module#TodoModule" },
  // { path: 'dashboard-list-view', component: DashboardListViewComponent,canActivate:[CasedblistviewGuard,PreviousUrlGuard]},

  { path: "cases", loadChildren: "./cases/cases.module#CasesModule" },
  //{ path: 'dashboard-list-view', loadChildren: './cases/cases.module#CasesModule' },

  {
    path: "forgotpassword",
    component: ForgotPasswordComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "permissiondeined",
    component: PageNotFoundComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "order-print",
    component: OrderPrintComponent,
    canActivate: [PreviousUrlGuard],
  },
  { path: "setpassword/:username", component: SetPasswordComponent },
  { path: "reset-password/:username", component: ResetpasswordComponent },
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [
      {
        path: "invoice/:invoiceIds",
        component: InvoiceComponent,
        canActivate: [PreviousUrlGuard],
      },
      {
        path: "ordereprint/:invoiceIds",
        component: InvoiceComponent,
        canActivate: [PreviousUrlGuard],
      },
    ],
  },
  {
    path: "order-pickup",
    component: OrderPickupComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "print-order",
    component: PrintOrderComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "order-request",
    component: OrderRequestlistComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "salesrepdashboard",
    component: SalesRepDashboardComponent,
    canActivate: [PreviousUrlGuard],
  },
  { path: "crm", loadChildren: "./CRM/crm.module#CrmModule" },
  {
    path: "managemeetings",
    component: ManageMeetingsComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "managetasks",
    component: ManageTasksComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "managetasks/tasks",
    component: dragDropTaskComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "managetasks/addnewtask",
    component: AddTaskComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "managetasks/updatecrmtask/:type/:id",
    component: AddTaskComponent,
    canActivate: [PreviousUrlGuard],
  },
  //Admin Notice
  {
    path: "adminnotice",
    component: AdminnoticeComponent,
    canActivate: [PreviousUrlGuard],
  },
  //Employee Admin Notice
  {
    path: "employeeadminnotice",
    component: EmployeeAdminNoticeComponent,
    canActivate: [PreviousUrlGuard],
  },
  // CIC
  { path: "cic", loadChildren: "./CIC/cic.module#CICModule" },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetpasswordComponent,
    SetPasswordComponent,
    InvoiceComponent,
    PrintLayoutComponent,
    OrderPickupComponent,
    OrderRequestlistComponent,
    PrintOrderComponent,
    MycurrencyPipe,
    SelectReqiuredValidatorDirective,
    QuestionComponent,
    AdminnoticeComponent,
    EmployeeAdminNoticeComponent,
    OrderPrintComponent,
    ManageTasksComponent,
    AddTaskComponent,
    dragDropTaskComponent,
    ManageMeetingsComponent,
    SalesRepDashboardComponent,

    // OrderByPipe
  ],

  imports: [
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).

    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload" }),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    FormsModule,
    FormsModule,
    BrowserModule,
    CKEditorModule,
    PerfectScrollbarModule,
    DragDropModule,
    // ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,    
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    HttpClientModule,
    //ProductsModule,
    //ShipmentsModule,
    //DentistsModule,
    //CompaniesModule,
    //CasesModule,
    //TodoModule,
    // HomeModule,
    //DashboardModule,
    RoleModule,
    ManagementModule,
    //ReportsModule,
    ChartsModule,
    MatRadioModule,
    TooltipModule,
    ShareModule,
    CrmModule,
    CICModule,
    NgxPaginationModule
  ],
  providers: [
    CompaniesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    LoginComponent,
    ConfigService,
    PrintService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    BaseService,
    PaginationService,
    CasesService,
    CICService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
