export class ExportLineItems {
       
    ExportLineItem:Array<ExportLineItem> = [];  
}

export class ExportLineItem {
    CommodityCode: any; 
    ItemNumber: any; 
    Quantity: any; 
    QuantityUnitOfMeasurement: any; 
    ItemDescription: any; 
    UnitPrice: any; 
    NetWeight: any; 
    GrossWeight: any; 
    ManufacturingCountryCode: any;  
} 
 