import { Component, OnInit, TemplateRef } from "@angular/core";
import { ManageTasksService } from "./manage-tasks.service";
import { TaskSharedService } from "./tasks-share.service";
import { Router } from "@angular/router";
import { ReminderDate, TaskStatus,TaskStatusLabelMapping } from "../enum/statusEnum";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TaskPerPage } from '../enum/pagesEnum';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { formatDate } from "@angular/common";

@Component({
  selector: "app-manage-tasks",
  templateUrl: "./manage-tasks.component.html",
  styleUrls: ["../home/home.component.css"],
})
export class ManageTasksComponent implements OnInit {
  UserId:any;
  modalRef: BsModalRef;
  crmTasksData = [];
  FilterTaskData = [];
  type: any;
  taskId:any;
  statustaskLabelMapping = TaskStatusLabelMapping;
  statustask = Object.values(TaskStatus).filter(value => typeof value === 'number');
  filterTask:any;
  // variable for task asc/desc
  settask: string = '';
  reverse: boolean = false;
  //variable for task per page
  pageSize:any = TaskPerPage.first;
  first = TaskPerPage.first;
  second = TaskPerPage.second;
  third = TaskPerPage.third;
  firstRow = true;
  secondRow = false;
  thirdRow = false;
  // variable for task delete 
  taskid: number = 0;
   //date range variable
   dateCustomClasses: DatepickerDateCustomClasses[];
   fromDate: any = null;
   toDate: any = null;
   statusvalue:any;
   ReminderDate: any;
   DateForReminder: any;
   reminder: boolean = false;
   ReminderDay = ReminderDate.reminderday;

  constructor(private manageTasksService: ManageTasksService,private tasksharedservice:TaskSharedService, private router: Router, private toastr:ToastrService,
    private spinner:NgxSpinnerService, private modalService: BsModalService)
    {
      let dt = new Date();
      dt.setDate( dt.getDate() + this.ReminderDay );
      this.ReminderDate = formatDate(dt,'yyyy-MM-dd','en_US');
      let dt1 = new Date();
      dt1.setDate( dt1.getDate() - this.ReminderDay );
      this.DateForReminder = formatDate(dt1,'yyyy-MM-dd','en_us');
    }

  ngOnInit() {
    this.UserId = localStorage.getItem("userid")
    this.statusvalue = "all";
    this.pageSize = TaskPerPage.first; 
    this.filterTask = this.tasksharedservice.searchTitle;
    this.fromDate = this.tasksharedservice.fromDate;
    this.toDate = this.tasksharedservice.toDate;
    this.getCRMTasksData(); 
  }

  getCRMTasksData() {
    this.spinner.show();
    let object = {
      'FromDate': this.fromDate,
      'ToDate': this.toDate,
      'UserId': this.UserId
    }
    this.manageTasksService.getCRMTasks(object).subscribe((data: any) => {
      this.crmTasksData = [];
      this.FilterTaskData = [];
      let TasksData = data.crmTaskList;
      let taskcommentdata = data.taskComment;
      TasksData.forEach(ele => {
        if(taskcommentdata != null && taskcommentdata.length > 0){
          taskcommentdata.forEach(ele2 => {
            if(ele.id == ele2.taskId){
              if(ele2.isReminder == true && (this.ReminderDate >= formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') && formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') >= this.DateForReminder)){
               this.reminder = true;         
              } 
            }           
          });
        }
        if(this.reminder == true){
          ele.isActive = true;
          this.reminder = false;
        } else{
          ele.isActive = false;
        }
        this.crmTasksData.push(ele);
        this.FilterTaskData.push(ele);
      });  
      this.crmTasksData.sort((a, b) => (
        b.isActive - a.isActive
      ));  
      this.FilterTaskData.sort((a, b) => (
        b.isActive - a.isActive
      ));
      this.searchByStatus(this.statusvalue);
      this.spinner.hide();
    }, (err) => { this.toastr.error(err);
      this.spinner.hide();
    });
  }

  ListToGrid(){
    this.tasksharedservice.searchTitle = this.filterTask;
    this.tasksharedservice.fromDate = this.fromDate;
    this.tasksharedservice.toDate = this.toDate;
    this.router.navigate([
      "/managetasks/tasks",
    ]);
  }

  editCRMTasks(id){
    this.type = "edit";
    this.router.navigate([
      "managetasks/updatecrmtask/" + this.type + "/" + id,
    ]);
  }

  trashCRMTasks(Id, template: TemplateRef<any>){
    this.taskId = Id;
    this.modalRef = this.modalService.show(template);
  }
  decline() {
    this.modalRef.hide();
  }
  confirm() {
      let object = {
        taskId: this.taskId,
      };
      this.manageTasksService.trashTask(object).subscribe(
        (response: any) => {
          if (response == 1) {
            this.getCRMTasksData();
            this.toastr.success("Task has been deleted Successfully");
          }
          else{
            this.toastr.error("Something is wrong please contact to administration");
          }
        },
        (err) => {
          this.toastr.error(err);
        });
      this.modalRef.hide();
  }

  searchTasks(){
   if(this.filterTask == undefined || this.filterTask == null){
    if(this.statusvalue == undefined || this.statusvalue == "all"){
      this.crmTasksData = this.FilterTaskData;
    }
    else{
      this.crmTasksData = this.FilterTaskData.filter((res: any) => {
        return (res.status == this.statusvalue)
      })
    }
   }
   else{  
    if(this.statusvalue == undefined || this.statusvalue == "all"){
      this.crmTasksData = this.FilterTaskData.filter((res: any) => {
        return (res.title.toLowerCase().includes(this.filterTask.toLowerCase()))
      })
    }   
    else{
      this.crmTasksData = this.FilterTaskData.filter((res: any) => {
        return (res.title.toLowerCase().includes(this.filterTask.toLowerCase()) && res.status == this.statusvalue)
      })
    }
   }
  }

  searchByStatus(value){
    this.statusvalue=value;
    this.crmTasksData = [];
    if(this.statusvalue == undefined || this.statusvalue == "all"){
      if(this.filterTask == undefined || this.filterTask == null){
        this.crmTasksData = this.FilterTaskData;
       }
       else{     
        this.crmTasksData = this.FilterTaskData.filter((res: any) => {
          return (res.title.toLowerCase().includes(this.filterTask.toLowerCase()))
        })
       }
    }
    else{   
      if(this.filterTask == undefined || this.filterTask == null){
      this.crmTasksData = this.FilterTaskData.filter((res: any) => {
        return (res.status == this.statusvalue)
      })
     }
     else{
      this.crmTasksData = this.FilterTaskData.filter((res: any) => {
        return (res.title.toLowerCase().includes(this.filterTask.toLowerCase()) && res.status == this.statusvalue)
      })
     }
    }
  }

  searchTaskByDateRange(fromdate,todate){
    this.fromDate = fromdate;
    this.toDate = todate;
    if(this.fromDate == null || this.toDate == null){
      this.fromDate = null;
      this.toDate = null;
      this.getCRMTasksData();
    }
    else{      
      this.fromDate = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
      this.toDate = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + this.toDate.getDate();
      this.getCRMTasksData();
    }
  }

  downloadTaskAttachment(item) {        
     let object = {
      'taskid': item.id
    }
   //Download Service here 
    this.manageTasksService.GetTaskUploadedFiles(object).subscribe((data: any) => {
      if (data != null) {
        for (let a = 0; a < data.crmTaskAttachment.length; a++) {
          const linkSource = 'data:application/pdf;base64,' + data.crmTaskAttachment[a].file;
          const downloadLink = document.createElement("a");
          const fileName = data.crmTaskAttachment[a].fileName;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
      else {
        this.toastr.info("File Path Not Found");
      }
    })
  }

  setPageSize(size) {
    if (size == TaskPerPage.first) {
      this.pageSize = size;
      this.firstRow = true;
      this.secondRow = false;
      this.thirdRow = false;
    } else if (size == TaskPerPage.second) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = true;
      this.thirdRow = false;
    } else if (size == TaskPerPage.third) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = false;
      this.thirdRow = true;
    }
  }

  setOrder(value: string) {
    if (this.settask === value) {
      this.reverse = !this.reverse;
    }
    this.settask = value;
  }
  

}
