export class addNewGroupMessaging {
    MessageGroupId: number;
    name:string;
    description:string; 
    CreateDate: Date;
    LastSent:Date;
    CreatedByTeethRUsUserId:string
    fromTelephone: string;
    fromEmail:string;
    TeethRUsUserIds:string;
}