import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Htmlconverter } from './order-print/htmlconverter';
@Injectable()

export class CasesService {
    private url = '';
    public baseUrl = '';
    // public prodCompanyUrl='';
    CasePdfFileUrl: any;
    searchField;
    searchCaseBy;
    searchId;
    currentTab;
    caseStatus
    CountryPhotoUrl: any;
    companyPhotoUrl: any;
    thumbnailPhotoUrl: any;
    thumbnailPhotoUrlfordentist:any;
    userID: any;
    searchIdForMaterial;
    currentPage;
    casesPerPage;
    firstRow;
    secondRow;
    thirdRow;

    constructor(private http: HttpClient, private configService: ConfigService) {
        this.userID = localStorage.getItem('userid');
        this.baseUrl = configService.getServiceBaseUrl();
        this.CasePdfFileUrl = this.baseUrl + 'Uploads/CasePdf/';
        this.thumbnailPhotoUrl=this.baseUrl+'Uploads/CaseDataFiles/'
        this.thumbnailPhotoUrlfordentist=this.baseUrl+'Uploads/DentistUploadedFiles/'
        this.CountryPhotoUrl = this.baseUrl + 'Uploads/country';
        this.companyPhotoUrl = this.baseUrl + 'Uploads/DealersLogo';
        // this.prodCompanyUrl=this.baseUrl + 'Uploads/DealersLogo';

    }

    //Testing For Scanned Purpose
    saveScannedFile(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/SaveScannedFile';
        return this.http.post(this.url, object)
    }

    getProduction(userId, dealerId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetProductions?userId=' + userId + '&dealerId=' + dealerId;
        return this.http.get(this.url)
    }
    getShipmentNumberAndIndex(dealerId, prodId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetProductionDetail?dealerId=' + dealerId + '&productionid=' + prodId;
        return this.http.get(this.url)
    }
    getCompanyNumber(companyNumber): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetDentistUsers?companyNumber=' + companyNumber;
        return this.http.get(this.url)
    }
    getDealerList(): Observable<any> {
        this.url = this.baseUrl + 'api/Product/GetDealers';
        return this.http.get(this.url);
    }
    getCompanyDetails(dealerId, userId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetDentistCompanies?dealerId=' + dealerId + '&userId=' + userId;
        return this.http.get(this.url)
    }
    getDentist(companyId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetDentistsUser?companyId=' + companyId;
        return this.http.get(this.url)
    }
    searchProductDetails(dentistCountryId, priceListId, IsOnlineOrdering): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetArticals?dentistCountryId=' + dentistCountryId + '&priceListId=' + priceListId + '&isOnlineOrdering=' + IsOnlineOrdering;
        return this.http.get(this.url)
    }
    getArticalNoForDentist(dentistCountryId, priceListId, IsOnlineOrdering): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetArticals?dentistCountryId=' + dentistCountryId + '&priceListId=' + priceListId + '&isOnlineOrdering=' + IsOnlineOrdering;
        return this.http.get(this.url)
    }

    createNewCase(caseDetails): Observable<any> {
        this.url = this.baseUrl + 'api/Order/CreateNewCase';
        return this.http.post(this.url, caseDetails);
    }

    getCaseDetailForUpdate(orderId, userId, flag) {
        this.url = this.baseUrl + 'api/Order/GetCaseDetail?orderId=' + orderId + '&UserId=' + userId + '&flag=' + flag;
        return this.http.get(this.url);
    }

    GetCaseDetail(id, userId, flag) {
        this.url = this.baseUrl + 'api/Order/GetCaseDetail?orderId=' + id + '&userId=' + userId + '&flag=' + flag;
        return this.http.get(this.url);

    }
    //Check Shipment is Available in DB 
    checkShipmentAvailability(prodId, dealerId) {
        this.url = this.baseUrl + 'api/Order/CheckShipmentAvailability?ProductionId=' + prodId + '&DealerId=' + dealerId;
        return this.http.get(this.url);
    }

    //Get Order Rows When required
    getCaseRows(orderId) {
        this.url = this.baseUrl + 'api/Order/GetOrderRow?orderId=' + orderId;
        return this.http.get(this.url);
    }

    //Get Comments
    getComments() {
        this.url = this.baseUrl + 'api/Order/GetComments';
        return this.http.get(this.url);
    }
    //Get external Comments
    GetExternalComments(externalId) {
        this.url = this.baseUrl + 'api/Order/GetExternalComments?id=' + externalId;
        return this.http.get(this.url);
    }
    //Get Company Logo and Welcome Text For Dentist Login
    getCompanyLogo(DealerCompanyId, DentistCompanyId) {
        this.url = this.baseUrl + 'api/Company/GetCompanyLogo?companyId=' + DealerCompanyId + '&dentistCompanyId=' + DentistCompanyId;

        return this.http.get(this.url);
    }

    //Delete Case
    deleteCase(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/DeleteCaseOrder'
        return this.http.post(this.url, object)
    }

    //Get All Cases
    getCases(caseSearchData): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetCases';
        return this.http.post(this.url, caseSearchData)
    }

    //Get Welcome Logo and Welcome Text For Dentist Login
    getLogoAndWelcomeText(companyId) {
        this.url = this.baseUrl + 'api/Company/GetWelcomeTextForDentist?companyId=' + companyId;
        return this.http.get(this.url)
    }

    getOrderRowCases(orderId, userId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetOrderRowsForCase?orderId=' + orderId + '&userId=' + userId;
        return this.http.get(this.url)
    }

    deleteAttachedFile(object) {
        this.url = this.baseUrl + 'api/Order/DeleteAttachedFile';
        return this.http.post(this.url, object);
    }

    generateCasePdf(Object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/CreatePdfNotes';
        return this.http.post(this.url, Object);
    }
    
    getAttachedFilesByOrderId(id): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAttachedFilesByOrderId?orderId=' + id;
        return this.http.get(this.url);
    }

    //Fetch Last Created Records of Cases
    getLastCreatedCaseDetails(userId, dealerId) {
        this.url = this.baseUrl + 'api/Order/GetLastCreatedCaseDetails?userId=' + userId + '&dealerId=' + dealerId;
        return this.http.get(this.url);
    }

    //Ranga implemented below methods
    getCaseDashBoardStages() {
        this.url = this.baseUrl + 'api/Order/GetCaseDashBoardStages';
        return this.http.get(this.url);
    }

    getCasesCountByStages(DealerCompanyId) {
        this.url = this.baseUrl + 'api/Order/GetCasesCountByStages?DealerCompanyId=' + DealerCompanyId;
        return this.http.get(this.url);
    }

    getCasesByDealerCompanyId(object) {
        this.url = this.baseUrl + 'api/Order/GetCasesByDealerCompanyId';
        return this.http.post(this.url, object);
    }

    updateCaseStage(data): Observable<any> {
        this.url = this.baseUrl + 'api/Order/UpdateCaseStage';
        return this.http.post(this.url, data);
    }

    getOrderAudit(id, orderId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/OrderAudit?userId=' + id + '&OrderId=' + orderId;
        return this.http.get(this.url);

    }

    SendMessage(item): Observable<any> {

        this.url = this.baseUrl + 'api/AppUser/SendSms';
        return this.http.post(this.url, item);

    }

    updateCaseStatus(data) {
        this.url = this.baseUrl + 'api/Order/UpdateCaseStatus';
        return this.http.post(this.url, data);
    }

    selectNextCases(caseperPage) {
        this.url = this.baseUrl + 'api/Order/SelectNextCases';
        return this.http.get(this.url);
    }
    Getpdf(htmlconvert:Htmlconverter):Observable<Blob>{
        // this.url = this.baseUrl + 'api/Reports/DownloadPdf';
        // return this.http.post(this.url, htmlconvert);
       return this.http.post(`${this.baseUrl}api/Reports/DownloadPdf`,htmlconvert,{responseType:'blob'});
      }


    //   Case View Details Api

    //Save Communication Post

    //Delete Communication Post
    deleteCommunicationList(obj): Observable<any> {
        this.url = this.baseUrl + 'api/Order/DeleteCommunicationList';
        return this.http.post(this.url, obj);
    }

    getCommunicationCases(id, roleType, userId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetCommunicationCase?orderId=' + id + '&roleType=' + roleType + '&userId=' + userId;
        return this.http.get(this.url);
    }

    //getMaterialForCases
    getMaterialForCases(dentistCountryId, priceListId, isOnlineOrdering): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetMaterialsForCases?dentistCountryId=' + dentistCountryId + '&priceListId=' + priceListId + '&isOnlineOrdering=' + isOnlineOrdering;
        return this.http.get(this.url);
    }

    createCommunicationCase(message): Observable<any> {
       // this.url = this.baseUrl + 'api/Order/SaveCommunicationCase';
       // return this.http.post(this.url, message,obj);
        return this.http.post<any>(`${this.baseUrl}api/Order/SaveCommunicationCase`, message);
    }
    createCommunicationCaseForDelayed(message): Observable<any> {
        this.url = this.baseUrl + 'api/Order/DelayedMessage';
        return this.http.post(this.url, message);
    }

    getCaseMaterialCases(id) {
        this.url = this.baseUrl + 'api/Order/GetCaseMaterial?orderId=' + id;

        return this.http.get(this.url);
    }
    getCaseMaterialConstruction(id) {
        this.url = this.baseUrl + 'api/Order/GetMaterialConstruction?orderId=' + id;
        return this.http.get(this.url);
    }

    addOrUpdateMaterial(data, orderId, dealerCompanyId, total, userID): Observable<any> {
        this.url = this.baseUrl + 'api/Order/AddOrUpdateMaterial?orderId=' + orderId + '&DealerId=' + dealerCompanyId + '&totalCost=' + total + '&TeethRUsUserId=' + userID;
        return this.http.post(this.url, data)
    }

    saveRiskAnalysis(data) {
        this.url = this.baseUrl + 'api/Order/SaveRiskAnalysisData';
        return this.http.post(this.url, data)
    }

    addOrUpdateAttachedFiles(data) {
        this.url = this.baseUrl + 'api/Order/AddOrUpdateAttachedFiles';
        return this.http.post(this.url, data)
    }

    getAttachedCaseFile(id) {
        this.url = this.baseUrl + 'api/Order/GetAttachedCaseFile?orderId=' + id;

        return this.http.get(this.url);
    }

    setDeliveryDate(data) {
        this.url = this.baseUrl + 'api/Order/UpdateDeliveryDate';
        return this.http.post(this.url, data)
    }

    //Add Case On Watch List
    addCaseOnWatchList(data) {
        this.url = this.baseUrl + 'api/Order/CreateFlag';
        return this.http.post(this.url, data)
    }
    markCasesAsFinished(object) {
        this.url = this.baseUrl + 'api/Order/MarkCasesAsFinished';
        return this.http.post(this.url, object)
    }

    //Order Pick Up
    orderPickUpForDentist(object) {
        this.url = this.baseUrl + 'api/Order/OrderPickup';
        return this.http.post(this.url, object)
    }
    // Case Type is Remake Continue and Adjustment
    CopyCase_Remake_Adjust_Comtinue(object) {
        this.url = this.baseUrl + 'api/Order/CopyCase_Remake_Adjust_Comtinue';
        return this.http.post(this.url, object)
    }
    remakeCaseSpecialNote(obj) {
        this.url = this.baseUrl + 'api/Order/SaveSpecialCaseNoteForRemakeCase';
        return this.http.post(this.url, obj)
    }

    //Search Case By Delivery Number
    searchCasesByDeliveryNumber(caseSearchData): Observable<any> {
        this.url = this.baseUrl + 'api/Order/SearchCases';
        return this.http.post(this.url, caseSearchData)
    }
    searchPerticularCasesByCategory(caseSearchData): Observable<any> {
        this.url = this.baseUrl + 'api/Order/SearchParticularCasesByCategory';
        return this.http.post(this.url, caseSearchData)
    }
    getShipmentsNumbersForIncomingCases(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetShipmentsNumbersForIncomingCases';
        return this.http.post(this.url, object)
    }
    moveToShipmentFormIncomingCases(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/MoveToShipmentFormIncomingCases';
        return this.http.post(this.url, object)
    }
    getDentistCompaniesForFilterSearch(dealerId): Observable<any> {
        this.url = this.baseUrl + 'api/company/GetDentistCompaniesForSearchCaseFilter?dealerId=' + dealerId;
        return this.http.get(this.url)
    }
    getWorkOrderFile(fileName): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetWorkOrderFileData?fileName=' + fileName;
        return this.http.get(this.url)
    }

    GetDentistDHLDetails(CompanyId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetDentistDHLDetails?CompanyId=' + CompanyId;
        return this.http.get(this.url)
    }

    RequestDHLShipment(CreateShipment): Observable<any> {
        this.url = this.baseUrl + 'api/Order/RequestDHLShipment';
        return this.http.post(this.url, CreateShipment)
    }

    GetCasesForRequestPickup(caseSearchData): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetCasesForRequestPickup';
        return this.http.post(this.url, caseSearchData)
    }

    GetRecentShipments(CompanyId, flag): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetRecentShipments?CompanyId=' + CompanyId + '&flag=' + flag;;
        return this.http.get(this.url)
    }

    GetOrderRequestShipments(caseSearchData): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetOrderRequestShipments';
        return this.http.post(this.url, caseSearchData)
    }

    TrackShipments(TrackingRequest): Observable<any> {
        this.url = this.baseUrl + 'api/DHLShipment/TrackingRequest';
        return this.http.post(this.url, TrackingRequest)
    }
    GetFile(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetFile';
        return this.http.post(this.url, object);
    }
    GetAttachedFileByDentist(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAttachedFileByDentist';
        return this.http.post(this.url, object);
    }

    GetDentistUploadedFiles(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetDentistUploadedFiles';
        return this.http.post(this.url, object);
    }
    AddUpdateAlteredOrders(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/AddUpdateAlteredOrders';
        return this.http.post(this.url, object)
    }
    getAlertedCases(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAlteredCases';
        return this.http.post(this.url, object)
    }

    FilesUploadForDentist(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/FilesUploadForDentist';
        return this.http.post(this.url, object)
    }
    addCommentForProductionCompany(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/AddCommentForProductionCompany';
        return this.http.post(this.url, object)
    }
    //new 
    getProdImageandPdfFilesById(id): Observable<any> {
        this.url = this.baseUrl + 'api/Product/GetImageandPdfFiles?orderId=' + id;
        return this.http.get(this.url);
    }

    getProdOngoingCases(object) {
        this.url = this.baseUrl + 'api/Order/GetProdOngoingCases';
        return this.http.post(this.url, object);
    }

    getEmployees(id): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAllEmloyee?CompanyId=' + id;
        return this.http.get(this.url);
    }
    updateAction(data) {
        this.url = this.baseUrl + 'api/Order/UpdateAction';
        return this.http.post(this.url, data)
    }
    getAllAction(id): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAllAction?OrderId=' + id;
        return this.http.get(this.url);
    }
    getDetails(id):Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAction?Id=' + id;
        return this.http.get(this.url);
    }
    deleteAction(id):Observable<any> {
        this.url = this.baseUrl + 'api/Order/DeleteAction?Id=' + id;
        return this.http.get(this.url);
    }
    getAllEmployeeDetails(id): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAllEmployeeDetails?CompanyId=' + id ;
        return this.http.get(this.url);

    }
    CreateWorkOrderPrint(Object): Observable<any> {
       this.url = this.baseUrl + 'api/Order/CreateWorkOrderPrint';
       return this.http.post(this.url, Object);
       // return this.http.post(`${this.baseUrl}api/Order/DownloadPdf`,Object,{responseType: 'arraybuffer' });
     
    }

    GetPdffile(filePath): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetPdf?filePath=' + filePath ;
        return this.http.get(this.url);

    }
    GetAllFiles(dealerId,companyId): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetAllFiles?DealerId=' + dealerId+ '&DentistCompanyId=' + companyId;
        return this.http.get(this.url);

    }
    DeleteFile(Id):Observable<any>
    {    this.url = this.baseUrl + 'api/Order/DeleteFile?Id=' + Id ;
         return this.http.get(this.url);
    }
    printPdf(filePath): Observable<any> {
        this.url = this.baseUrl + 'api/Order/GetPrint?filePath=' + filePath ;
        return this.http.get(this.url,{'responseType'  : 'blob'});
       // return this.http.post(`${this.baseUrl}api/Reports/DownloadPdf`,htmlconvert,{responseType:'blob'});
         // return this.http.get(`${this.baseUrl}api/Order/DownloadPdf`,Object,{responseType: 'arraybuffer' });
    }
    CreateOrderPrint(count,dealerId,companyId,userID,Id): Observable<any> {
        this.url = this.baseUrl + 'api/Order/CreateOrderPrint?NoOfCopies=' + count + '&DealerId=' + dealerId+ '&DentistCompanyId=' + companyId+ '&PrintBy=' + userID+ '&Id=' + Id;
        return this.http.get(this.url);
     }
     CreateFile(Id,count,dealerId,companyId,userID,Status): Observable<any> {
        this.url = this.baseUrl + 'api/Order/AddFile?Id=' + Id + '&NoOfCopies=' + count + '&DealerId=' + dealerId+ '&DentistCompanyId=' + companyId+ '&PrintBy=' + userID+ '&Status=' + Status;
        return this.http.get(this.url);
     }
     CheckStatus(Id): Observable<any> {
        this.url = this.baseUrl + 'api/Order/CheckFileStatus?Id=' + Id;
        return this.http.get(this.url);
     }
    
     // 3 shape cases
    extract3ShapeCases(): Observable<any> {
        this.url = this.baseUrl + 'api/Order/ExtractManuallyThreeShapeCases';
        return this.http.get(this.url)
    }
    
    get3ShapeIncomingCases(): Observable<any> {
        this.url = this.baseUrl + 'api/Order/Get3ShapeIncomingCases';
        return this.http.get(this.url)
    }

    Get3ShapeCasesById(temorderid): Observable<any> {
        this.url = this.baseUrl + 'api/Order/Get3ShapeCasesById?temOrderId=' + temorderid;
        return this.http.get(this.url)
    }

    delete3ShapeCase(temorderid): Observable<any> {
        this.url = this.baseUrl + 'api/Order/Delete3ShapeCase?temOrderId=' + temorderid;
        return this.http.get(this.url)
    }

    UpdateTempOrder(caseDetails: any): Observable<any> {
        this.url = this.baseUrl + 'api/Order/UpdateTempOrder';
        return this.http.post(this.url, caseDetails);
    }

    moveToShipmentForm3ShapeIncomingCases(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/moveToShipmentForm3ShapeIncomingCases';
        return this.http.post(this.url, object)
    }

    DownLoad3ShapeFile(object): Observable<any> {
        this.url = this.baseUrl + 'api/Order/DownLoad3ShapeFile';
        //return this.http.post(this.url, object, { responseType: 'blob' });
        return this.http.post(this.url, object);
    }

    PrintDeliveryNote(object) {
        this.url = this.baseUrl + 'api/Order/PrintPdfNotes';
        return this.http.post(this.url, object);
    }

}