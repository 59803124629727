//httpclient-global-error-handling - created by pradeep 
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError( (error: HttpErrorResponse) => {
                    if(!error.error)
                    return throwError(error);
                    else
                    return throwError(error.error.Message);
                  })
            )
    }
} 