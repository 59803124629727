import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {RoleService} from '../role.service';
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  username:any;
  sub:any;
  userDetails:any={};
  constructor(private route: ActivatedRoute,private roleService:RoleService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.username = params['username']; 
   
   });
 
  }

}
