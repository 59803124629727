import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RoleService } from '../role/role.service';
import { DentAppUsersComponent } from './dent-app-users/dent-app-users.component';
import { UpdatedentappuserComponent } from './updatedentappuser/updatedentappuser.component';
import { ModalModule, PopoverModule } from 'ngx-bootstrap';
import { AuthGuard } from '../../app/auth.guard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from "ngx-spinner";
import { TooltipModule } from 'ng2-tooltip-directive';
import { PreviousUrlGuard } from '../previousurl.guard';
const appRoutes: Routes = [
  { path: 'dentAppUsers/:searchId', component: DentAppUsersComponent,canActivate: [PreviousUrlGuard] },
  { path: 'management/dentAppUsers', component: DentAppUsersComponent,canActivate: [PreviousUrlGuard] },

  { path: 'management/dentAppUsers/updatedentappuser/:id', component: UpdatedentappuserComponent, canActivate: [AuthGuard,PreviousUrlGuard] },

];
@NgModule({
  imports: [
    ModalModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    PopoverModule,
    NgxSpinnerModule,
    ScrollingModule,
    TooltipModule

  ],
  exports: [RouterModule],
  declarations: [DentAppUsersComponent, UpdatedentappuserComponent

  ],
  providers: [RoleService]

})
export class RoleModule { }
