import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../management.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {loginby} from '../../models/loginby';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { CompaniesService } from "../../companies/companies.service";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['../../home/home.component.css']
})

export class InvoiceComponent implements OnInit {
  selection:boolean=false;
  dateCustomClasses: DatepickerDateCustomClasses[];
  selected: boolean;
  companyId:number;
  dealerCompanys: any = [];
  dealercompanyName:any;
  dealerCompanyId:any;
  agentCompanys: any = [];
  showDate: boolean;
  company:boolean;
  delivery:boolean;
  totalCost:number;
  DealerComapnyDetailsById:any={};
  DealerComapnyName:any;   
  deliveryNumber:string;
  invoiceTypes:any=[];
  dropdownSettings = {};
  selectedItems = [];
  dentistCompanies:any;
  selectedDentistCompanyId:number;
  selectedDentistCompanyName:any;
  InvoiceType:any='';
  periodFrom:any=null;
  periodTo:any=null;
  selectionCases:any=[];
  name:any;
  total:number;
  DealerId:any;
  invoiceTypeId:any;
  RoleName:any;
  dealerDetails:any={};
  fortnoxProcessingList: any = [];
  FotnoxConnId: any;
  FortnoxSysId: any;

  constructor(private managementService: ManagementService,private location: Location,
    private toastr: ToastrService,private spinner:NgxSpinnerService, private companiesService: CompaniesService) {
      const now = new Date();
    this.dateCustomClasses = [
      { date: now, classes: ['text-warning'] },
    ]   
  }

  ngOnInit() {
    this.RoleName=localStorage.getItem('RoleName');
    this.DealerId = localStorage.getItem('DealerId');    
    if(this.RoleName === 'SUPER_ADMIN'){//super admin
      let object={
        'loginBy':loginby.superAdmin,
        'DealerId':null
      }
      this.managementService.getDealerCompanyForInvoice(object).subscribe((data: any) => {       
        if(data!=null){
          this.dealerCompanys=data;
          if(this.dealerCompanys.length > 0){
            let filterId = this.dealerCompanys.filter(item => item.companyId == this.DealerId).map(item => item.companyId);
            this.companyId = filterId[0];
            this.onChangeDealerCompany(this.companyId);
          }
        }
        else{
          this.toastr.info("You are not registered with Invoice System.Please Contact Administrator");
        }
      });
    }
    else {//dealer admin
      let object={
        'DealerId':this.DealerId,
        'loginBy':loginby.dealer
      }
      this.managementService.getDealerCompanyForInvoice(object).subscribe((data: any) => {       
        if(data!=null){
          this.dealercompanyName = data[0].name;
          this.companyId=data[0].companyId;
          this.dealerCompanyId=data[0].companyId;
          this.managementService.getAgentCompanyForInvoice(this.dealerCompanyId).subscribe((data: any) => {      
            this.agentCompanys = data;
          })
          this.invoiceTypes=[{ 'InvoiceTypeId': 0, 'TypeName': 'Per Period' }, { 'InvoiceTypeId': 1, 'TypeName': 'Per Dentist' }, { 'InvoiceTypeId': 2, 'TypeName': 'Per Case' }];
          // this.DealerComapnyName=
          this.DealerComapnyDetailsById =this.dealerCompanyId;  
          this.DealerComapnyName= this.dealercompanyName;
        }
        else{
          this.toastr.info("You are not registered with Invoice System.Please Contact Administrator");
        }
      });
    }
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: true,
      selectAllText:"Select All",
      unSelectAllText:"UnSelect All",
      idField: 'companyId',
      textField: 'name',
      allowSearchFilter: true,
      closeDropDownOnSelection:true,
      disabled:true
    };
    this.companiesService.GetFortnoxProcessing().subscribe((response: any) => {
      this.fortnoxProcessingList = response;
      if(this.fortnoxProcessingList.length > 0){
        let filterId = this.fortnoxProcessingList.filter(item => item.isDefault == true).map(item => item.fortnoxTokensId);
        this.FotnoxConnId = filterId[0];
      }
    }, (err) => { this.toastr.error(err); });
  }

  onChangeDealerCompany($event) {
    if ($event == undefined){
      this.selected = false;
      this.invoiceTypes=[];
      this.selectionCases=[];
      this.companyId=0;
      this.InvoiceType='';
      this.showDate=false;
      this.company=false;
    }
    else{
    this.invoiceTypes=[];
    this.selectionCases=[];
    this.InvoiceType='';
    this.selected = true;
    this.companyId= $event;
    this.dealerDetails={};
    this.dealerDetails= this.dealerCompanys.filter(x => x.companyId === $event);
    this.DealerComapnyName=this.dealerDetails[0].name;
    this.managementService.getAgentCompanyForInvoice(this.companyId).subscribe((data: any) => {
      this.agentCompanys = data;
    })
    this.invoiceTypes=[{ 'InvoiceTypeId': 0, 'TypeName': 'Per Period' }, { 'InvoiceTypeId': 1, 'TypeName': 'Per Dentist' }, { 'InvoiceTypeId': 2, 'TypeName': 'Per Case' }];
    }
  }

  onChangeInvoiceType($event) {
    if ($event == undefined) {
      this.showDate = false;
      this.company=false;
      this.delivery=false;
      this.InvoiceType='';
      this.selectionCases=[];
      this.periodFrom=null;
      this.periodTo=null;
    }
    else if($event == 2){  
      this.selectionCases=[];
      this.showDate = false;
      this.company=false;
      this.delivery=true;
      this.InvoiceType=$event;
    }
    else if($event == 1){
      this.selectedItems = [];
      this.selectionCases=[];
      this.company=true;
      this.showDate=true;
      this.delivery=false;
      this.InvoiceType=$event;
      this.periodFrom=null;
      this.periodTo=null;
      this.managementService.getDentistCompaniesForInvoice(this.companyId).subscribe((data:any)=>{     
        this.dentistCompanies=data;
      })
    }
    else{   
      this.total=0;
      this.selectionCases=[];
      this.showDate=true;
      this.company=false;
      this.delivery=false;
      this.InvoiceType=$event;
      this.periodFrom=null;
      this.periodTo=null;
    }
  }

  onItemSelect($event){
    this.selectionCases=[];
    this.selectedDentistCompanyName='';
    this.selectedDentistCompanyId=$event.companyId
    this.selectedDentistCompanyName=$event.name
  }

  onItemUnSelect($event){   
    this.selectedDentistCompanyId=0;
  }

  onchangeDatePeriodFrom($event){
    $event.toLocaleDateString();
    var date = $event.getFullYear() + '-' + ($event.getMonth() + 1) + '-' + $event.getDate();
    this.periodFrom = date;
  }

  onchangeDatePeriodTo($event){
    $event.toLocaleDateString();
    var date = $event.getFullYear() + '-' + ($event.getMonth() + 1) + '-' + $event.getDate();
    this.periodTo = date;
  }

  onSubmit(f){
    if(f.form.valid){
    this.selectionCases=[];
    this.FortnoxSysId = this.FotnoxConnId;
    let object = {
      'DealerCompanyId': this.companyId,
      'Agent': 0,
      'InvoiceType': this.InvoiceType,
      'PeriodFrom': this.periodFrom,
      'PeriodTo': this.periodTo,
      'DentistCompanyId': this.selectedDentistCompanyId,
      'DeliveryNumber': this.deliveryNumber,
      'FotnoxConnId': this.FotnoxConnId
    }
    this.selection=true;
    this.managementService.getMarkSelection(object).subscribe((data:any)=>{    
      if(data==0){
        this.toastr.info("No cases found in this range");
      }
      else{
        this.totalCost=0;
        this.selectionCases=data;
        this.selectionCases = this.selectionCases.map(function(item) { 
          if(item.countryName=='Iceland')
          {
            item.totalCost = (item.totalCost*0.07);       
          } 
            return item;
        });
        this.totalCost = this.selectionCases.reduce((sum, item) => sum + item.totalCost, 0);
        this.total=0;
        this.total = this.selectionCases.reduce((sum, item) => sum + item.count, 0);
      }
    })
  }
  f.submitted=false;
  }
  backClicked(){
    this.location.back();
  }
  createInvoiceJob(){
    this.name='';
    if(this.InvoiceType==0){
      this.name='Per Period'+'  '+this.periodFrom+'  '+'to'+'  '+this.periodTo+'  '+'for'+'  '+this.DealerComapnyName+','+this.total+' '+'Cases.'
    }
    else if(this.InvoiceType==1){
      this.name='Per Dentist'+'  '+this.periodFrom+'  '+'to'+'  '+this.periodTo+'  '+'for'+'  '+this.selectedDentistCompanyName+','+this.total+' '+'Cases.'
    }
    else{
      this.name="For case"+'  '+this.deliveryNumber +'.'
    }
    let object={
      'DealerCompanyId':this.companyId,
      'Agent':0,
      'InvoiceType':this.InvoiceType,
      'PeriodFrom':this.periodFrom,
      'PeriodTo':this.periodTo,
      'DentistCompanyId':this.selectedDentistCompanyId,
      'DeliveryNumber':this.deliveryNumber,
      'InvoiceSystemId':0,
      'Name':this.name,
      'InvoiceOption':0,
      'TeethRUsUserId':localStorage.getItem('userid'),
      'FotnoxConnId': this.FortnoxSysId
    }
    this.spinner.show();
    this.managementService.createInvoiceJob(object).subscribe((data:any)=>{     
      this.toastr.success("Invoice job has been created successfully" );
      this.spinner.hide();
    }) 
    this.InvoiceType='';
    this.periodFrom=null;
    this.periodTo=null;
    this.selectionCases=[];
    this.showDate = false;
    this.invoiceTypeId=undefined;
    this.deliveryNumber="";
    this.delivery=false;
    this.company=false;
    //this.companyId=undefined
    this.selectedItems = [];
  }
}