import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ConfigService } from '../app.config';
import { Observable } from 'rxjs';


@Injectable()

export class CICService {
  private url = "";
  private baseUrl = "";
  PhotoUrl:any
  userID:any;
  public CountryFlagUrl = "";
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
    this.PhotoUrl=this.baseUrl+'Uploads/DealersLogo'
    this.userID = localStorage.getItem('userid');
    this.CountryFlagUrl=this.baseUrl+'Uploads/country';
  }

  GetTopTwoFiftyDentist(dealerid, companyid): Observable<any> {
    this.url = this.baseUrl + 'api/CIC/GetTopTwoFiftyDentist?dealerid=' + dealerid + '&companyid=' + companyid;    
    return this.http.get<any[]>(this.url);
  }

  GetClientInfoDetail(dentistId): Observable<any> {
    this.url = this.baseUrl + 'api/CIC/GetClientInfoDetail?dentistId=' + dentistId;    
    return this.http.get<any[]>(this.url);
  }

  GetCICComment(dentistId):Observable<any>{
    this.url=this.baseUrl + "api/CIC/GetCICComment?dentistId=" + dentistId;
    return this.http.get(this.url);
  }

  AddandUpdateCICComment(obj):Observable<any>{
    this.url=this.baseUrl +'api/CIC/AddandUpdateCICComment';
    return this.http.post(this.url,obj);
  }

  SaveProfileNote(obj):Observable<any>{
    this.url=this.baseUrl +'api/CIC/SaveProfileNote';
    return this.http.post(this.url,obj);
  }

  GetLastTenCases(dentistId, caseStatus, compdealerid, isprodusr):Observable<any>{
    this.url=this.baseUrl + "api/CIC/GetLastTenCases?dentistId=" + dentistId + "&caseStatus=" + caseStatus + "&dealerId=" + compdealerid + "&isProdUser=" + isprodusr;
    return this.http.get(this.url);
  }

  AddandUpdateCICTasks(object):Observable<any>{
    this.url=this.baseUrl +'api/CIC/AddandUpdateCICTasks';
    return this.http.post(this.url,object);
  }

  getCICTasks(dentistId, userId): Observable<any> {
    this.url = this.baseUrl + "api/CIC/getCICTasks?dentistId=" + dentistId + "&userId=" + userId;
    return this.http.get(this.url);
  }

  GetTaskUploadedFiles(object): Observable<any> {
    this.url = this.baseUrl + 'api/CIC/GetCICTaskUploadedFiles';
    return this.http.post(this.url, object);
  }

  removecicTaskFile(object): Observable<any> {
    this.url = this.baseUrl + "api/CIC/removecicTaskFile";
    return this.http.post(this.url, object);
  }

}