import { ShipmentRequestJson } from "./ShipmentRequestJson";

import { DHLAuth } from "./DHLAuth";
import { RequestPickup } from "./RequestPickup";

export class CreateRequestShipment {
    ShipmentRequestJson: ShipmentRequestJson; 
    DHLAuth: DHLAuth; 
    RequestPickup:RequestPickup;
    IsRequestpickup:boolean
} 