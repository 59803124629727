import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/app.config";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  baseUrl: string;
  userID: string;
  private url = "";
  CountryPhotoUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.userID = localStorage.getItem("userid");
    this.baseUrl = configService.getServiceBaseUrl();
    this.CountryPhotoUrl = this.baseUrl + "Uploads/country";
  }

  GetSalesRepUsers() {
    this.url = this.baseUrl + "api/CRMMessage/GetSalesRepUsers";
    return this.http.get(this.url);
  }

  GetMessageDetails(messageObj) {
    this.url = this.baseUrl + `api/CRMMessage/GetMessageDetails`;
    return this.http.post(this.url, messageObj);
  }

  createMessage(obj): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}api/CRMMessage/CreateMessage`,
      obj
    );
  }

  FilesUploadForMessage(data) {
    this.url = this.baseUrl + "api/CRMMessage/AttachedFiles";
    return this.http.post(this.url, data);
  }
  GetMessageUploadedFiles(object): Observable<any> {
    this.url = this.baseUrl + "api/CRMMessage/GetMessageUploadedFiles";
    return this.http.post(this.url, object);
  }

  markedMessageAsRead(obj): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}api/CRMMessage/MarkedMessageAsRead`,
      obj
    );
  }
  //by Fahimuddin Khan
  GetSentMessageDetails(sentObj) {
    this.url = this.baseUrl + `api/CRMMessage/GetSentMessageDetails`;
    return this.http.post(this.url, sentObj);
  }
  GetMessageById(messageId) {
    this.url = this.baseUrl + "api/CRMMessage/GetMessageById?messageId=" + messageId;
    return this.http.get(this.url);
  }
}
