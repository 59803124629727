export const SalesRepStatus = {
  NewLead: 1,
  ColdLead: 2,
  WarmLead: 3,
  HotLead: 4,
  LeadtoCustomer: 5,
  LostLeads: 6,
};

export const CompanyTypeId = {
  Owner: 1,
  Dealer: 2,
  Production: 3,
  Dentist: 4,
  Subsidiary: 5,
  Invoice_agent: 6,
};

export enum CRMMessagePerPage {
  first = 10,
  second = 20,
  third = 50,
  displayNoOfPagesPerPage = 10,
}
