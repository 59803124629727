import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PreviousUrlGuard implements CanActivate {
    loggedInService: any;
   
    constructor(private router: Router, public route: ActivatedRoute) {
    }
    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        // let url: string = state.url;
        // localStorage.setItem('PreviousUrl', url);
        return true;
    }
}
