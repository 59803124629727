import { Injectable } from '@angular/core';
import { ConfigService } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class GlobalSearchService {
    private url = '';
    private baseUrl = '';
    public searchId:any;
    public sortBy="DeliveryNumber"
    constructor(private http: HttpClient, private configService: ConfigService) {
        this.baseUrl = configService.getServiceBaseUrl();
    }

    //Get All Cases
    searchCases(caseSearchData): Observable<any> {
        this.url = this.baseUrl + 'api/Order/SearchCases';
        return this.http.post(this.url, caseSearchData)
    }
    
}