import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from '../role.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HomeService } from 'src/app/home/home.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-updatedentappuser',
  templateUrl: './updatedentappuser.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class UpdatedentappuserComponent implements OnInit {
  sub: any;
  id: any;
  dentappuserdetails: any = {};
  
  ////==========base64formatedata Varibles=========////
  base64textString: any;
  FileName: any;
  FileType: any;

  constructor(
    private spinner:NgxSpinnerService,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private toastr: ToastrService, 
    private router: Router, 
    private location: Location,
    private homeService:HomeService
    ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      this.roleService.getDentAppUsersByIdUpdateProfile(this.id).subscribe((data: any) => {
        this.dentappuserdetails = data[0];
        this.FileName = data[0].fileName;
        this.FileType = data[0].fileType;
      }, (err) => { this.toastr.error(err); })
    });
  }
  back() {
    this.location.back();
  }
  onSubmit() {
    this.spinner.show();
    let object = {
      "TeethRUsUserId": this.id,
      "FirstName": this.dentappuserdetails.firstName,
      "LastName": this.dentappuserdetails.lastName,
      "Title": this.dentappuserdetails.title,
      "Address": this.dentappuserdetails.address,
      "PostalCode": this.dentappuserdetails.postalCode,
      "City": this.dentappuserdetails.city,
      "CountryName": this.dentappuserdetails.countryName,
      "MobileNumber": this.dentappuserdetails.mobileNumber,
      "Email": this.dentappuserdetails.email,
      "ApplicationName": this.dentappuserdetails.applicationName,
      "FileName": this.FileName,
      "FileType": this.FileType,
      "Base64Data": this.base64textString,
      "PhotoURL": this.dentappuserdetails.photoURL
    }
    this.roleService.updateProfile(object).subscribe((data: any) => {
   
      if (data) {
        if(this.FileName !=undefined || this.FileType !=undefined || this.FileName !=null || this.FileType !=null){
          // localStorage.setItem('FileName',this.FileName+'.'+this.FileType);  
          this.homeService.Url =this.homeService.PhotoUrl + this.id + '/' + this.FileName+'.'+this.FileType;
        }
      
        // this.homeService.FirstName = this.dentappuserdetails.firstName;
        // this.homeService.LastName =this.dentappuserdetails.lastName;

        this.toastr.success("User details has been updated successfully");
        //this.router.navigate(['/dentAppUsers']);
        //this.back();
        this.spinner.hide();
      }
    }, (err) => { this.toastr.error(err);  this.spinner.hide();})
  }
  backButton() {
    this.back();
  }


  handleFileSelect(evt) {
    var File = evt.target.value;
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var FileType = subStringData.split('.')[1];
   
    this.FileName = FileName;
    this.FileType = FileType;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  
  }
}
