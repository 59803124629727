
export class CRMTask {
    id: any=0;
    title: any;
    status: any;
    description: any;
    createdBy: any; 
    filepath:any;
    filename:any;   
    crmTaskAttachment:any = [];
}