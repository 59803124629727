import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../app.config";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  baseUrl: string;
  userID: string;
  private url = "";

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.userID = localStorage.getItem("userid");
    this.baseUrl = configService.getServiceBaseUrl();
  }

  GetUsers(): Observable<any> {
    this.url = this.baseUrl + "api/Calendar/GetUsers";
    return this.http.get(this.url);
  }
  GetCRMMeetings(object): Observable<any> {
    this.url = this.baseUrl + "api/Calendar/GetCRMMeetings";
    return this.http.post(this.url,object);
  }
  AddUpdateEvent(object) {
    this.url = this.baseUrl + "api/Calendar/AddUpdateMeeting";
    return this.http.post(this.url,object);
  }
  trashEvent(id) {
    this.url = this.baseUrl + "api/Calendar/DeleteCRMMeeting?id=" + id;
    return this.http.get(this.url);
  }
  GetCRMMeetingById(id){
    this.url = this.baseUrl + "api/Calendar/GetCRMMeetingById?Id=" + id;
    return this.http.get(this.url);
  }
  // shared calendar service
  GetCalendarSharedUsers(userid) {
    this.url = this.baseUrl + "api/Calendar/GetCalendarSharedUsers?userid=" + userid;
    return this.http.get(this.url);
  }
  GetSharedUsers(userid) {
    this.url = this.baseUrl + "api/Calendar/GetSharedUsers?userid=" + userid;
    return this.http.get(this.url);
  }
  GetUsersForSharedCalendar(userObj): Observable<any> {
    this.url = this.baseUrl + "api/Calendar/GetUsersForSharedCalendar";
    return this.http.post(this.url,userObj);
  }
  AddSharedCalendar(objects) {
    this.url = this.baseUrl + "api/Calendar/AddSharedCalendar";
    return this.http.post(this.url,objects);
  }
  UnSharedCalendar(objects) {
    this.url = this.baseUrl + "api/Calendar/UnSharedCalendar";
    return this.http.post(this.url,objects);
  }
  DeleteSharedCalendar(userid) {
    this.url = this.baseUrl + "api/Calendar/DeleteSharedCalendar?userid=" + userid;
    return this.http.get(this.url);
  }

}
