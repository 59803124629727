import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private url = "";
  private baseUrl = "";
  public PhotoUrl ="";

  FileName = localStorage.getItem('FileName');
  teethRUsUserId = localStorage.getItem('userid');
  Url: string;
  FirstName;
  LastName;
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
    this.PhotoUrl=this.baseUrl+'Uploads/Users/'
    this.Url = this.PhotoUrl + this.teethRUsUserId + '/' + this.FileName;
  }

  getDealerList() {
    this.url = this.baseUrl + 'api/Product/GetDealers';
    return this.http.get(this.url);
  }

  getNotification(id): Observable<any> {
    this.url = this.baseUrl + 'api/Order/GetAllNotification?TeethRUsUserId=' + id;
    return this.http.get(this.url);
}
UpdateNotification(id): Observable<any> {
  this.url = this.baseUrl + 'api/Order/UpdateNotification?Id=' + id;
  return this.http.get(this.url);
}
  // getDealerInformation(dealerId){
  //   this.url = this.baseUrl + 'api/Product/GetDealerInfo?dealerId='+dealerId;
  //   return this.http.get(this.url);
  // }
}
