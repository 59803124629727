export class CRMCalendar {
    id: any=0;
    senderId:any;
    subject: any;
    location: any;
    durationInMins:any;
    meetingFrom: any;
    meetingTo: any;
    repeatPattern: any;
    description: any;
    colorCode:any;
    crmMeetingDetail:any = [];
  }