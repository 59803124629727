import { Component, OnInit,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {ManagementService} from '../management.service';
import {CompaniesService} from '../../companies/companies.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-groupmessaging',
  templateUrl: './groupmessaging.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class GroupmessagingComponent implements OnInit {
  sendbuttons:boolean=false;
  ckeditorContent: string = '<p>Some html</p>';
  modalRef: BsModalRef;
  allGroups:any=[];

  senderPhoneNumber:any;
  sendGroupName:any;
  senderDesp:any;
  message:any;
  messageGroupId:any;
  senderEmailAddress:any;
  Subject:any;
  body:any;

  type:string;
  messagelogInfo: any = [];
  config = {
    backdrop: false,
    ignoreBackdropClick: true
  };

  messageLength = 0;
  constructor(private managementService:ManagementService,private companiesService:CompaniesService,private spinner:NgxSpinnerService,
     private modalService: BsModalService,public router: Router, private toastr: ToastrService) {
 
   }

  ngOnInit() {
   this.getAllGroups();
  }
  getAllGroups(){
    this.allGroups=[];
    this.spinner.show();
    this.managementService.getAllGroups().subscribe((data: any) => {
    
      this.allGroups = data;
      this.spinner.hide();
    }, (err) => { this.toastr.error(err);  this.spinner.hide(); })
  }
  smsPopUp(item, template: TemplateRef<any>){
    this.sendbuttons=false;
    this.modalRef = this.modalService.show(template, this.config);
    this.senderPhoneNumber=item.fromTelephone;
    this.sendGroupName=item.name;
    this.senderDesp=item.description;
    this.messageGroupId=item.messageGroupId
  
  }
  sendmessage(){
 
    let object={
      'MessageGroupId':this.messageGroupId,
      'Message':this.message,
      'senderPhoneNumber':this.senderPhoneNumber,
      'senderTeethRUsUserId':localStorage.getItem('userid')
    }
    this.sendbuttons=true;
    this.managementService.sendGroupSms(object).subscribe((data:any)=>{
     
      this.modalRef.hide();
      this.toastr.success("Sms has sent successfully")
      this.message="";
      this.messageLength=0;
    })
  }
  close(){
    this.modalRef.hide();
this.sendGroupName="";
this.senderPhoneNumber="";
this.senderDesp="";
this.message="";
this.Subject=""
this.senderEmailAddress="";
this.messageLength=0;
  }
  sendEmailPopup(item,template1: TemplateRef<any>) {
    this.sendbuttons=false;
    this.senderEmailAddress=item.fromEmail;
    this.sendGroupName=item.name;
    this.senderDesp=item.description;
    this.messageGroupId=item.messageGroupId;
    this.modalRef = this.modalService.show(template1,Object.assign({}, { class: 'gray modal-lg' }, this.config)
    );
  }
  sendEmail(){
  
    let object={
      'MessageGroupId':this.messageGroupId,
      'Subject':this.Subject,
      'Body':this.message,
      'FromEmail':this.senderEmailAddress
    }
    this.sendbuttons=true;
    this.managementService.sendGroupMail(object).subscribe((data:any)=>{
    
      this.modalRef.hide();
      this.toastr.success("Mail has been sent successfully")
      this.body="";
      this.message="";
      this.Subject=""
    })
  }
  edit(item){
    this.type = 'edit';
    this.router.navigate(['management/groupmessaging/updategroup/' + this.type + '/' + item.messageGroupId]);
  }
  deleteGroup(item){
    let object={
      'MessageGroupId':item.messageGroupId
    }
    this.managementService.deleteGroupMessage(object).subscribe((data:any)=>{
    
      if(data=true){
        this.toastr.success("Group is Deleted Successfully"); 
        this.getAllGroups();
      }
    }, (err) => { this.toastr.error(err); })
  }
  onChange($event){
   
    this.message=$event
  }
  smsLogInfo(item, smslogtemplate: TemplateRef<any>) {
    let object = {
      'TeethRUsUserId': item.createdByTeethRUsUserId,
      'MessageGroupId':item.messageGroupId
    }
  
    this.companiesService.getMessageLogInfo(object).subscribe((data: any) => {
    
      this.messagelogInfo = data;
    }, (err) => { this.toastr.error(err); });

    this.modalRef = this.modalService.show(smslogtemplate, Object.assign({}, { class: 'gray modal-lg' }, this.config))
  }
  messaegType($event) {

    this.messageLength = $event.length;
    if (this.messageLength == 160) {
      this.message = $event;
      // this.tempMessageData = $event;
    } else if (this.messageLength > 160) {
     // this.message = this.tempMessageData;
      this.messageLength = this.messageLength - 1;
    }
  }
}
