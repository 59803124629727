import { Dimensions } from "./Dimensions";
import {JsonObject, JsonProperty} from "json2typescript";
export class RequestedPackages {
    //@JsonProperty("@number",String) 
    @JsonProperty('@number')
    number: string = undefined ; 
    Weight: any; 
    Dimensions: Dimensions; 
    CustomerReferences: any;
} 
 
 