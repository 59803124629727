import { Component, OnInit, TemplateRef } from '@angular/core';
import { CasesService } from '../cases.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { PreferencePerPage } from 'src/app/models/preferencePerPage';
import { PaginationService } from 'src/app/services/pagination.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-requestlist',
  templateUrl: './order-requestlist.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class OrderRequestlistComponent implements OnInit {
  dealerId: any;
  OrderRequest: any = [];
  roleName: any;
  userId = localStorage.getItem('userid');
  companyId: any = localStorage.getItem('CompanyId');
  numOfPages: any;
  casesPerPage;
  paginationData: number;
  totalPages: any;
  exactPageList: number;
  pageField: any[];
  currentPage: any = 1;
  pageNumber: boolean[] = [];
  Request: any;
  hideme: any[];
  sortBy: any;
  headerSorting: any;
  orderBy: any;
  errormsg: any;
  pageSize: any = PreferencePerPage.first;
  first = PreferencePerPage.first;
  second = PreferencePerPage.second;
  third = PreferencePerPage.third;
  firstRow = true;
  secondRow = false;
  thirdRow = false;
  constructor(
    private caseService: CasesService,private toastr: ToastrService,private spinner: NgxSpinnerService, private modalService: BsModalService,
    private paginationService: PaginationService) { }

  ngOnInit() {
    this.roleName = localStorage.getItem('RoleName');
    this.dealerId = localStorage.getItem('DealerId');
    this.casesPerPage = PreferencePerPage.first;
    this.pageNumber[0] = true;
    this.paginationService.temppage = 0;
    this.paginationService.currentPage = 1;
    this.paginationService.showNoOfCurrentPage = 1;
    this.paginationService.showPageOnlyOntabsChange = true;
    this.sortBy = "CreatedDate";
    this.orderBy = "DESC";
    this.GetOrderRequestShipments();
  }
  GetorderRequest() {
    this.caseService.GetRecentShipments(this.dealerId, 4).subscribe((data: any) => {
  
      this.OrderRequest = data.orderRequestlist;
    })
  }

  downloaddhlpdf(index) {
    const linkSource = 'data:application/pdf;base64,' + this.OrderRequest[index].graphicIabel;
    const downloadLink = document.createElement("a");

    var fileName;
    if (this.roleName == 'SUPER_ADMIN' || this.roleName == 'DEALER_ADMIN') {
      fileName = this.OrderRequest[index].requestAWB;
    } else if (this.roleName == 'DENTIST_USER') {
      fileName = this.OrderRequest[index].deliveryAWB;
    }
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  GetOrderRequestShipments() { 
    var companyid = 0;
    var flag = 0;
    if (this.roleName == 'SUPER_ADMIN' || this.roleName == 'DEALER_ADMIN' || this.roleName == 'DEALER_USER') {
      companyid = this.dealerId;
      flag = 1;
      this.Request = "Order Pickup Requests";
    } else if (this.roleName == 'DENTIST_USER') {
      companyid = this.companyId;
      flag = 2;
      this.Request = "Delivery Pickup Requests";
    }
    let caseSearchData = {
      "CompanyId": companyid,// ,
      "DentistCompanyId": 0,
      "CaseStatus": 0,
      "CasesPerPage": this.casesPerPage,
      "CurrentPage": this.currentPage,
      "SortBy": this.sortBy,
      "OrderBy": this.orderBy,
      "DeliveryNumber": "",
      "UserId": this.userId,
      "RoleType": this.roleName,
      "flag": flag
    }
    this.spinner.show();
    this.caseService.GetOrderRequestShipments(caseSearchData).subscribe((data: any) => {
      //this.numOfPages = data.totalcounts[0].totalNoOfShipments;
      this.numOfPages =data.orderRequestlist.length;
      this.OrderRequest = data.orderRequestlist;
      this.errormsg = "";

      if (this.OrderRequest.length == 0 && flag == 1) {
        this.errormsg = "No Pickup Request Found";
      } else if (this.OrderRequest.length == 0 && flag == 2) {
        this.errormsg = "No Delivery Request Found";
      }
      this.totalNoOfPages();
     
      //Spinner Hide 
      this.spinner.hide();
    })
  }

  //Pagination Start

  prevCase() {
    if (this.paginationService.showNoOfCurrentPage != 1) {
      this.paginationService.prevCase();
      this.pageNumber = [];
      this.pageNumber[0] = true;
      this.currentPage = this.paginationService.pageField[0];
      this.GetOrderRequestShipments();
    }
  }

  nextCases() {
    if (this.paginationService.disabledNextBtn == false) {
      this.pageNumber = [];
      this.paginationService.nextCases();
      this.pageNumber[0] = true;
      this.currentPage = this.paginationService.pageField[0];
      this.GetOrderRequestShipments();
    }
  }

  setOrder(value: string, id) {

    this.hideme = [];
    this.sortBy = value;
   
    if (this.orderBy == "Desc") {
      this.orderBy = "Asc"
    } else {
      this.orderBy = "Desc"
    }

    this.GetOrderRequestShipments();
  }
  showNextCases(page, i) {
    this.pageNumber = [];
    this.pageNumber[i] = true;
    this.currentPage = page;
    this.GetOrderRequestShipments();
  }


  getRows(page) {

    this.pageNumber = [];
    if (page == PreferencePerPage.first) {
      this.firstRow = true;
      this.secondRow = false;
      this.thirdRow = false;
      this.casesPerPage = page;
      this.currentPage = 1;
      this.pageNumber[0] = true;
      this.totalNoOfPages();
      this.GetOrderRequestShipments();
    }
    else if (page == PreferencePerPage.second) {
      this.firstRow = false;
      this.secondRow = true;
      this.thirdRow = false;
      this.casesPerPage = page;
      this.currentPage = 1;
      this.pageNumber[0] = true;
      this.totalNoOfPages();
      this.GetOrderRequestShipments();
    } else {
      this.firstRow = false;
      this.secondRow = false;
      this.thirdRow = true;
      this.casesPerPage = page;
      this.currentPage = 1;
      this.pageNumber[0] = true;
      this.totalNoOfPages();
      this.GetOrderRequestShipments();
    }
    this.pageSize = page;
    //this.shipmentPerpage = page;
    // this.currentPage = 1

  }

  totalNoOfPages() {
    this.pageField = [];
    this.paginationData = (this.numOfPages / this.casesPerPage);
    // Pagination 
    let tempPageData = this.paginationData.toFixed();
    // const _paginationService = new PaginationService();
    // this.paginationService = _paginationService;
    if (Number(tempPageData) < this.paginationData) {
      this.exactPageList = Number(tempPageData) + 1;
      this.paginationService.exactPageList = this.exactPageList;
    } else {
      this.exactPageList = Number(tempPageData);
      this.paginationService.exactPageList = this.exactPageList
    }
    this.paginationService.pageOnLoad();
    this.pageField = this.paginationService.pageField;
    // for (var a = 0; a < this.paginationData; a++) {
    //   this.pageField[a] = a + 1;
    // }
  }
  onNavigate(index) {
    let awb = "";
    if (this.roleName == 'SUPER_ADMIN' || this.roleName == 'DEALER_ADMIN') {
      awb = this.OrderRequest[index].requestAWB;
    } else if (this.roleName == 'DENTIST_USER') {
      awb = this.OrderRequest[index].deliveryAWB;
    }
    const url = "https://www.dhl.co.in/en/express/tracking.html?AWB=" + awb + "&brand=DHL";
    window.open(url, "_blank");
  }
   
}
