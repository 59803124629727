import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { ManageLeadsComponent } from "./manage-leads/manage-leads.component";
import { AddLeadComponent } from "./add-lead/add-lead.component";
import { MessageComponent } from "./message/message.component";
import { ViewCalendarComponent } from "./calendar/view-calendar/view-calendar.component";
import { PopoverWrapperComponent } from "./calendar/view-calendar/popoverwrapper.component";
import { ShareCalendarComponent } from "./calendar/share-calendar/share-calendar.component";
import { PreviousUrlGuard } from "../previousurl.guard";
import { GridLayoutLeadsComponent } from "./manage-leads/grid-layout-leads/grid-layout-leads.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ShareModule } from "../share/share.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TooltipModule } from "ng2-tooltip-directive";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxPaginationModule } from "ngx-pagination";
import { HttpClientModule } from "@angular/common/http";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
//full calendar
import { FullCalendarModule } from '@fullcalendar/angular';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  rrulePlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

const appRoutes: Routes = [
  {
    path: "manageleads",
    component: ManageLeadsComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "newcrmlead",
    component: AddLeadComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "updatecrmlead/:type/:id",
    component: AddLeadComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "message",
    component: MessageComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "crm/calendar/viewcalendar",
    component: ViewCalendarComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "calendar/sharecalendar",
    component: ShareCalendarComponent,
    canActivate: [PreviousUrlGuard],
  },
  {
    path: "manageleads/leads",
    component: GridLayoutLeadsComponent,
    canActivate: [PreviousUrlGuard],
  },
];

@NgModule({
  declarations: [
    ManageLeadsComponent,
    AddLeadComponent,
    MessageComponent,
    ViewCalendarComponent,
    PopoverWrapperComponent,
    ShareCalendarComponent,
    GridLayoutLeadsComponent,
  ],
  imports: [
    RouterModule.forChild(appRoutes),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ColorPickerModule,
    FormsModule,
    FullCalendarModule,
    NgbPopoverModule,
    ReactiveFormsModule,
    DragDropModule,
    ScrollingModule,
    PerfectScrollbarModule,
    Ng2SearchPipeModule,
    DialogsModule,
    ButtonsModule,
    NgMultiSelectDropDownModule,
    ShareModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    HttpClientModule,
    TooltipModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BsDatepickerModule.forRoot(),
    NgxPaginationModule,
    TabsModule.forRoot()  
  ],
  entryComponents: [PopoverWrapperComponent],
})
export class CrmModule {}
