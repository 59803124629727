   // Lead status enum
   export enum LeadsStatus
    {
       NewLead = 1,
       ColdLead = 2,
       WarmLead = 3,
       HotLead = 4,
       LeadtoCustomer = 5,
       LostLeads = 6
    }

    // optional: Record type annotation guaranties that 
    // all the values from the enum are presented in the mapping
   export const LeadsStatusLabelMapping: Record<LeadsStatus, string> = {
   [LeadsStatus.NewLead]: "New Lead",
   [LeadsStatus.ColdLead]: "Cold Lead",
   [LeadsStatus.WarmLead]: "Warm Lead",
   [LeadsStatus.HotLead]: "Hot Lead",
   [LeadsStatus.LeadtoCustomer]: "Lead to Customer",
   [LeadsStatus.LostLeads]: "Lost Leads"
   };

    // task status enum
    export enum TaskStatus
    {
       Tobedone = 1,
       Doing = 2,
       Done = 3 
    }
  
   // optional: Record type annotation guaranties that 
   // all the values from the enum are presented in the mapping
   export const TaskStatusLabelMapping: Record<TaskStatus, string> = {
      [TaskStatus.Tobedone]: "To be done",
      [TaskStatus.Doing]: "Doing",
      [TaskStatus.Done]: "Done",
   };

   export enum ReminderDate
    {
       reminderday = 30
    }