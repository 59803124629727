import { Component, OnInit, TemplateRef } from '@angular/core';
import { RoleService } from '../role.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Location } from "@angular/common";
import { PreferencePerPage } from '../../models/preferencePerPage';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-dent-app-users',
  templateUrl: './dent-app-users.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class DentAppUsersComponent implements OnInit {
  modalRef: BsModalRef;
  dentappusers: any = [];
  id: any;
  DealerId: any;
  searchId: any;
  showSearchBar: boolean = false;
  hideme: any = [];
  pageSize;
  //Variables for Products per page
  first = 20;
  second = 50;
  third = 100;
  firstRow = true;
  secondRow = false;
  thirdRow = false;
  SelectedUsers:any=[];
  roleType: any;
  productionCompanyId: any;
  companyId: string;
  RoleName:any;
  totalUsers: any;

  constructor(private roleService: RoleService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService,
    private modalService: BsModalService, private location: Location, private spinner:NgxSpinnerService) {
    
    
    this.roleType = localStorage.getItem('RoleName')
    this.searchId = this.route.snapshot.paramMap.get("searchId");
    if (this.searchId == "null") {
     
      this.searchId ='';
    }
    if(this.roleType =="SUPER_ADMIN" || this.roleType =="DEALER_ADMIN" || this.roleType =="DEALER_USER"){
      this.companyId = localStorage.getItem('DealerId');
    }else if(this.roleType == "PRODUCTION_ADMIN" || this.roleType == "PRODUCTION_USER"){
      this.companyId = localStorage.getItem('CompanyId');
    }
  }

  ngOnInit() {
    this.RoleName = localStorage.getItem('RoleName');
    this.getAllUsers();
    this.pageSize =this.first;
  }
  getAllUsers() {
    let object = {
      'CompanyId': this.companyId,
      'SearchId': this.searchId
    }
this.spinner.show();
    this.roleService.getDentAppUsers(object).subscribe((data: any) => {
    
      this.dentappusers = data;
      this.totalUsers =this.dentappusers.length;
     
      this.spinner.hide();
    }, (err) => { this.toastr.error(err); this.spinner.hide(); })
   
  }
  edit(item) {
    this.router.navigate(['management/dentAppUsers/updatedentappuser/', item.teethRUsUserId]);
  }

  pageChange() {
    for (let i = 0; i < this.dentappusers.length; i++) {
      this.hideme[i] = false;
    }

  }

  delete(item, template: TemplateRef<any>) {
    this.id = item.teethRUsUserId
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    let object = {
      'TeethRUsUserId': this.id
    }
    this.roleService.deleteDentAppUser(object).subscribe((data: any) => {
      if (data) {
        this.toastr.success("DentAppUser Deleted Successfully");
    this.getAllUsers();
      }
    }, (err) => { this.toastr.error(err); })
    this.modalRef.hide();
  }

  decline() {

    this.modalRef.hide();
  }

  setPageSize(size) {
    if(size==this.first){
      this.pageSize = size;
      this.firstRow=true;
      this.secondRow=false;
      this.thirdRow=false;
    }else if(size==this.second){
      this.pageSize = size;
      this.firstRow=false;
      this.secondRow=true;
      this.thirdRow=false;
    }else if(size==this.third){
      this.pageSize = size;
      this.firstRow=false;
      this.secondRow=false;
      this.thirdRow=true;
    }
    
  }
 
  checkUncheckUsers(item){
    if (this.SelectedUsers.includes(item.teethRUsUserId)) {
      // let index = this.shipmentNumbers.indexOf(item);
      this.SelectedUsers = this.SelectedUsers.filter(x => x !== item.teethRUsUserId)
    }
    else {
      this.SelectedUsers.push(item.teethRUsUserId)
    }
   
  }
  unlockUsers(){
    var Str_teethRUsUserIds = this.SelectedUsers.toString();
  
    let object={
      'TeethRUsUserId':Str_teethRUsUserIds
    }
    this.roleService.unlockUser(object).subscribe((data:any)=>{
     
      if (data==true) {
        this.toastr.success("Unlocked User Successfully");
    this.getAllUsers();
    this.SelectedUsers=[];
      }
    }, (err) => { this.toastr.error(err); })
  }


}
