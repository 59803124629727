import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import {RoleService} from '../role.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
username:any;
sub:any;
newpassword:any;
confirnnewpassword:any;
url:any;
  constructor(private router: Router,private route: ActivatedRoute,private toastr: ToastrService,private roleService:RoleService) {
    this.url = decodeURI(this.router.url);
    
    let subStringData=this.url.substr(16);
    this.username =subStringData;
  
       
   }

  ngOnInit() {
  
    
  }
  onSubmit() {
    if(this.newpassword === this.confirnnewpassword)
    {
      let object={
        'Password':this.newpassword,
        'Username':this.username
      }
      this.roleService.updateForgotPassword(object).subscribe((data:any)=>{
  
        if (data){
          this.router.navigate(['/login']);
          this.toastr.success("Password has been updated successfully");

        }
      },(err) => {this.toastr.error(err);})
    }
    else{
    
      this.toastr.warning("New password is not matched with Confirm new password");
      this.newpassword="";
      this.confirnnewpassword="";
      }
  }
}
