import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { PreviousUrlGuard } from "../previousurl.guard";
import { CICComponent } from "./cic.component";
import {ShareModule} from '../share/share.module';
import { ChartsModule } from 'ng2-charts';
import { ConfigService } from "../app.config";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { TooltipModule } from 'ng2-tooltip-directive';

const appRoutes: Routes = [
  {
    path: "cic",
    component: CICComponent,
    canActivate: [PreviousUrlGuard],
  }
];

@NgModule({
  exports: [RouterModule],
  declarations: [
    CICComponent
  ],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ShareModule,
    ChartsModule,
    PerfectScrollbarModule,
    TooltipModule
  ],
  providers: [ConfigService]
})
export class CICModule {}
