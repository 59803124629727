import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagementService } from '../management.service';
import { CasesService } from '../../cases/cases.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-viewjob',
  templateUrl: './viewjob.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class ViewjobComponent implements OnInit {
  countryCode = localStorage.getItem('CountryCode');
  currencyCode = localStorage.getItem('CurrencyCode');

  id: number;
  sub: any;
  invoiceJobDetailsById: any = {};
  cases: any = [];

  hideme = [];
  Index: any;
  orderRowArray: any = [];
  constructor(private route: ActivatedRoute, private managementService: ManagementService,
    private caseService: CasesService, private _toastr: ToastrService) {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
     
    })
    this.managementService.getInvoiceJobsDetails(this.id).subscribe((data: any) => {
 
      this.invoiceJobDetailsById = data.invoiceJobDetail;
      this.invoiceJobDetailsById.totalCost =this.invoiceJobDetailsById.totalCost.toLocaleString(this.countryCode, { style: 'currency', currency: this.currencyCode });
      this.cases = data.orderList;
      for (var i = 0; i < this.cases.length; i++) {
        this.cases[i].totalCost = this.cases[i].totalCost.toLocaleString(this.cases[i].countryCode, { style: 'currency', currency: this.cases[i].shortName });
      }
    })
  }

  ngOnInit() {
  }
  showOrderRow(index, orderId) {

    this.hideme[index] = !this.hideme[index];
    this.Index = index;

    this.caseService.getCaseRows(orderId).subscribe((data: any) => {
 
      this.orderRowArray[index] = data;
      for (var i = 0; i < data.length; i++) {
        data[i].cost = data[i].cost.toLocaleString(this.cases[index].countryCode, { style: 'currency', currency: this.cases[index].shortName });
      }

    }, err => { this._toastr.error(err) });

  }
  exportInvoiceJobReport() {
    this.managementService.exportCasesForInvoiceJob(this.id).subscribe((data: any) => {
     
      const linkSource = 'data:application/xlsx;base64,' + data;
      const downloadLink = document.createElement("a");
      const fileName = "CasesForInvoiceJob_" + this.id + ".xlsx";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    })
  }
}
