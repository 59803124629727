import { Billing } from "./Billing";
import { ShipmentReferences } from "./ShipmentReferences";
import { SpecialServices } from "./SpecialServices";
import { LabelOptions } from "./LabelOptions";

export class ShipmentInfo {
    Account: any; 
    Currency: any; 
    DropOffType: any; 
    ServiceType: any; 
    UnitOfMeasurement: any;  
    PaperlessTradeEnabled: any;  
    Billing: Billing;  
    ShipmentReferences: ShipmentReferences;  
    SpecialServices: SpecialServices;  
    LabelOptions:LabelOptions;
}