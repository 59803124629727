import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {RoleService} from '../role.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  username:any;

  userDetails:any={};
  userId:any;
  constructor(private location: Location,private router: Router,private roleService:RoleService,private toastr: ToastrService) { }

  ngOnInit() {
  
   
  }
  search(){
    this.roleService.getForgotPasswordDetails(this.username).subscribe((data:any)=>{

    if (data[0].status =="Invalied User"){
      this.toastr.warning("Invalid user")
      this.username="";
    }
    else if(data[0].status =="User Is Locked Already"){
      this.toastr.warning("User Is Locked Already")
      this.router.navigate(['/login']);
    }
    else{
      this.toastr.success("Password reset link has been sent to your registered email");
      this.username="";
      this.router.navigate(['/login']);
    }
    },(err) => {this.toastr.error(err);})
 
  }
  backClicked(){
    this.back();
  }
  back() {
    this.location.back();
  }
}
