import { Component, OnInit } from "@angular/core";
import { SalesRepresentative } from "../../models/salesRepresentative";
import { Location } from "@angular/common";
import { ManagementService } from "../../management/management.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-add-sales-rep",
  templateUrl: "./add-sales-rep.component.html",
  styleUrls: ["../../home/home.component.css"],
})
export class AddSalesRepComponent implements OnInit {
  salesRepresentative: any = new SalesRepresentative();
  type: any;
  sub: any;
  id: number;
  Gender: { Type: string; Id: number }[];
  Roles: { description: string; roleId: number }[];
  onlyNumberDecimal:boolean=false;
  isHide: boolean = true;
  constructor(
    private location: Location,
    private managementService: ManagementService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.Gender = [
      { Type: "Male", Id: 2 },
      { Type: "Female", Id: 1 },
    ];
    this.Roles = [{ description: "Sales Rep User", roleId: 7 }];
    this.salesRepresentative.roleId = 7;
    this.sub = this.route.params.subscribe((params) => {
      this.type = params["type"];
      this.id = params["id"];
    });
    if (this.type === "edit" && this.id != undefined) {
      let object = {
        Id: this.id,
      };
      this.GetSalesRepManagement(object);
    }
  }

  ngOnInit() {}
  GetSalesRepManagement(object) {
    this.spinner.show();
    this.managementService
      .getSalesRepManagement(object)
      .subscribe((data: any) => {
        this.salesRepresentative = data[0];;
        this.spinner.hide()
      });
  }
  Checked() {
    if (this.salesRepresentative.status == true) {
      this.salesRepresentative.status = false;
    } else {
      this.salesRepresentative.status = true;
    }
  }
  back() {
    this.location.back();
  }
  backClicked() {
    this.back();
  }
  onChangeGender($event) {
    this.salesRepresentative.gender = $event;
  }
  onChangeRole($event) {
    this.salesRepresentative.roleId = $event;
  }
  onSubmit() {
    this.spinner.show();
    this.salesRepresentative.companyId = localStorage.getItem("DealerId");
    this.managementService
      .addandUpdateSalesRepresentative(this.salesRepresentative)
      .subscribe(
        (data: any) => {
          if (data.message == "Created") {
            this.toastr.success(
              "New Sales Representative has been added successfully"
            );
            this.spinner.hide();
            this.location.back();
          } else if (data.message == "Updated") {
            this.toastr.success(
              "Sales Representative has been updated successfully"
            );
            this.spinner.hide();
            this.location.back();           
          }
          else if(data.message == "User Already Exist"){
            this.toastr.warning("Username already exist"); 
            this.spinner.hide();
          }          
        },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide();
        }
      );
  }
  keyPressNumbersWithDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      this.onlyNumberDecimal = true;
      return false;
    }
    this.onlyNumberDecimal = false;
    return true;
  }

}
