export class CRMLead {
    id: any=null;
    name: any;
    email: any;
    mobile: any;
    street: any;
    postalCode: any;
    city: any;
    webPage: any;
    fax: any;
    comments: any;
    countryId: any;
    createdBy: any;
    salesRepId: any;     
    refCompanyId: any;
    status: any;
    dentistId:any;
    isConvertedFromClient:any;
    conversionDate:any;
    UpdatedDate:any;
    CreatedDate:any;
  }