//repeat calendar enum
export enum RepeatPattern
    {
       RN="Never",
       RD="Daily",
       RW="Weekly",
       RM="Monthly",
       RY="Yearly"
    }

    export enum monthRepeatPattern
    {
       First="1",
       Second="2",
       Third="3",
       Fourth="4",
       Fifth="5",
       Sixth="6",
       Seventh="7",
       Eighth="8",
       Ninth="9",
       Tenth="10",
       Eleventh="11",
       Twelfth="12",
       Thirteenth="13",
       Fourteenth="14",
       Fifteenth="15",
       Sixteenth="16",
       Seventeenth="17",
       Eighteenth="18",
       Ninteenth="19",
       Twentieth="20",
       Twenty_first="21",
       Twenty_second="22",
       Twenty_third="23",
       Twenty_fourth="24",
       Twenty_fifth="25",
       Twenty_sixth="26",
       Twenty_seventh="27",
       Twenty_eighth="28",
       Twenty_ninth="29",
       Thirtieth="30",
       Thirty_first="31"
    }
    export enum yearmonthRepeatPattern
    {
       January="1",
       February="2",
       March="3",
       April="4",
       May="5",
       June="6",
       July="7",
       August="8",
       September="9",
       October="10",
       November="11",
       December="12",
    }