import {Component, OnInit  } from "@angular/core";
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageTasksService } from "../manage-tasks.service";
import { TaskSharedService } from "../tasks-share.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ReminderDate } from "../../enum/statusEnum";
import { formatDate } from "@angular/common";

@Component({
    selector: 'app-drag-drop-task',
    templateUrl: './drag-drop-task.component.html',
    styleUrls: ['./drag-drop-task.component.css']

})
export class dragDropTaskComponent implements OnInit {
    UserId:any;
    crmTasksData:any=[];
    FilterTaskData:any=[];
    statusToBeDone:any=[];
    statusDoing:any=[];
    statusDone:any=[];
    userDetails:any=[];
    filterTask:any;
    statusbject:any={};
    type: any;
    //date range variable
    dateCustomClasses: DatepickerDateCustomClasses[];
    fromDate: any = null;
    toDate: any = null;
    ReminderDate: any;
    DateForReminder: any;
    reminder: boolean = false;
    ReminderDay = ReminderDate.reminderday;

    constructor(private spinner:NgxSpinnerService, private manageTasksService:ManageTasksService, private tasksharedservice:TaskSharedService,
    private router:Router, private toastr:ToastrService)
    {
      let dt = new Date();
      dt.setDate( dt.getDate() + this.ReminderDay );
      this.ReminderDate = formatDate(dt,'yyyy-MM-dd','en_US');
      let dt1 = new Date();
      dt1.setDate( dt1.getDate() - this.ReminderDay );
      this.DateForReminder = formatDate(dt1,'yyyy-MM-dd','en_us');
    }

    ngOnInit() {
    window.scrollTo(0, 0)
    this.UserId = localStorage.getItem("userid");
    this.filterTask = this.tasksharedservice.searchTitle;
    this.fromDate = this.tasksharedservice.fromDate;
    this.toDate = this.tasksharedservice.toDate;
    this.getCRMTasksData();
    }

    getCRMTasksData() {
        this.spinner.show();
        let object = {
          'FromDate': this.fromDate,
          'ToDate': this.toDate,
          'UserId': this.UserId
        }
        this.manageTasksService.getCRMTasks(object).subscribe((data: any) => {
            let gridTasksData = data.crmTaskList;
            let gridtaskcommentdata = data.taskComment;
            gridTasksData.forEach(ele => {
              if(gridtaskcommentdata != null && gridtaskcommentdata.length > 0){
                gridtaskcommentdata.forEach(ele2 => {
                  if(ele.id == ele2.taskId){
                    if(ele2.isReminder == true && (this.ReminderDate >= formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') && formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') >= this.DateForReminder)){
                     this.reminder = true;         
                    } 
                  }           
                });
              }
              if(this.reminder == true){
                ele.isActive = true;
                this.reminder = false;
              } else{
                ele.isActive = false;
              }
              this.crmTasksData.push(ele);
            });
            this.statusToBeDone = [];
            this.statusDoing = [];
            this.statusDone = [];
            this.statusToBeDone = this.crmTasksData.filter(x => x.status === 1);
            this.statusDoing = this.crmTasksData.filter(y => y.status === 2);
            this.statusDone = this.crmTasksData.filter(z => z.status === 3);            
            this.FilterTaskData =[];
            this.FilterTaskData =  this.crmTasksData;
            this.searchTasks();
            this.spinner.hide();
        }, (err) => { this.toastr.error(err);
          this.spinner.hide();
        });
      }

      GridToList(){
        this.tasksharedservice.searchTitle = this.filterTask;
        this.tasksharedservice.fromDate = this.fromDate;
        this.tasksharedservice.toDate = this.toDate;
        this.router.navigate([
          "/managetasks",
        ]);
      }

      searchTaskByDateRange(fromdate,todate){
        this.fromDate = fromdate;
        this.toDate = todate;
        if(this.fromDate == null || this.toDate == null){
          this.fromDate = null;
          this.toDate = null;
          this.getCRMTasksData();
        }
        else{
          this.fromDate = fromdate;
          this.toDate = todate;
          this.fromDate = this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate();
          this.toDate = this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + this.toDate.getDate();
          this.getCRMTasksData();
        }
      }

      searchTasks(){
        if(this.filterTask == undefined || this.filterTask == null){
         this.crmTasksData = this.FilterTaskData;
         this.statusToBeDone = this.crmTasksData.filter(x => x.status === 1);
         this.statusDoing = this.crmTasksData.filter(y => y.status === 2);
         this.statusDone = this.crmTasksData.filter(z => z.status === 3);
        }
        else{     
         this.crmTasksData = this.FilterTaskData.filter((res: any) => {
           return (res.title.toLowerCase().includes(this.filterTask.toLowerCase()))
         })
         this.statusToBeDone = this.crmTasksData.filter(x => x.status === 1);
         this.statusDoing = this.crmTasksData.filter(y => y.status === 2);
         this.statusDone = this.crmTasksData.filter(z => z.status === 3);
        }
        this.statusToBeDone.sort((a, b) => (
          b.isActive - a.isActive
        ));  
        this.statusDoing.sort((a, b) => (
          b.isActive - a.isActive
        ));
        this.statusDone.sort((a, b) => (
          b.isActive - a.isActive
        ));
       }

      drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
          event.previousContainer._draggables.reset;
        }
        else {
          this.statusbject = '';
          let indexNumber = event.previousContainer.data.findIndex(item => item === event.item.data)
          this.statusbject = event.previousContainer.data[indexNumber];
          this.statusbject.status = event.container.id;
          this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
          this.statusbject.createdBy = this.userDetails.teethRUsUserId;
          this.statusbject.crmTaskAttachment = [];
          this.manageTasksService.AddandUpdateTasks(this.statusbject).subscribe(
            (data:any)=>{
              if (data.message == "2") {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
                this.getCRMTasksData();
                this.toastr.success("Task Moved");
            }
            else {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
                this.getCRMTasksData();
                this.toastr.warning("No Access" + event.container.id);
            }
            },
            (err) => {
              this.toastr.error(err);
          });
        }
      }

      downloadTaskAttachment(item) {        
        let object = {
         'taskid': item.id
       }
      //Download Service here 
       this.manageTasksService.GetTaskUploadedFiles(object).subscribe((data: any) => {
         if (data != null) {
           for (let a = 0; a < data.crmTaskAttachment.length; a++) {
             const linkSource = 'data:application/pdf;base64,' + data.crmTaskAttachment[a].file;
             const downloadLink = document.createElement("a");
             const fileName = data.crmTaskAttachment[a].fileName;
             downloadLink.href = linkSource;
             downloadLink.download = fileName;
             downloadLink.click();
           }
         }
         else {
           this.toastr.info("File Path Not Found");
         }
       })
     }
     openRecordNewTab(id) {
      this.type = "edit";
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["managetasks/updatecrmtask/" + this.type + "/" + id])
      );
      window.open(url, '_blank');
    }

}