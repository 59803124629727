import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../management.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SystemConfigurations } from '../../models/system-configurations';
@Component({
  selector: 'app-setting-configuration',
  templateUrl: './setting-configuration.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class SettingConfigurationComponent implements OnInit {
  fromemail:any;
  fromemailpwd:any;
  host:any;
  port:any;
  systemDetails:any;
  systemConfigurations: any = new SystemConfigurations();
  constructor(private managementService: ManagementService, private toastr: ToastrService,private location: Location) { }

  ngOnInit() {
    this.managementService.getSystemConfiguration().subscribe((data: any) => {
      this.systemConfigurations = data;
    }, (err) => { this.toastr.error(err); })
  }
  backClicked(){
    this.location.back();
  }
  onSubmit(){
 
    this.managementService.updatesettingconfiguration(this.systemConfigurations).subscribe((data: any) => {

      this.toastr.success("Updated successfully");
      this.location.back();
    }, (err) => { this.toastr.error(err); })
  }
}
