export enum CRMLeadPerPage {
    first = 20,
    second = 50,
    third = 100,
  }

  export enum TaskPerPage {
    first = 20,
    second = 50,
    third = 100,
  }

  export enum SalesRepPerPage {
    first = 10,
    second = 20,
    third = 50,
  }