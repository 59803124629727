import { Component, OnInit } from "@angular/core";
import { ManagementService } from "../management.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { SalesRepPerPage } from '../../enum/pagesEnum';
@Component({
  selector: "app-sales-rep-management",
  templateUrl: "./sales-rep-management.component.html",
  styleUrls: ["../../home/home.component.css"],
})
export class SalesRepManagementComponent implements OnInit {
  salesRepresentative: any = [];
  forFilterSalesRepData:any=[];
  type: any;
  sub: any;
  id: number;
  countryCode: string;
  currencyCode: any;
  filterSalesRep:any;
   //variable for Sales rep per page
   pageSize:any = SalesRepPerPage.first;
   first = SalesRepPerPage.first;
   second = SalesRepPerPage.second;
   third = SalesRepPerPage.third;
   firstRow = true;
   secondRow = false;
   thirdRow = false;
   isHide: boolean = true;
  constructor(
    private managementService: ManagementService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.countryCode = localStorage.getItem("CountryCode");
    this.currencyCode = localStorage.getItem("CurrencyCode");
    this.GetSalesRepManagement();
  }
  setPageSize(size) {
    if (size == SalesRepPerPage.first) {
      this.pageSize = size;
      this.firstRow = true;
      this.secondRow = false;
      this.thirdRow = false;
    } else if (size == SalesRepPerPage.second) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = true;
      this.thirdRow = false;
    } else if (size == SalesRepPerPage.third) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = false;
      this.thirdRow = true;
    }
  }
  GetSalesRepManagement() {
    this.salesRepresentative = [];
    this.forFilterSalesRepData = [];
    let object = {
      Id: null,
    };
    this.spinner.show();
    this.managementService.getSalesRepManagement(object).subscribe(
      (data: any) => {
        this.salesRepresentative = data;
        this.forFilterSalesRepData = this.salesRepresentative;
        for (var i = 0; i < this.salesRepresentative.length; i++) {
          if (this.salesRepresentative[i].commissionAmount != 0) {
            this.salesRepresentative[i].commissionAmount =
              this.salesRepresentative[i].commissionAmount.toLocaleString(
                this.countryCode,
                { style: "currency", currency: this.currencyCode }
              );
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  }
  edit(item) {
    this.type = "edit";
    this.router.navigate([
      "management/salesrep/updateSalesRep/" + this.type + "/" + item.id,
    ]);
  }
  delete(item) {
    let object = {
      Id: item.id,
    };
    this.managementService.deleteSalesRepresentative(object).subscribe(
      (data: any) => {
        if (data.message == "Deleted") {
          this.toastr.success(
            "Sales Representative has been deleted successfully"
          );
          this.GetSalesRepManagement();
        }
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  }
  searchSalesRep(){
    if(this.filterSalesRep === undefined || this.filterSalesRep === null){
       this.salesRepresentative = this.forFilterSalesRepData; 
    } else{
      this.salesRepresentative = this.forFilterSalesRepData.filter((res: any) => {
        return (((res.firstName.toLowerCase()+res.lastName.toLowerCase()).includes(this.filterSalesRep.toLowerCase()))
                || (res.email.toLowerCase().includes(this.filterSalesRep.toLowerCase())))
      })
    }
  }
}
