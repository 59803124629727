export class SalesRepresentative {
  id: any;
  firstName: any;
  lastName: any;
  gender: any;
  roleId: any;
  mobile: any;
  email: any;
  commissionAmount: any;
  status: boolean = true;
  username: any;
  companyId: number;
}
