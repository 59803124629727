import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchComponent } from '../share/global-search/global-search.component';
import { FormsModule } from '@angular/forms'; 
import { GlobalSearchService } from './global-search/global-search.service';
//import { TestingComponent } from './testing/testing.component';
import {ExcelService} from './excel.service';
import {OrderByPipe} from '../directives/OrderByPipe';
import { DentistUploadFilesComponent } from '../cases/dentist-upload-files/dentist-upload-files.component';
import { UploadFilesDirective } from '../cases/dentist-upload-files/upload-files.directive';
import { ProgressComponent } from '../cases/dentist-upload-files/progress/progress.component';
@NgModule({
  declarations: [ GlobalSearchComponent,OrderByPipe,
    DentistUploadFilesComponent,
    UploadFilesDirective,
    ProgressComponent, ],
  imports: [
    CommonModule,
    FormsModule,
    
  ],
  exports:[GlobalSearchComponent,OrderByPipe,DentistUploadFilesComponent,
    UploadFilesDirective,
    ProgressComponent, ],
  providers:[GlobalSearchService,ExcelService] 
})

export class ShareModule { }
