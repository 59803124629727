import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {
    private readonly serviceBase = 'https://localhost:44392/';
  
  //private readonly serviceBase = 'http://ascdevs.com:8042/';
  
  //private readonly serviceBase = 'http://ascdevs.com:8042/';
  //private readonly serviceBase = 'http://ascdevs.com:8042/';
  //private readonly serviceBase = 'http://localhost:8036/';

  //public webTwainProductKey ='f0068WQAAAI2GZhkGVEWmD2Xpz1bGRafGEqdOEOujzfkGgCaNDxahkUZJgawQxRuDEYGA6NqslcswqKrB2XqDT6mnZAXnvgg=';
  //private readonly serviceBase = 'http://192.168.1.41:8093/';

  //private readonly serviceBase = 'https://ascdevs.com:8093/';
  //private readonly serviceBase = 'http://217.16.197.86:8093/';
  //private readonly serviceBase = 'http://217.16.197.86:80/';
  constructor() { }

  getServiceBaseUrl() {
    return this.serviceBase;
  }
}
