import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { formatDate, Location } from "@angular/common";
import { CRMLead } from "../../models/crmLead";
import { ManageLeadsService } from "../manage-leads/manage-leads.service";
import { ToastrService } from "ngx-toastr";
import { LeadsStatus, LeadsStatusLabelMapping, ReminderDate } from "../../enum/statusEnum";
import { CompanyTypeId } from "../../enums";
import { CompaniesService } from "src/app/companies/companies.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-add-lead",
  templateUrl: "./add-lead.component.html",
  styleUrls: ["../../home/home.component.css"],
})
export class AddLeadComponent implements OnInit {
  modalRef: BsModalRef;
  CRMLead: any = new CRMLead();
  leadtaskLabelMapping = LeadsStatusLabelMapping;
  statuslead = Object.values(LeadsStatus).filter(value => typeof value === 'number');
  countries: any = [];
  userDetails: any = [];
  SalesRepId: any;
  SalesRepUser: any = [];
  type: any;
  sub: any;
  id: number;
  newLeadId: any;
  newDentistId: any;
  history: string[] = [];
  CommentId: any = 0;
  LeadComment: any = "";
  LeadCommentList: any = [];
  ActivityTypeList: any = [];
  ActivityId: any = -1;
  commentPageSize: any = 12;
  IsReminder: boolean = false;
  ReminderDate: any;
  DateForReminder: any;
  ReminderDay = ReminderDate.reminderday;

  constructor(private manageleadService: ManageLeadsService, private toastr: ToastrService, private route: ActivatedRoute, private modalService: BsModalService,
    private location: Location, private router: Router, private companyservice: CompaniesService, private spinner: NgxSpinnerService) {
    this.CRMLead.status = 1;
    this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
    this.SalesRepId = this.userDetails.salesRepId;
    if (this.SalesRepId === null) {
      this.manageleadService.GetSalesRepUser().subscribe((data: any) => {
        this.SalesRepUser = data;
      });
    }
    else {
      this.CRMLead.salesRepId = this.SalesRepId;
    }
    this.sub = this.route.params.subscribe((params) => {
      this.type = params["type"];
      this.id = params["id"];
    });
    if (this.type === "edit" && this.id != undefined) {
      var Id = this.id;
      this.GetCRMLeadById(Id);
      this.GetCRMLeadCommentByLeadId(this.id);
    }
    this.manageleadService.GetCountries().subscribe((response: any) => {
      this.countries = response;
    }, (err) => { this.toastr.error(err); });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    });
    let dt = new Date();
    dt.setDate(dt.getDate() + this.ReminderDay);
    this.ReminderDate = formatDate(dt, 'yyyy-MM-dd', 'en_US');
    let dt1 = new Date();
    dt1.setDate(dt1.getDate() - this.ReminderDay);
    this.DateForReminder = formatDate(dt1, 'yyyy-MM-dd', 'en_us');

    this.GetActivityType();
  }

  ngOnInit() { }

  onChangeCountry($event) {
    this.CRMLead.countryId = $event;
  }

  onChangeStatus($event) {
    this.CRMLead.status = $event;
  }
  onChangeSalesRep($event) {
    this.CRMLead.salesRepId = $event.salesRepId;
  }

  back() {
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl("crm/manageleads/leads")
    }
  }

  backClicked() {
    this.back();
  }

  GetActivityType() {
    this.manageleadService.GetActivityType().subscribe((data: any) => {
      this.ActivityTypeList = data;
    });
  }

  GetCRMLeadById(id) {
    this.manageleadService.GetCRMLeadById(id).subscribe((data: any) => {
      this.CRMLead = data;
    });
  }

  onSubmit() {
    this.CRMLead.createdBy = this.userDetails.teethRUsUserId;
    this.CRMLead.refCompanyId = localStorage.getItem("DealerId");
    if (this.CRMLead.id === null) {
      this.manageleadService.addandUpdateLead(this.CRMLead).subscribe(
        (data: any) => {
          this.newLeadId = data;
          if (this.CRMLead.status == 5) {
            this.AddCompany();
          }
          this.toastr.success("CRM Lead has been created successfully");
          this.back();
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    }
    else {
      this.manageleadService.addandUpdateLead(this.CRMLead).subscribe(
        (data: any) => {
          this.newLeadId = data;
          this.newDentistId = this.CRMLead.dentistId;
          if (this.CRMLead.status === 5) {
            if (!this.CRMLead.dentistId) {
              this.AddCompany();
            }
            else {
              this.updateLeadStatus();
              const obj = {
                dentistId: this.newDentistId,
                isDelete: false,
              };
              this.manageleadService.updateCompany(obj).subscribe((data: any) => {
              });
            }
          }
          else {
            if (this.CRMLead.dentistId) {
              this.updateLeadStatus();
              const obj = {
                dentistId: this.newDentistId,
                isDelete: true,
              };
              this.manageleadService.updateCompany(obj).subscribe((data: any) => {
              });
            }
            else {
              this.updateLeadStatus();
            }
          }
          this.toastr.success("CRM Lead has been updated successfully");
          this.back();
        }, (err) => {
          this.toastr.error(err);
        }
      );
    }
  }

  AddCompany() {
    const dentistObj = {
      CompanyTypeId: CompanyTypeId.Dentist,
      Street: this.CRMLead.street,
      PostalCode: this.CRMLead.postalCode,
      City: this.CRMLead.city,
      Email: this.CRMLead.email,
      PhoneNumber: this.CRMLead.mobile,
      CountryId: this.CRMLead.countryId,
      RefCompanyId: this.CRMLead.refCompanyId,
      Name: this.CRMLead.name,
      CompanyNumber: this.CRMLead.name,
      SalesRepId: this.CRMLead.salesRepId,
    };
    this.companyservice.Addcompany(dentistObj).subscribe(
      (response: any) => {
        if (response) {
          this.newDentistId = response;
          this.updateLeadStatus();
        }
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }

  updateLeadStatus() {
    const crmObj = {
      Id: this.newLeadId,
      StatusId: this.CRMLead.status,
      DentistId: this.newDentistId,
      UserId: localStorage.getItem("userid"),
      ConversionDate: new Date(),
      isConvertedFromClient: true,
    };
    this.manageleadService.updateLeadStatus(crmObj).subscribe((data: any) => {
    },
      (err) => {
        this.toastr.error(err);
      });
  }

  submitComment() {
    if(this.ActivityId == null || this.ActivityId == -1 || this.ActivityId == "-1" || this.ActivityId == undefined){
      this.toastr.warning("Please select activity type !");
      return false;
    }
    if (this.LeadComment == "" || this.LeadComment == null) {
      this.toastr.warning("Cannot submit empty comment !");
    } else {
      let obj = {
        Id: this.CommentId,
        LeadComment: this.LeadComment,
        UserId: this.userDetails.teethRUsUserId,
        LeadId: this.id,
        IsReminder: this.IsReminder,
        ActivityId: this.ActivityId
      }
      this.spinner.show()
      this.manageleadService.AddandUpdateLeadComment(obj).subscribe(
        (data: any) => {
          if (this.CommentId == 0) {
            this.toastr.success("Lead comment added successfully");
          } else {
            this.toastr.success("Lead comment updated successfully");
          }
          this.CommentId = 0;
          this.LeadComment = "";
          this.IsReminder = false;
          this.GetCRMLeadCommentByLeadId(this.id);
          this.spinner.hide()
          this.ActivityId = "-1";
          (document.getElementById("SelCom") as HTMLSelectElement).value = "-1";
        },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide()
        }
      );
    }
  }

  editComment(item) {
    if (item.postedUser !== this.userDetails.teethRUsUserId) {
      this.toastr.warning("You dont have permission to edit this comment");
    } else {
      this.CommentId = item.id;
      this.LeadComment = item.leadComment;
    }
  }

  deleteComment(item, template: TemplateRef<any>) {
    if (item.postedUser !== this.userDetails.teethRUsUserId) {
      this.toastr.warning("You dont have permission to delete this comment");
    } else {
      this.CommentId = item.id;
      this.modalRef = this.modalService.show(template);
    }
  }

  cancelDeleteComment() {
    this.CommentId = 0;
    this.modalRef.hide();
  }

  confirmDeleteComment() {
    this.manageleadService.DeleteLeadComment(this.CommentId).subscribe((data: any) => {
      if (data == 1) {
        this.CommentId = 0;
        this.GetCRMLeadCommentByLeadId(this.id)
        this.toastr.success("Lead comment has been deleted Successfully");
      }
      else {
        this.toastr.error("Something is wrong please contact to administration");
      }
    },
      (err) => {
        this.toastr.error(err);
      });
    this.modalRef.hide();
  }

  GetCRMLeadCommentByLeadId(id) {
    this.spinner.show()
    this.LeadCommentList = [];
    this.manageleadService.GetCRMLeadCommentByLeadId(id).subscribe((data: any) => {
      let leadCommentList = data;
      leadCommentList.forEach(element => {
        if (element.isReminder == true && (this.ReminderDate >= formatDate(element.postedDate, 'yyyy-MM-dd', 'en_US') && formatDate(element.postedDate, 'yyyy-MM-dd', 'en_US') >= this.DateForReminder)) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
        this.LeadCommentList.push(element);
      });
      this.LeadCommentList.sort((a, b) => (
        b.isActive - a.isActive
      ));
      this.spinner.hide()
    });
  }

  SetActivityValue(event) {
    this.ActivityId = event.target.value;
  }

}