export class Country {
    countryId: number;
    country: string;
    shortName: string;
    flagFileName: string;
    countryCode: string;
    currencyName: string;
    currencyShortName: string;
    dentalNotation: any;
    ////==========base64formatedata Varibles=========////
    Base64Data: any;
    FileName: any;
    FileType: any;
}