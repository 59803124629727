import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ManageLeadsService } from "./manage-leads.service";
import { CRMLeadPerPage } from "../../enum/pagesEnum";
import { PaginationService } from "src/app/services/pagination.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LeadsStatus, LeadsStatusLabelMapping, ReminderDate } from "../../enum/statusEnum";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-manage-leads",
  templateUrl: "./manage-leads.component.html",
  styleUrls: ["../../home/home.component.css"],
})
export class ManageLeadsComponent implements OnInit {
  crmLeadsData = [];
  FilterLeadData = [];
  filterLead: any;
  pageNumber: boolean[] = [];

  dealerlogeed: boolean = false;
  modalRef: BsModalRef;
  pageSize: any = CRMLeadPerPage.first;
  leadId: any;
  opened: any = false;
  type: any;
  //Variables for Leads per page
  first = CRMLeadPerPage.first;
  second = CRMLeadPerPage.second;
  third = CRMLeadPerPage.third;
  firstRow = true;
  secondRow = false;
  thirdRow = false;
  currentPage: any = 1;
  sortBy = "Name;";
  searchText: string = "";
  order: string = "Name";
  SalesRepId: any;
  statusleadLabelMapping = LeadsStatusLabelMapping;
  statuslead = Object.values(LeadsStatus).filter(
    (value) => typeof value === "number"
  );
  reverse: boolean = false;
  statusvalue: any;
  userDetails: any = [];
  ReminderDate: any;
  DateForReminder: any;
  reminder: boolean = false;
  ReminderDay = ReminderDate.reminderday;

  constructor(
    private manageLeadsService: ManageLeadsService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private router: Router,
    public paginationService: PaginationService,
    private spinner: NgxSpinnerService
  ) {
    let dt = new Date();
    dt.setDate( dt.getDate() + this.ReminderDay );
    this.ReminderDate = formatDate(dt,'yyyy-MM-dd','en_US');
    let dt1 = new Date();
    dt1.setDate( dt1.getDate() - this.ReminderDay );
    this.DateForReminder = formatDate(dt1,'yyyy-MM-dd','en_us');
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
    this.statusvalue = "all";
    
    this.getCRMLeadsData();

    this.sortBy = "Name";
    this.currentPage = 1;
    this.pageSize = CRMLeadPerPage.first;
    this.pageNumber = [];
    this.pageNumber[0] = true;
    this.paginationService.temppage = 0;
    this.paginationService.currentPage = 1;
    this.paginationService.showNoOfCurrentPage = 1;
  }

  addCRMLeads() {
    this.router.navigate(["crm/newcrmlead"]);
  }

  editCRMLeads(id) {
    this.type = "edit";
    this.router.navigate(["crm/updatecrmlead/" + this.type + "/" + id]);
  }

  getCRMLeadsData() {
    this.SalesRepId = this.userDetails.salesRepId;
    this.crmLeadsData = [];
    this.FilterLeadData = [];
    this.spinner.show();
    this.manageLeadsService.getCRMLeads(this.SalesRepId).subscribe((data: any) => {
        let leaddata = data.crmleads;
        let commentdata = data.leadsComment;
        leaddata.forEach(ele => {
          if(commentdata != null && commentdata.length > 0){
            commentdata.forEach(ele2 => {
              if(ele.id == ele2.leadId){
                if(ele2.isReminder == true && (this.ReminderDate >= formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') && formatDate(ele2.postedDate,'yyyy-MM-dd','en_US') >= this.DateForReminder)){
                 this.reminder = true;         
                } 
              }           
            });
          }
          if(this.reminder == true){
            ele.isActive = true;
            this.reminder = false;
          } else{
            ele.isActive = false;
          }
          this.crmLeadsData.push(ele);
          this.FilterLeadData.push(ele);
        });  
        this.crmLeadsData.sort((a, b) => (
          b.isActive - a.isActive
        ));  
        this.FilterLeadData.sort((a, b) => (
          b.isActive - a.isActive
        ));
        this.spinner.hide();
      });
  }
  
  trashCRMLeads(Id, template: TemplateRef<any>) {
    this.leadId = Id;
    this.modalRef = this.modalService.show(template);
  }

  decline() {
    this.modalRef.hide();
  }

  confirm(status) {
    let object = {
      leadId: this.leadId,
    };
    this.manageLeadsService.trashLead(object).subscribe(
      (response: any) => {
        if (response == 1) {
          this.getCRMLeadsData();
          this.toastr.success("Lead has been deleted Successfully");
        } else {
          this.toastr.error(
            "Something is wrong please contact to administration"
          );
        }
      },
      (err) => {
        this.toastr.error(err);
      }
    );
    this.modalRef.hide();
  }

  //Setting the records per page -For ex 50,70,100 Active color of page
  setPageSize(size) {
    this.pageNumber = [];
    this.paginationService.temppage = 0;

    if (size == CRMLeadPerPage.first) {
      this.pageSize = size;
      this.firstRow = true;
      this.secondRow = false;
      this.thirdRow = false;
      this.currentPage = 1;
    } else if (size == CRMLeadPerPage.second) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = true;
      this.thirdRow = false;
      this.currentPage = 1;
    } else if (size == CRMLeadPerPage.third) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = false;
      this.thirdRow = true;
      this.currentPage = 1;
    }
    this.pageNumber[0] = true;
  }

  setLeads(value: string) {
    if (this.sortBy === value) {
      this.reverse = !this.reverse;
    }
    this.sortBy = value;
  }

  searchLeads() {
    if (this.filterLead == undefined || this.filterLead == null) {
      if (this.statusvalue == undefined || this.statusvalue == "all") {
        this.crmLeadsData = this.FilterLeadData;
      } else {
        this.crmLeadsData = this.FilterLeadData.filter((res: any) => {
          return res.status == this.statusvalue;
        });
      }
    } else {
      if (this.statusvalue == undefined || this.statusvalue == "all") {
        this.crmLeadsData = this.FilterLeadData.filter((res: any) => {
          return res.name.toLowerCase().includes(this.filterLead.toLowerCase());
        });
      } else {
        this.crmLeadsData = this.FilterLeadData.filter((res: any) => {
          return (
            res.name.toLowerCase().includes(this.filterLead.toLowerCase()) &&
            res.status == this.statusvalue
          );
        });
      }
    }
  }

  searchByStatus(value) {
    this.statusvalue = value;
    this.crmLeadsData = [];
    if (this.statusvalue == undefined || this.statusvalue == "all") {
      if (this.filterLead == undefined || this.filterLead == null) {
        this.crmLeadsData = this.FilterLeadData;
      } else {
        this.crmLeadsData = this.FilterLeadData.filter((res: any) => {
          return res.name.toLowerCase().includes(this.filterLead.toLowerCase());
        });
      }
    } else {
      if (this.filterLead == undefined || this.filterLead == null) {
        this.crmLeadsData = this.FilterLeadData.filter((res: any) => {
          return res.status == this.statusvalue;
        });
      } else {
        this.crmLeadsData = this.FilterLeadData.filter((res: any) => {
          return (
            res.name.toLowerCase().includes(this.filterLead.toLowerCase()) &&
            res.status == this.statusvalue
          );
        });
      }
    }
  }
}
