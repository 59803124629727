import { Component, OnInit } from '@angular/core';
import { PrintService } from '../print.service';
import { Htmlconverter } from './htmlconverter';
import{CasesService}from './../cases.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-order-print',
  templateUrl: './order-print.component.html',
  styleUrls: ['./order-print.component.css']
})
export class OrderPrintComponent implements OnInit {
//Printing Variables
isRequired:boolean=false;
htmlconvert = new Htmlconverter();
ignoreWhenPrinting: boolean = false;
  constructor(public printService: PrintService,public  CasesService:CasesService) { }

  ngOnInit() {
    //this.isRequired=true;
  }
    //Print 
    onPrintInvoice() {
      var y=`<h1>Hi GoodMorning<h1>`
      this.htmlconvert.html1 = y;
      this.CasesService.Getpdf(this.htmlconvert)
        .subscribe(blob => {
      var blob1 = new Blob([blob], {type: 'application/pdf'});
      const blobUrl = URL.createObjectURL(blob1);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    });

         
  
  
      // this.isRequired=true;
      // this.ignoreWhenPrinting = true;
      // const invoiceIds = [];
      // this.printService.caseNumber = 54666;
      // this.printService
      //   .printDocument('ordereprint', invoiceIds);
        //this.isRequired=false;
    }

}
