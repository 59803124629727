import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ManageCountryGuard implements CanActivate {
  constructor(private router: Router){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if ( localStorage.getItem('loginStatus') != null ) {
        
        if(localStorage.getItem('ShowManagmentsManageCountry')==='true'){
          return true;
        }
        else{
          this.router.navigate(['/permissiondeined']);
        }
      }
      else{
        this.router.navigate(['login']);
        return false;
      }
  }
}
