import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ConfigService } from '../app.config';
import * as FileSaver from 'file-saver';
import { map } from 'rxjs/operators';
import { Htmlconvert } from './htmlconvert';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private url = "";
  private baseUrl = "";

  adminNoticPhotoUrl:any='';
  employeeNoticPhotoUrl:any='';
  httpClient: any;
  constructor(public http: HttpClient, private configService: ConfigService) {
    
  
    this.baseUrl = configService.getServiceBaseUrl();
    this.adminNoticPhotoUrl=this.baseUrl+'Uploads/AdminNotice';
    this.employeeNoticPhotoUrl=this.baseUrl+'Uploads/EmployeeAdminNotice';
  }
  getQuickYearOverview(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetQuickYearOverview';
    return this.http.post(this.url,object);
  }
  getDealerList() {
    this.url = this.baseUrl + 'api/Product/GetDealers';
    return this.http.get(this.url);
  }
  getPriceList(dealerId): Observable<any> {
    this.url = this.baseUrl + 'api/Product/GetDealerPriceList/?dealerId=' + dealerId;
    return this.http.get(this.url)
  }
  getMiscExcelReportsDentistCompanies(object) {
    this.url = this.baseUrl + 'api/Reports/GetMiscExcelReportsDentistCompanies';
    return this.http.post(this.url, object);
    
   // return this.http.post(`${this.baseUrl}api/Reports/GetMiscExcelReportsDentistCompanies`,object, { responseType: 'blob' })
    // .subscribe(
    //   (res) => {
    //         var blob = new Blob([res], {type: 'application/vnd.ms-excel'} )
    //         return blob;            
    //   });
    
  //     .subscribe((res) => {
  //       var newblob = new Blob([res], {type: 'application/vnd.ms-excel'} )
  //        FileSaver.saveAs(newblob, fileName);
  // }); 
  }
  getMiscExeclExportForPriceList(object): Observable<any> {
    this.url = this.baseUrl + 'api/Reports/GetMiscExeclExportForPriceList';
    return this.http.post(this.url, object);
  }
  getMiscExeclExportProducts(object): Observable<any> {
    this.url = this.baseUrl + 'api/Reports/GetMiscExeclExportProducts'; 
    return this.http.post(this.url, object);
  }
  ////////////////////////////////////////////////////////////////////////////////
  getDentistCompanies(object): Observable<any> {
    this.url = this.baseUrl + 'api/Reports/GetDentistCompanies';
    return this.http.post(this.url, object);
  }
  exportCases(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/ExportCases';
    return this.http.post(this.url, object);
  }
  exportCasesByDealer(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/ExportCasesByDealer';
    return this.http.post(this.url, object);
  }
  /////////////// Statistics Reports Api's ///////////////////////////
  getUnitsAndMaterialsForEachProduct(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetUnitsAndMaterialsForEachProduct';
    return this.http.post(this.url, object);
  }
  getChartBasedOnUnitsSold(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetChartBasedOnUnitsSold';
    return this.http.post(this.url,object);
  }
  getChartBasedOnRevenueBreakDown(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetChartBasedOnRevenueBreakDown';
    return this.http.post(this.url,object);
  }
  getChartBasedDentistCompanyAddedMonthly(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetChartBasedDentistCompanyAddedMonthly';
    return this.http.post(this.url,object);
  }
  getChartBasedRevenueGenerators(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetRevenueGeneratorsByDensistComapnay';
    return this.http.post(this.url,object);
  }
  //////////salesRep Dashboard///////////
  getChartBasedMonthlyLeads(SalesRepId): Observable<any>{
    if(SalesRepId === null){
      this.url = this.baseUrl + 'api/Reports/getChartBasedMonthlyLeads?SalesRepId?=' + SalesRepId;
    }
    else{
      this.url = this.baseUrl + 'api/Reports/getChartBasedMonthlyLeads?SalesRepId=' + SalesRepId;
    }
    return this.http.get(this.url);
  }
  getCRMMessagesForDashboard(ReceiverId): Observable<any>{
    this.url = this.baseUrl + 'api/CRMMessage/GetCRMMessagesForDashboard?ReceiverId=' + ReceiverId;
    return this.http.get(this.url);
  }
   ////////////////////-Dentist Information-//////////////////

   getChartBasedDentistCompanyUnitsAdded(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/ChartBasedDentistCompanyUnitsAdded';
    return this.http.post(this.url,object);
   }
   getGetTopTenWallMesagesDentist(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetTopTenWallMesagesDentist';
    return this.http.post(this.url,object);
   }
   getGetFinishedCasesForDentist(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetFinishedCasesForDentist';
    return this.http.post(this.url,object);
   }
   createFeedbackForCase(object): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/CreateFeedbackForCase';
    return this.http.post(this.url,object);
   }
   //============================New Reports==================
   dentistRevenues(object):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetNewDentistRevenue';
    return this.http.post(this.url,object);
   }
   getActiveSalesRepresentative():Observable<any>{
    this.url=this.baseUrl + 'api/Management/GetActiveSalesRepresentative';
    return this.http.get(this.url);
   }
   getRevenueByDentist(object):Observable<any>{
    this.url=this.baseUrl + 'api/Reports/GetRevenueByDentist';
    return this.http.post(this.url,object);
   }
   getRevenueByLeadToCustomer(object):Observable<any>{
     this.url=this.baseUrl + 'api/Reports/GetRevenueByLeadToCustomer';
     return this.http.post(this.url,object);
   }
   getCustomers(object):Observable<any>{
    this.url=this.baseUrl + 'api/Reports/GetCustomers';
    return this.http.post(this.url,object);
    }
   //===============AdminNotice================================//
   addUpdateAdminNotice(object):Observable<any>{
    this.url=this.baseUrl + 'api/Reports/AddUpdateAdminNotice';
    return this.http.post(this.url,object);
   }
   getAdminNotice(object):Observable<any>{
    this.url=this.baseUrl + 'api/Reports/GetAdminNotice';
    return this.http.post(this.url,object);
   }
   addandGetUploadedFilePath(object):Observable<any>{
    this.url=this.baseUrl + 'api/Reports/AddandGetUploadedFilePath';
    return this.http.post(this.url,object);
   }
   getSalesRepCommissionReport(object):Observable<any>{
    this.url=this.baseUrl + 'api/Reports/GetSalesRepCommissionReport';
    return this.http.post(this.url,object);
   }
   getOngoingCaseDeliverylist(object) :Observable<any>{
     this.url=this.baseUrl + 'api/Reports/GetOngoingCaseDeliverylist';
     return this.http.post(this.url,object);
   }
   getDentistCompaniesForInvoice(companyId): Observable<any>{
    this.url = this.baseUrl + 'api/Invoice/GetDentistCompaniesForInvoice?companyId='+companyId;
    return this.http.get(this.url);
  }
  getPerformanceStatistics(object):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetPerformanceStatistics';
    return this.http.post(this.url,object);
  }
  getUnitService(object):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetUnitCounter';
    return this.http.post(this.url,object);
  }
  getCompareRevenueListByDentistsMonth(object):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetCompareRevenueListByDentistsMonth';
    return this.http.post(this.url,object);
  }
  getRemakes(object):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetRemake';
    return this.http.post(this.url,object);
  }
  getInvoice(object):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetInvoiceReport';
    return this.http.post(this.url,object);
  }
  Getpdf(htmlconvert:Htmlconvert):Observable<Blob>{
    return this.http.post(`${this.baseUrl}api/Reports/DownloadPdf`,htmlconvert,{responseType:'blob'});
  }
  //==================PERIODICAL CASE STATISTICS======================
  Getpdffile(htmlconvert:Htmlconvert):Observable<any>{
    this.url = this.baseUrl + 'api/Reports/DownloadPdf';
    return this.http.post(this.url,htmlconvert);
   
  }
  GetAllInvoiceReport(): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GetAllInvoiceReport';
    return this.http.get(this.url);
  }
  UpdateStatus(UserId,Id,orderId, reportName, status): Observable<any> {
    this.url = this.baseUrl + 'api/Reports/Updateinvoicejobstatus/?UserId=' + UserId+ '&Id=' + Id+ '&orderId=' + orderId+ '&reportName=' + reportName+ '&status=' + status;
    return this.http.get(this.url)
  }
  
  GetComapanyDetails(companyId): Observable<any>{
    this.url = this.baseUrl + 'api/company/GetCompanyDetail/?companyId=' + companyId;
    return this.http.get(this.url);
  }

  GenerateUsers(objectList: any): Observable<any>{
    this.url = this.baseUrl + 'api/Reports/GenerateUsers';
    return this.http.post(this.url,objectList);
  }

  getOngoingCaselist(object) :Observable<any>{
    this.url=this.baseUrl + 'api/Reports/GetOngoingCaselist';
    return this.http.post(this.url,object);
  }

}