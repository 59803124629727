import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/app.config";

@Injectable({ providedIn: "root" })
export class ManageTasksService {
    baseUrl: string;
    userID: string;
    private url = "";
    taskFileUrl:any='';
  
    constructor(private http: HttpClient, private configService: ConfigService) {
      this.userID = localStorage.getItem("userid");
      this.baseUrl = configService.getServiceBaseUrl();
      this.taskFileUrl=this.baseUrl+'Uploads/TasksFiles';
    }

    GetTaskUploadedFiles(object): Observable<any> {
      this.url = this.baseUrl + 'api/Tasks/GetTaskUploadedFiles';
      return this.http.post(this.url, object);
  }

    getCRMTasks(object): Observable<any> {
      this.url = this.baseUrl + "api/Tasks/getCRMTasks";
      return this.http.post(this.url, object);
    }

    GetCRMTaskById(object):Observable<any>{
      this.url=this.baseUrl +'api/Tasks/GetCRMTaskById';
      return this.http.post(this.url,object);
    }
    
    AddandUpdateTasks(object):Observable<any>{
        this.url=this.baseUrl +'api/Tasks/AddandUpdateTask';
        return this.http.post(this.url,object);
    }

    trashTask(object): Observable<any> {
      this.url = this.baseUrl + "api/Tasks/DeleteTask";
      return this.http.post(this.url, object);
    }

    removeTaskFile(object): Observable<any> {
      this.url = this.baseUrl + "api/Tasks/removeTaskFile";
      return this.http.post(this.url, object);
    }

    AddandUpdateTaskComment(object):Observable<any>{
      this.url=this.baseUrl +'api/Tasks/AddandUpdateTaskComment';
      return this.http.post(this.url,object);
    }

  GetCRMTaskCommentByTaskId(id):Observable<any>{
    this.url=this.baseUrl + "api/Tasks/GetCRMTaskCommentByTaskId?=" + id;
    return this.http.get(this.url);
  }

  DeleteTaskComment(id) {
    this.url = this.baseUrl + "api/Tasks/DeleteTaskComment?id=" + id;
    return this.http.get(this.url);
  }
     
}