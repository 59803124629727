import { Component, ComponentRef, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, ChangeDetectionStrategy} from "@angular/core";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="fc-content" [ngbPopover]="template" container="body" triggers="manual">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// [placement]="['top', 'top-left', 'auto']"
// style="html: false;"
export class PopoverWrapperComponent implements OnInit {
  template: TemplateRef<any>;
  @ViewChild(NgbPopover) popover: NgbPopover;//, {static: true}
  constructor(private cd:ChangeDetectorRef){}
  ngOnInit(){ 
  }
  
  ngAfterContentChecked(){
    this.cd.detectChanges();
  }
}