import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from '../role.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  username: any;
  newpassword: any;
  confirnnewpassword: any;
  sub: any;
  url: any;
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private roleService: RoleService) {

    this.url = decodeURI(this.router.url);

    let subStringData = this.url.substr(13);

    this.username = subStringData;



  }

  ngOnInit() {

  }
  onSubmit() {
    if (this.newpassword === this.confirnnewpassword) {
      let object = {
        'Password': this.newpassword,
        'Username': this.username
      }

      this.roleService.updateForgotPassword(object).subscribe((data: any) => {

        if (data) {
          this.router.navigate(['/login']);
          this.toastr.success("Password has been updated successfully");

        }
      }, (err) => { this.toastr.error(err); })
    }
    else {

      this.toastr.warning("Password is not matched with Confirm password");
      this.newpassword = "";
      this.confirnnewpassword = "";
    }
  }
}
