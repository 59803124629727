import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ReportsService } from '../reports/reports.service';
import { ToastrService } from 'ngx-toastr';
import { NoticeType } from '../models/noticeType';
@Component({
  selector: 'app-employee-admin-notice',
  templateUrl: './employee-admin-notice.component.html',
  styleUrls: ['../home/home.component.css']
})
export class EmployeeAdminNoticeComponent implements OnInit {
  file: any = [];
  photoUrl: any;
  FileName: any;
  FileType: any;
  Base64Data: any;

  Notice: any = '';
  NoticeId: any = null;
  addedFilePath: any = null;

  @ViewChild('removeImageName') removeImageName: ElementRef;
  constructor(private reportService: ReportsService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getAdminNotice();

  }
  getAdminNotice() {
    let object = {
      'NoticeType': NoticeType.EmployeeAdmin
    }
    this.reportService.getAdminNotice(object).subscribe((data: any) => {
      if (data.length !== 0) {
        this.Notice = data[0].notice;
        this.NoticeId = data[0].id;
      }
      else {
        this.Notice = ''
      }
    })
  }
  save() {
    var object = null;
    if (this.NoticeId != null) {
      object = {
        'Id': this.NoticeId,
        'Notice': this.Notice,
        'UpdatedBy': localStorage.getItem('userid'),
        'NoticeType': NoticeType.EmployeeAdmin
      }
    }
    else {
      object = {
        'Notice': this.Notice,
        'CreatedBy': localStorage.getItem('userid'),
        'NoticeType': NoticeType.EmployeeAdmin
      }
    }

    this.reportService.addUpdateAdminNotice(object).subscribe((data: any) => {
      if (data.message == 'Created') {
        this.toastr.success("Employee Admin notice has been added successfully");

      }
      else if (data.message == 'Updated') {
        this.toastr.success("Employee Admin notice has been updated successfully");

      }
    })
  }


  handleFileSelect(evt) {
    this.addedFilePath = null;
    var File = evt.target.value;
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var FileType = subStringData.split('.')[1];

    this.FileName = FileName;
    this.FileType = FileType;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.Base64Data = btoa(binaryString);

  }

  reset() {
    this.removeImageName.nativeElement.value = "";
  }

  copyText() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.addedFilePath;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  onDrop($event) {

  }
  UploadedFiles($event) {

  }
  uploadImages() {
    if (this.file == null || this.file == '') {
      this.toastr.warning("Choose the file");
    }
    else {
      for (let x = 0; x < this.file.length; x++) {
        let obj = {
          "FileName": this.file[x].name.split('.')[0],
          "FileType": this.file[x].fileType,
          "Base64Data": this.file[x].base64Data,
          'NoticeType': NoticeType.AdminNoticeType
        }

        this.toastr.success("Admin notice has been updated successfully");
        this.reportService.addandGetUploadedFilePath(obj).subscribe((data: any) => {
          this.addedFilePath = this.reportService.adminNoticPhotoUrl + '/' + data.message;


        })
      }

    }
  }
}
