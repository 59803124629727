import { Component, OnInit } from '@angular/core';
import {RoleService} from '../role.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  currentpassword:any;
  newpassword:any;
  confirnnewpassword:any;
  constructor(private roleService:RoleService,private toastr: ToastrService,
              private router: Router,private location: Location) {

   
   }

  ngOnInit() {
  }
  back(){
    this.location.back();
  }
 
  onSubmit() {
    if(this.newpassword == this.confirnnewpassword)
    {
      let obj={
        'Password':this.currentpassword,
        'NewPassword':this.newpassword,
        'Username':localStorage.getItem('username'),
      }
     
      this.roleService.changePassword(obj).subscribe((data:any)=>{
     
        if(data=='0'){
          this.toastr.error("Invalid User");
        }
        else if(data=='1'){
          this.toastr.success("Password Updated Successfully");
          // this.router.navigate(['/login']);
          this.back();
        }
        else if(data=='2'){
          this.toastr.warning("Please Enter Current Password Correctly");
          //  this.newpassword="";
          // this.currentpassword="";
    
        }
      },(err) => {this.toastr.error(err);})
     
     // this.currentpassword="";
    }
    else{
    
      this.toastr.warning("New Password is not matched with Confirm New Password");
     // this.newpassword="";
      this.confirnnewpassword="";
  
      }
  }
  backButton(){
    this.back();
  }
}
