import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "src/app/app.config";
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ManageLeadsService {
  baseUrl: string;
  userID: string;
  private url = "";
  CountryPhotoUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.userID = localStorage.getItem("userid");
    this.baseUrl = configService.getServiceBaseUrl();
    this.CountryPhotoUrl = this.baseUrl + "Uploads/country";
  }
  getCRMLeads(SalesRepId): Observable<any> {
    if(SalesRepId === null){
      this.url = this.baseUrl + "api/Leads/GetCRMLeads?SalesRepId?=" + SalesRepId;
    }
    else{
      this.url = this.baseUrl + "api/Leads/GetCRMLeads?SalesRepId=" + SalesRepId;
    }
    return this.http.get(this.url);
  }

  GetActivityType(): Observable<any> {
    this.url = this.baseUrl + "api/Leads/GetActivityType";
    return this.http.get(this.url);
  }

  GetSalesRepUser(): Observable<any> {
    this.url = this.baseUrl + "api/Leads/GetSalesRepUser";
    return this.http.get(this.url);
  }

  GetCRMLeadById(id): Observable<any> {
    this.url = this.baseUrl + "api/Leads/GetCRMLeadById?Id=" + id;
    return this.http.get(this.url);
  }

  updateLead(id, data): Observable<any> {
    this.url = this.baseUrl + "api/Lead/UpdateLead";
    return this.http.post<any[]>(this.url, data);
  }

  trashLead(object): Observable<any> {
    this.url = this.baseUrl + "api/Leads/DeleteLead";
    return this.http.post(this.url, object);
  }

  // Leads Dashboard api
  updateLeadStatus(data): Observable<any> {
    this.url = this.baseUrl + "api/Leads/UpdateLeadStatus";
    return this.http.post(this.url, data);
  }

  updateCompany(object): Observable<any> {
    this.url = this.baseUrl + "api/Leads/UpdateCompany";
    return this.http.post(this.url, object);
  }

  getLeadsCountByStatus(SalesRepId) {
    this.url =
      this.baseUrl + "api/Leads/GetLeadsCountByStatus?SalesRepId?=" + SalesRepId;
    return this.http.get(this.url);
  }

  getLeadsBySalesRepId(object) {
    this.url = this.baseUrl + "api/Leads/GetLeadsBySalesRepId";
    return this.http.post(this.url, object);
  }

  GetCountries(): Observable<any> {
    this.url = this.baseUrl + "api/company/GetCountries";
    return this.http.get<any[]>(this.url);
  }

  addandUpdateLead(object): Observable<any> {
    this.url = this.baseUrl + "api/Leads/AddandUpdateLead";
    return this.http.post(this.url, object);
  }

  AddandUpdateLeadComment(object):Observable<any>{
    this.url=this.baseUrl +'api/Leads/AddandUpdateLeadComment';
    return this.http.post(this.url,object);
  }

  GetCRMLeadCommentByLeadId(id):Observable<any>{
  this.url=this.baseUrl + "api/Leads/GetCRMLeadCommentByLeadId?=" + id;
  return this.http.get(this.url);
}

DeleteLeadComment(id) {
  this.url = this.baseUrl + "api/Leads/DeleteLeadComment?id=" + id;
  return this.http.get(this.url);
}

}
