import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ConfigService } from '../app.config'; 


@Injectable()

export class ManagementService {
    private url="";
    private baseUrl="";
    public PhotoUrl ="";
    
  
    constructor(public http: HttpClient,private configService:ConfigService) { 
  
        this.baseUrl = configService.getServiceBaseUrl();
        this.PhotoUrl=this.baseUrl+'Uploads/Country';
       
    }

    getAllCountry() {
        this.url = this.baseUrl + 'api/management/GetAllCountry';
        return this.http.get(this.url);
    }

    getDentalNotation() {
        this.url = this.baseUrl + 'api/management/GetDentalNotation';
        return this.http.get(this.url);
    }

    //Adding new Country 
    addCountryInforamtion(countryInfo){
        this.url = this.baseUrl + 'api/management/AddOrUpdateCountry';
        return this.http.post(this.url,countryInfo);
    }
    // //Updating new Country 
    // editCountryInforamtion(countryId,data){
    //     this.url = this.baseUrl + 'api/management/AddOrUpdateCountryInfo?countryId='+countryId;
    //     return this.http.put(this.url,data);
    // }
    //Delete Country
    deleteCountry(object){
        this.url = this.baseUrl + 'api/management/DeleteCountry';
        return this.http.post(this.url,object);
        
    }
    getDealerCompanyForInvoice(object): Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/GetDealerCompanyForInvoice';
        return this.http.post(this.url,object);
      }
      getAgentCompanyForInvoice(companyId): Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/GetAgentCompanyForInvoice?companyId='+companyId;
        return this.http.get(this.url);
      }
      getDentistCompaniesForInvoice(companyId): Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/GetDentistCompaniesForInvoice?companyId='+companyId;
        return this.http.get(this.url);
      }
      getMarkSelection(object):Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/GetMarkSelectionInvoice';
        return this.http.post(this.url,object);
      }
      createInvoiceJob(object):Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/CreateInvoiceJob';
        return this.http.post(this.url,object);
      }
      showInvoiceJobs(object):Observable<any>{
        this.url=this.baseUrl+'api/Invoice/GetInvoiceJobs';
        return this.http.post(this.url,object);
      }
      restartJob(id):Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/RestartJob?InvoiceJobId='+id;
        return this.http.get(this.url);
      }
      getInvoiceJobsDetails(id):Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/GetInvoiceJobsDetails?InvoiceJobId='+id;
        return this.http.get(this.url);
      }
      exportCasesForInvoiceJob(id):Observable<any>{
        this.url = this.baseUrl + 'api/Invoice/ExportCasesForInvoiceJob?InvoiceJobId='+id;
        return this.http.get(this.url);
      }
    //  =========================GROUP MESSAGING=============================
    getUsers():Observable<any>{
      this.url = this.baseUrl + 'api/Management/GetUsers';
      return this.http.get(this.url);
    }
    addNewGroup(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/AddNewGroup';
      return this.http.post(this.url,object);
    }
    getAllGroups():Observable<any>{
      this.url=this.baseUrl +'api/Management/GetAllGroups';
      return this.http.get(this.url);
    }
    deleteGroupMessage(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/DeleteGroupMessage';
      return this.http.post(this.url,object);
    }
    //send Group SMS
    sendGroupSms(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/SendGroupSms';
      return this.http.post(this.url,object);
    }
    //send Group Email
    sendGroupMail(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/SendGroupMail';
      return this.http.post(this.url,object);
    }
    //edit perticular group
    getGroupDetailsById(id):Observable<any>{
      this.url=this.baseUrl +'api/Management/GetGroupDetailsById?MessageGroupId='+id;
      return this.http.get(this.url);
    }
    /////////SalesRepManagement//////////
    getSalesRepManagement(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/GetSalesRepManagement';
      return this.http.post(this.url,object);
    }
    addandUpdateSalesRepresentative(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/AddandUpdateSalesRepresentative';
      return this.http.post(this.url,object);
    }
    deleteSalesRepresentative(object):Observable<any>{
      this.url=this.baseUrl +'api/Management/DeleteSalesRepresentative';
      return this.http.post(this.url,object);
    }
    deletenotinvoicedjob(id):Observable<any>{
      this.url=this.baseUrl +'api/Invoice/UpdateInnvoiceJob?InvoiceJobId='+id;
      return this.http.get(this.url);
    }
    getSystemConfiguration() {
      this.url = this.baseUrl + 'api/management/GetSysystemConfiguration';
      return this.http.get(this.url);
  }
  updatesettingconfiguration(object):Observable<any>{
    this.url=this.baseUrl +'api/Management/UpdateSystemConfiguration';
    return this.http.post(this.url,object);
  }
}
