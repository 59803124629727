import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { GlobalSearchService } from './global-search.service';
import { Router, NavigationEnd, ParamMap } from '@angular/router';
import { PrintService } from '../../cases/print.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class GlobalSearchComponent implements OnInit {
  // tesxtVale:any;
  // @Input() description: any;
  // @Output() increment: EventEmitter<any> = new EventEmitter();
  // @ViewChild("name") nameField: ElementRef;


  deliveryNo = null;
  caseData = [];
  dealerId = localStorage.getItem('DealerId');
  searchId;

  disabledWhenPageLoadsCases: boolean = true;
  disabledWhenPageLoadsCompany: boolean = true;
  companyNameOrNumber = null;
  activatedRoute: any;
  RoleName: any;



  test: any;
  constructor(private globalSearchService: GlobalSearchService, private router: Router
    , public printService: PrintService) {
    this.RoleName = localStorage.getItem('RoleName');
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    // this.router.events.subscribe((evt) => {
    //   if (evt instanceof NavigationEnd) {
    //     // trick the Router into believing it's last link wasn't previously loaded
    //     this.router.navigated = false;
    //     // if you need to scroll back to top, here is the right place
    //     window.scrollTo(0, 0);
    //   }
    // });
    // this.router.events.subscribe(() => {
    //   // Here is the other part of the trick
    //   this.router.navigated = false;
    //   this.router.navigate([this.router.url]);
    // })
  }
  // override the route reuse strategy

  ngOnInit() {

    // setTimeout(() => {
    //   this.router.navigated = false;
    //   this.router.navigate([this.router.url]);
    // }, 500);
  }


  search() {
    if (this.companyNameOrNumber == "") {
      this.companyNameOrNumber = null;
    }
    if (this.searchId == "Company Name/No.") {
      this.router.navigate(['companies/search/' + this.companyNameOrNumber]);


    } else if (this.searchId == "Staff or employee") {
      this.router.navigate(['dentAppUsers/' + this.companyNameOrNumber]);
    }
    else if (this.searchId == "Case" || this.searchId == "Add Materials") {
      if (this.deliveryNo == "") {
        this.deliveryNo = null;
      }
      this.router.navigate(['cases/dashboard-list-view/' + this.searchId + '/' + this.deliveryNo]);
      // this.router.navigate(['cases/dashboard-list-view'],{ queryParams: { searchId: this.searchId ,deliveryNumber : this.deliveryNo}});
    }

  }
  //Key Press Hit when hit Enter Key
  pressEnter($event) {

    if ($event.keyCode === 13) {
      this.search();
    }
  }

  onSearchChange($event) {

    $(document).ready(function () {
      $(".AutoFocus").focus();
      $(".AutoFocus").val("");


    })

    if ($event == "Company Name/No." || $event == "Staff or employee") {
      this.searchId = $event;
      this.disabledWhenPageLoadsCompany = false;
      this.disabledWhenPageLoadsCases = true;
      // this.nameField.nativeElement.focus();


    } else if ($event == "Case" || $event == "Add Materials") {
      this.searchId = $event;
      this.disabledWhenPageLoadsCases = false;
      this.disabledWhenPageLoadsCompany = true;
      // this.nameField.nativeElement.focus();

    }
  }

}
