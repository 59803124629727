import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../management.service';
import { addNewGroupMessaging } from '../../models/addNewGroupMessageing';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageGroup } from '../../models/messageGroup';
@Component({
  selector: 'app-addnewgroup',
  templateUrl: './addnewgroup.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class AddnewgroupComponent implements OnInit {
  //filterpurpose 
  userDetails: any;
  //
  addNewGroupMessaging: any = new addNewGroupMessaging();
  alluserDetails: any = [];
  membersAddedInGroup: any = [];

  UserFullName: string;


  type: any;
  sub: any;
  messageGroupId: number;


  selectedUser: any = [];
  unselectedUsers: any = [];
  fromTelephone: any;
  fromEmail: any;
  constructor(private managementService: ManagementService, private toastr: ToastrService,
    private location: Location, private route: ActivatedRoute) {

    this.sub = this.route.params.subscribe(params => {
      this.type = params['type'];
      this.messageGroupId = params['messageGroupId'];
      //If it is edit meams the below condition will execute....
      if (this.type === 'edit' && this.messageGroupId != null) {
        this.managementService.getGroupDetailsById(this.messageGroupId).subscribe((data: any) => {

          this.addNewGroupMessaging = data.addNewGroupMessaging;
          this.UserFullName = data.addNewGroupMessaging.createdBy;
          this.fromTelephone = data.addNewGroupMessaging.fromTelephone;
          this.fromEmail = data.addNewGroupMessaging.fromEmail;
          // this.addNewGroupMessaging.createdByTeethRUsUserId =data.addNewGroupMessaging[0].createdBy;
          this.membersAddedInGroup = data.membersInGroup;
          this.alluserDetails = data.remainingUsers;
        })
      }
      //If it is Add meams the below condition will execute....
      else {

        var today = new Date();
        var year = new Date().getFullYear().toString().substr(0);
        var month = (new Date().getMonth() + 1).toString().substr(0);
        var date = new Date().getDate().toString().substr(0);

        var hours = new Date().getHours().toString().substr(0);
        var minutes = new Date().getMinutes().toString().substr(0);
        var seconds = new Date().getSeconds().toString().substr(0);
        if (month.length == 1) {
          month = 0 + month;
        }
        if (date.length == 1) {
          date = 0 + date;
        }
        if (hours.length == 1) {
          hours = 0 + hours;
        }
        if (minutes.length == 1) {
          minutes = 0 + minutes;
        }
        if (seconds.length == 1) {
          seconds = 0 + seconds;
        }
        // var currentdate = new Date();
        // var datetime1 =currentdate.getFullYear() + "-" + (currentdate.getMonth()+1)
        //   + "-" + currentdate.getDay() + " @ "
        //   + currentdate.getHours() + ":"
        //   + currentdate.getMinutes() + ":" + currentdate.getSeconds();
        var dateTime = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;

        this.addNewGroupMessaging.fromEmail = MessageGroup.FormEmail;
        this.fromTelephone = MessageGroup.ForFromTelephone;
        this.fromEmail = MessageGroup.FormEmail;
        this.addNewGroupMessaging.createDate = dateTime

        this.UserFullName = localStorage.getItem('UserFullName');
        this.managementService.getUsers().subscribe((data: any) => {

          this.alluserDetails = data;
        })
      }

    })
  }

  ngOnInit() {

  }
  onItemSelect(item, event) {
    if (event.shiftKey) {
      this.selectedUser.unshift(item.teethRUsUserId);

    } else {
      this.selectedUser = [];

      this.selectedUser.unshift(item.teethRUsUserId);
    }
  }

  addusers() {
    let userdetailsbyid: any = {}
    this.selectedUser.forEach((item) => {

      userdetailsbyid = this.alluserDetails.filter(x => x.teethRUsUserId === item);

      if (userdetailsbyid.length === 0) {

      }
      else {

        if (this.membersAddedInGroup.filter(x => x.teethRUsUserId === userdetailsbyid[0].teethRUsUserId)) {

          this.membersAddedInGroup.push(userdetailsbyid[0]);


          this.alluserDetails = this.alluserDetails.filter(item => item.teethRUsUserId !== userdetailsbyid[0].teethRUsUserId)

        }
        else {


        }
      }
    })
    this.selectedUser = [];
  }


  onItemUnSelect(item, event) {
    if (event.shiftKey) {
      this.unselectedUsers.unshift(item.teethRUsUserId);

    } else {
      this.unselectedUsers = [];

      this.unselectedUsers.unshift(item.teethRUsUserId);
    }


  }
  removeUsers() {
    let removeUserDetailsbyid: any = {}
    this.unselectedUsers.forEach((item) => {

      removeUserDetailsbyid = this.membersAddedInGroup.filter(x => x.teethRUsUserId === item);

      if (removeUserDetailsbyid.length === 0) {

      }
      else {
        this.alluserDetails.push(removeUserDetailsbyid[0]);
        this.membersAddedInGroup = this.membersAddedInGroup.filter(item => item.teethRUsUserId !== removeUserDetailsbyid[0].teethRUsUserId)

      }
    })
    this.unselectedUsers = [];
  }



  onSubmit() {
    var userIDS: any = [];
    var userDetails1: any = {};

    this.membersAddedInGroup.forEach((item) => {

      userDetails1 = this.membersAddedInGroup.filter(x => x.teethRUsUserId == item.teethRUsUserId);
      userIDS.push(userDetails1[0].teethRUsUserId)
    })

    var TeethRUsUserIds = userIDS.toString();
    this.addNewGroupMessaging.TeethRUsUserIds = TeethRUsUserIds;
    this.addNewGroupMessaging.MessageGroupId = 0;
    // this.addNewGroupMessaging.CreatedByTeethRUsUserId = this.managementService.userID;

    if (this.type === 'edit') {
      this.addNewGroupMessaging.MessageGroupId = this.messageGroupId;
      this.addNewGroupMessaging.CreatedByTeethRUsUserId = localStorage.getItem('userid');
      this.addNewGroupMessaging.fromTelephone = this.fromTelephone;
      this.addNewGroupMessaging.fromEmail = this.fromEmail;

      this.managementService.addNewGroup(this.addNewGroupMessaging).subscribe((data: any) => {
        ;
        this.toastr.success("Group has been updated successfully");
        this.location.back();
      }, (err) => { this.toastr.error(err); })
    }
    else {
      this.addNewGroupMessaging.MessageGroupId = 0
      this.addNewGroupMessaging.CreatedByTeethRUsUserId = localStorage.getItem('userid');
      this.addNewGroupMessaging.fromTelephone = this.fromTelephone;
      this.addNewGroupMessaging.fromEmail = this.fromEmail;

      this.managementService.addNewGroup(this.addNewGroupMessaging).subscribe((data: any) => {

        this.toastr.success("New group has been added successfully" + ' ' + data);
        this.location.back();
      }, (err) => { this.toastr.error(err); })
    }

  }

  back() {
    this.location.back();
  }
  backClicked() {
    this.back();
  }


}
