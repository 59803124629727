import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-share-calendar",
  templateUrl: "./share-calendar.component.html",
  styleUrls: ["./share-calendar.component.css"],
})
export class ShareCalendarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
