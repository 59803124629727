import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  isLabelPrinting =false;
  leftSideMenu =false;
  labelPrinting =true;
  caseNumber;
  labelCompanyPrinting: boolean=true;

  constructor(private router: Router,private titleService:Title) { }

  printDocument(documentName: string, documentData: string[]) {
    this.isPrinting = true;
    this.leftSideMenu =true;
    this.titleService.setTitle("Case Details for :"+this.caseNumber+" - DentApp");
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', documentName, documentData.join()]
      }}]);
  }
  //Print Label
  printLabel(documentName: string, documentData: string[]) {
    this.isLabelPrinting = true;
    this.leftSideMenu =true;
    this.labelPrinting =false;
   // this.titleService.setTitle("Case Details for :"+this.caseNumber+" - DentApp");
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', documentName, documentData.join()]
      }}]);
  }

    //Print Company Label
    printCompanyLabel(documentName: string, documentData: string[]) {
     // this.isLabelPrinting = true;
      this.leftSideMenu =true;
      this.labelCompanyPrinting =false;
     // this.titleService.setTitle("Case Details for :"+this.caseNumber+" - DentApp");
      this.router.navigate(['/',
        { outlets: {
          'print': ['print', documentName, documentData.join()]
        }}]);
    }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.leftSideMenu =false;
      this.isLabelPrinting = false;
      this.labelCompanyPrinting =true;
      this.labelPrinting =true;
      this.titleService.setTitle("Dentnet - We make teeth Dent-App");
      this.router.navigate([{ outlets: { print: null }}]);
     });
  }
}
