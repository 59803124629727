
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { NgxPaginationModule } from "ngx-pagination";
import { NgxEditorModule } from 'ngx-editor';
import{ NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { ManageCountryComponent } from './manage-country/manage-country.component';
import {ManageCountryGuard} from '../management/managementGaurds/manage-country.guard';
import { InvoiceComponent } from './invoice/invoice.component';
import {ManagementService} from '../management/management.service';
import { ShowjobsComponent } from './showjobs/showjobs.component';
import { ViewjobComponent } from './viewjob/viewjob.component';
import { GroupmessagingComponent } from './groupmessaging/groupmessaging.component';
import { AddnewgroupComponent } from './addnewgroup/addnewgroup.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxSpinnerModule } from "ngx-spinner";
import { PreviousUrlGuard } from '../previousurl.guard';
import { SalesRepManagementComponent } from './sales-rep-management/sales-rep-management.component';
import { AddSalesRepComponent } from './add-sales-rep/add-sales-rep.component';
import { SettingConfigurationComponent } from './setting-configuration/setting-configuration.component';
const appRoutes: Routes = [
   
   { path: 'management/manage-country', component: ManageCountryComponent,canActivate:[ManageCountryGuard,PreviousUrlGuard]},
   //{ path: 'management/manage-country/edit', component: ManageCountryComponent }
   {path: 'management/invoice',component: InvoiceComponent,canActivate:[PreviousUrlGuard]},
   {path:'management/invoice/invoiceHistory',component:ShowjobsComponent,canActivate:[PreviousUrlGuard]},
   {path:'management/invoice/invoiceHistory/jobdetails/:id',component:ViewjobComponent,canActivate:[PreviousUrlGuard]},
   {path:'management/groupmessaging',component:GroupmessagingComponent,canActivate:[PreviousUrlGuard]},
   {path:'management/addnewgroup',component:AddnewgroupComponent,canActivate:[PreviousUrlGuard]},
   {path:'management/groupmessaging/updategroup/:type/:messageGroupId',component:AddnewgroupComponent,canActivate:[PreviousUrlGuard]},
    {path:'management/salesRepresentatives',component:SalesRepManagementComponent,canActivate:[PreviousUrlGuard]},
     {path:'management/addNewSalesRep',component:AddSalesRepComponent,canActivate:[PreviousUrlGuard]},
     {path:'management/salesrep/updateSalesRep/:type/:id',component:AddSalesRepComponent,canActivate:[PreviousUrlGuard]},
     {path:'management/setting-configuration',component:SettingConfigurationComponent,canActivate:[PreviousUrlGuard]},
  ];
  //  const appRoutes: Routes = [
   
  //    {path: '',component: InvoiceComponent},
  //    { path: 'manage-country', component: ManageCountryComponent,canActivate:[ManageCountryGuard]},
  //    //{ path: 'management/manage-country/edit', component: ManageCountryComponent }
  //    {path: 'invoice',component: InvoiceComponent},
  //    {path:'invoice/invoiceHistory',component:ShowjobsComponent},
  //    {path:'invoice/invoiceHistory/jobdetails/:id',component:ViewjobComponent},
  //    {path:'groupmessaging',component:GroupmessagingComponent},
  //    {path:'addnewgroup',component:AddnewgroupComponent},
  //    {path:'groupmessaging/updategroup/:type/:messageGroupId',component:AddnewgroupComponent}
      
   
  //  ];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes,{enableTracing:true}),
        //RouterModule.forChild(appRoutes),
        FormsModule,
        CommonModule,
        BsDatepickerModule.forRoot(),
        NgMultiSelectDropDownModule,
        NgxEditorModule,
        PopoverModule,
        Ng2SearchPipeModule,
        TooltipModule,
        CKEditorModule ,
        NgxSpinnerModule,
        NgxPaginationModule
      ],
      exports: [RouterModule],
      declarations: [
        ManageCountryComponent,
        InvoiceComponent,
        ShowjobsComponent,
        ViewjobComponent,
        GroupmessagingComponent,
        AddnewgroupComponent,
        SalesRepManagementComponent,
        AddSalesRepComponent,
        SettingConfigurationComponent

      ],
      providers:[ManagementService]
 
  })
  export class ManagementModule { }