import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { CasesService } from '../cases.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { MatRadioChange } from '@angular/material';
import { ShipmentRequestJson } from 'src/app/models/DHLModels/ShipmentRequestJson';
import { ShipmentRequest } from 'src/app/models/DHLModels/ShipmentRequest';
import { RequestedShipment } from 'src/app/models/DHLModels/RequestedShipment';
import { ShipmentInfo } from 'src/app/models/DHLModels/ShipmentInfo';
import { InternationalDetail } from 'src/app/models/DHLModels/InternationalDetail';
import { Commodities } from 'src/app/models/DHLModels/Commodities';
import { Ship } from 'src/app/models/DHLModels/Ship';
import { Shipper } from 'src/app/models/DHLModels/Shipper';
import { Recipient } from 'src/app/models/DHLModels/Recipient';
import { Packages } from 'src/app/models/DHLModels/Packages';
import { RequestedPackages } from 'src/app/models/DHLModels/RequestedPackages';
import { Dimensions } from 'src/app/models/DHLModels/Dimensions';
import { DHLAuth } from 'src/app/models/DHLModels/DHLAuth';
import { CreateShipment } from 'src/app/models/DHLModels/CreateShipment';
import { Address } from 'src/app/models/DHLModels/Address';
import { Contact } from 'src/app/models/DHLModels/Contact';
import { RequestPickup } from 'src/app/models/DHLModels/RequestPickup';
import { CreateRequestShipment } from 'src/app/models/DHLModels/CreateRequestShipment';
import { ExportDeclaration } from 'src/app/models/DHLModels/ExportDeclaration';
import { ExportLineItems, ExportLineItem } from 'src/app/models/DHLModels/ExportLineItems';
import { Service, SpecialServices } from 'src/app/models/DHLModels/SpecialServices';
import { LabelOptions } from 'src/app/models/DHLModels/LabelOptions';
import { ShipmentReference } from 'src/app/models/DHLModels/ShipmentReference';
import { ShipmentReferences } from 'src/app/models/DHLModels/ShipmentReferences';
import { Billing } from 'src/app/models/DHLModels/Billing';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-pickup',
  templateUrl: './order-pickup.component.html',
  styleUrls: ['../../home/home.component.css']
})

export class OrderPickupComponent implements OnInit {
  disableOrderPickup: boolean = false;
  userEmail = localStorage.getItem('UserEmail');
  companyEmail = localStorage.getItem('CompanyEmail');
  companyName = localStorage.getItem('CompanyName');
  userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  companyId = localStorage.getItem('CompanyId');
  firstName = this.userDetails.firstName;
  lastName = this.userDetails.lastName;
  dentistName = this.firstName + '' + this.lastName;
  modalRef: BsModalRef;
  _shipmentrequestJson: any = new ShipmentRequestJson();
  _shipmentrequest: any = new ShipmentRequest();
  _requestedshipment: any = new RequestedShipment();
  _shipmentinfo: any = new ShipmentInfo();
  _internationaldetail: any = new InternationalDetail();
  _commodities: any = new Commodities();

  _ship = new Ship();
  _shipper = new Shipper();
  _recipient = new Recipient();
  _packages = new Packages();
  _requestedpackages: any = new RequestedPackages();
  _dimensions: any = new Dimensions();
  _DHLAuth = new DHLAuth();
  _CreateRequestShipment = new CreateRequestShipment();
  _RequestPickup = new RequestPickup();
  IsDHLservice: boolean = true;
  DhlCheck: boolean;
  modeToCheckDHL: string = '';
  modeToCheckDHLService: string = '';
  // IsDHL:boolean=true;
  dealerId: any;
  roleName: any;
  userId = localStorage.getItem('userid');
  allCases: any = [];
  Selectedorderids: any = [];
  checkALL: boolean = false;
  checked: boolean = false;
  RecentShipments: any = [];
  dentistpickupDeliveryNote: any;
  IsPLT: boolean;
  _ExportDeclaration = new ExportDeclaration();
  _ExportLineItems = new ExportLineItems();
  _ExportLineItem = new ExportLineItem();
  _Service = new Service();
  _SpecialServices = new SpecialServices();
  _LabelOptions = new LabelOptions();
  _ShipmentReference = new ShipmentReference();
  _ShipmentReferences = new ShipmentReferences();
  _billing: any = new Billing();
  @ViewChild('templatecreateshipment') templatecreateshipment: TemplateRef<any>;
  url: any;
  constructor(private caseService: CasesService, private spinner: NgxSpinnerService, private toastr: ToastrService, private modalService: BsModalService) { }

  ngOnInit() {
    this.GetDentistDHLDetails();
    this.GetRecentShipments();
    //this.order();
    this.roleName = localStorage.getItem('RoleName');
    this.dealerId = localStorage.getItem('DealerId');
    this.url = this.caseService.CountryPhotoUrl;
  }

  orderPickUp() {
    let orderObj = {
      "Sender": this.userEmail,
      "Receiver": this.companyEmail,
      "DentistName": this.dentistName,
      "CompanyName": this.companyName
    }
    this.caseService.orderPickUpForDentist(orderObj).subscribe((data: any) => {
      if (data) {
        this.toastr.success("Email has been sent for order pickup successfully");
        this.disableOrderPickup = true;
        //this.closeModal();
      }
    })

  }

  ShipmentRequest(templatecreateshipment: TemplateRef<any>) {
    //this.IsDHLservice = true;
    this.modalRef = this.modalService.show(templatecreateshipment);
  }


  closeModal() {
    this.modalRef.hide();
    this.clearAll();
  }

  GetDentistDHLDetails() {
    this.caseService.GetDentistDHLDetails(this.companyId).subscribe((data: any) => {
      if (data.sender.length > 0) {
        this.Shipper(data.sender[0]);
      } else {
        this.modeToCheckDHL = 'false';
        this.DhlCheck = false;
      }
      if (data.reciever.length > 0) {
        this.Recipient(data.reciever[0]);
        if (data.sender[0].countryCode !== data.reciever[0].countryCode) {
          this._shipmentinfo.ServiceType = "P";
          this._shipmentinfo.Account = data.dealer[0].dhlImportAccountNumber;
        } else {
          this._shipmentinfo.ServiceType = "N";
          this._shipmentinfo.Account = data.dealer[0].dhlExportAccountNumber;
        }
      }
      if (data.dealer.length > 0) {
        this.DHLAuth(data.dealer[0]);
        this.Packages(data.dealer[0])
      } else {
        this.modeToCheckDHL = 'false';
        this.DhlCheck = false;
      }
      if (this._shipmentinfo.PaperlessTradeEnabled) {
        if (data.dealer.length > 0) {
          const _billing = new Billing();
          _billing.BillingAccountNumber = data.dealer[0].dhlExportAccountNumber;
          _billing.ShipperAccountNumber = data.dealer[0].dhlExportAccountNumber;
          _billing.ShippingPaymentType = "S";
          this._billing = _billing;
          this._shipmentinfo.Billing = this._billing;
          this._ExportLineItem.CommodityCode = data.dealer[0].commodityCode;
          this._ExportLineItem.UnitPrice = data.dealer[0].unitPrice;
          this._ExportLineItem.NetWeight = data.dealer[0].netWeight;
          this._ExportLineItem.GrossWeight = data.dealer[0].grossWeight;
          this._ExportLineItem.Quantity = data.dealer[0].quantity;
        }
      }
      if (data.table3.length > 0) {
        this.dentistpickupDeliveryNote = data.table3[0].pickupDeliveryNote;
      }
    })
  }
  Shipper(data) {
    const _Address = new Address();
    _Address.StreetLines = data.street;
    _Address.City = data.city;
    _Address.PostalCode = data.postalCode;
    _Address.CountryCode = data.countryCode;
    this._shipper.Address = _Address;
    const _Contact = new Contact();
    _Contact.PersonName = data.contactperson;
    _Contact.CompanyName = data.companyname;
    _Contact.PhoneNumber = data.phoneNumber;;
    _Contact.EmailAddress = data.email;
    this._shipper.Contact = _Contact;
    if (data.defaultDeliveryService && data.defaultDeliveryService == "3") {
      this.IsDHLservice = true;
      this.modeToCheckDHLService = 'true';
      if (data.countryCode == 'IS' || data.countryCode == 'NO') {
        this._shipmentinfo.PaperlessTradeEnabled = true;
        this.IsPLT = true;
      } else {
        this._shipmentinfo.PaperlessTradeEnabled = false;
        this.IsPLT = false;
      }
      /*  if (data.countryCode == 'NO') {
        this._shipmentinfo.PaperlessTradeEnabled=true;
        this.IsPLT=true;
      }else{
        this._shipmentinfo.PaperlessTradeEnabled=false;
        this.IsPLT=false;
      } */
      this.GetCasesForRequestPickup();
      // this.checkUnCheckAll();
    } else {
      this.modeToCheckDHLService = 'false';
      this.IsDHLservice = false;
    }
  }

  Recipient(data) {
    const _Address = new Address();
    _Address.StreetLines = data.street;
    _Address.City = data.city;
    _Address.PostalCode = data.postalCode;
    _Address.CountryCode = data.countryCode;
    this._recipient.Address = _Address;

    const _Contact = new Contact();
    _Contact.PersonName = data.contactperson;
    _Contact.CompanyName = data.companyname;
    _Contact.PhoneNumber = data.phoneNumber;;
    _Contact.EmailAddress = data.email;
    this._recipient.Contact = _Contact;
    /*  if (data.countryCode == 'IS' || data.countryCode == 'NO') {
       this._shipmentinfo.PaperlessTradeEnabled=true;
       this.IsPLT=true;
     }else{
       this._shipmentinfo.PaperlessTradeEnabled=false;
       this.IsPLT=false;
     } */
  }

  DHLAuth(data) {
    this._DHLAuth.DHLUsername = data.dhlUsername;
    this._DHLAuth.DHLPassword = data.dhlPassword;
    // this._shipmentinfo.Account = data.dhlImportAccountNumber;
    this._shipmentinfo.Currency = data.currency;
    if (data.dhlImportAccountNumber) {
      this.modeToCheckDHL = 'true';
      this.DhlCheck = true;
    } else {
      this.modeToCheckDHL = 'false';
      this.DhlCheck = false;
    }
  }
  Packages(data) {
    this._requestedpackages.Weight = data.weight;
    this._dimensions.Length = data.length;
    this._dimensions.Width = data.width;
    this._dimensions.Height = data.height;
    this._commodities.CustomsValue = data.productValue;
    this._requestedpackages.CustomerReferences = data.shipmentReferences;
    this._commodities.Description = data.productDescription;
  }
  ShipmentInfo() {
    this._shipmentinfo.DropOffType = "REQUEST_COURIER";
    this._shipmentinfo.UnitOfMeasurement = "SI";
  }

  getNowDate() {
    //return string "2020-10-20"
    var returnDate = "";
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //because January is 0! 
    var yyyy = today.getFullYear();
    returnDate = yyyy.toString() + '-';
    if (mm < 10) {
      returnDate += '0' + mm + '-';
    } else {
      returnDate += mm + '-';
    }
    if (dd < 10) {
      returnDate += '0' + dd;
    } else {
      returnDate += dd;
    }
    return returnDate;
  }
  CheckPLT() {
    if (this._shipmentinfo.PaperlessTradeEnabled) {
      // if (this._recipient.Address.CountryCode == 'NO' || this._recipient.Address.CountryCode == 'IS') {
      // this._ExportLineItem=null;
      /* const _billing = new Billing();
      _billing.BillingAccountNumber = "201262696";
      _billing.ShipperAccountNumber = "201262696";
      _billing.ShippingPaymentType = "S";
      this._billing = _billing;
      this._shipmentinfo.Billing = this._billing; */
      this._shipmentinfo.PaperlessTradeEnabled = "true";
      const _ShipmentReference = new ShipmentReference();
      _ShipmentReference.shipmentReference = "433418";
      _ShipmentReference.ShipmentReferenceType = "CU";
      this._ShipmentReference = _ShipmentReference;
      this._ShipmentReferences.ShipmentReference = this._ShipmentReference;
      this._shipmentinfo.ShipmentReferences = this._ShipmentReferences;
      //parseInt
      // const _ExportLineItem = new ExportLineItem();
      // this._ExportLineItem.CommodityCode = "3924.10.00";
      this._ExportLineItem.ItemNumber = "1";
      //this._ExportLineItem.Quantity = "28";
      this._ExportLineItem.QuantityUnitOfMeasurement = "PCS";
      this._ExportLineItem.ItemDescription = "test";
      //this._ExportLineItem.UnitPrice = "2.94";
      // this._ExportLineItem.NetWeight = "0.1";
      // this._ExportLineItem.GrossWeight = "0.11";
      this._ExportLineItem.ManufacturingCountryCode = "SE";
      this._ExportLineItems.ExportLineItem = [];
      this._ExportLineItems.ExportLineItem.push(this._ExportLineItem);
      this._ExportDeclaration.InvoiceDate = this.getNowDate(); //"2020-10-20";
      this._ExportDeclaration.InvoiceNumber = "433418";
      this._ExportDeclaration.ExportLineItems = this._ExportLineItems;
      this._internationaldetail.ExportDeclaration = this._ExportDeclaration;
      this._Service.ServiceType = "WY";
      this._SpecialServices.Service = this._Service;
      this._shipmentinfo.SpecialServices = this._SpecialServices;
      this._LabelOptions.RequestDHLCustomsInvoice = "Y";
      this._shipmentinfo.LabelOptions = this._LabelOptions;
      this._commodities.CustomsValue = Number(this._ExportLineItem.UnitPrice) * Number(this._ExportLineItem.Quantity);
      // }
    } else {
      this._shipmentinfo.PaperlessTradeEnabled = false;
    }
  }
  IsCommodityCode: boolean;
  IsQuantity: boolean;
  IsGrossWeight: boolean;
  IsUnitPrice: boolean;
  IsNetWeight: boolean;
  Createshipment() {
    this.spinner.show();
    this.ShipmentInfo();
    this._internationaldetail.Content = "NON_DOCUMENTS";
    this._requestedshipment.PaymentInfo = "DDP";
    this._internationaldetail.Commodities = this._commodities;
    //this._internationaldetail.Commodities.CustomsValue=6;
    this.CheckPLT();
    this.IsCommodityCode = false;
    this.IsQuantity = false;
    this.IsGrossWeight = false;
    this.IsUnitPrice = false;
    this.IsNetWeight = false;
    if (this._shipmentinfo.PaperlessTradeEnabled) {
      let check = 0;
      if (!this._ExportLineItem.CommodityCode) {
        this.IsCommodityCode = true;
        check = 1;
      }
      if (!this._ExportLineItem.Quantity) {
        this.IsQuantity = true;
        check = 1;
      }
      if (!this._ExportLineItem.GrossWeight) {
        this.IsGrossWeight = true;
        check = 1;
      }
      if (!this._ExportLineItem.UnitPrice) {
        this.IsUnitPrice = true;
        check = 1;
      }
      if (!this._ExportLineItem.NetWeight) {
        this.IsNetWeight = true;
        check = 1;
      }
      if (check == 1) {
        this.spinner.hide();
        return;
      }
    } else {
      this._shipmentinfo.PaperlessTradeEnabled = "false";
    }
    this._ship.Shipper = this._shipper;
    this._ship.Recipient = this._recipient;
    this._requestedpackages.Dimensions = this._dimensions;
    this._packages.RequestedPackages = [];
    this._packages.RequestedPackages.push(this._requestedpackages);
    this._requestedshipment.ShipmentInfo = this._shipmentinfo;
    this._requestedshipment.Ship = this._ship;
    this._requestedshipment.InternationalDetail = this._internationaldetail;
    this._requestedshipment.Packages = this._packages;
    this._shipmentrequest.RequestedShipment = this._requestedshipment;
    this._shipmentrequestJson.ShipmentRequest = this._shipmentrequest;
    this._CreateRequestShipment.DHLAuth = this._DHLAuth;
    this._CreateRequestShipment.ShipmentRequestJson = this._shipmentrequestJson;
    this._RequestPickup.OrderIds = this.Selectedorderids.toString();
    this._RequestPickup.DentistId = this.userId;
    this._RequestPickup.DentistCompanyId = this.companyId;
    this._RequestPickup.DealerId = this.dealerId
    this._CreateRequestShipment.RequestPickup = this._RequestPickup;
    this._CreateRequestShipment.IsRequestpickup = true;

    this.caseService.RequestDHLShipment(this._CreateRequestShipment).subscribe((data: any) => {

      if (data && data.shipmentResponse && data.shipmentResponse.shipmentIdentificationNumber) {
        this.toastr.success("Your Request Shipment has been created successfully");
        this.GetCasesForRequestPickup();
        this.GetRecentShipments();
      } else {
        this.toastr.error(data.shipmentResponse.notification[0].message);
        /*  this.toastr.error("Failed to Create Request Shipment Please Fill Dealer DHL details Properly in company"); */
      }
      this.spinner.hide();
      this.closeModal();
    })
  }
  GetCasesForRequestPickup() {
    let caseSearchData = {
      "CompanyId": this.companyId,
      "DentistCompanyId": 0,
      "CaseStatus": 0,
      "CasesPerPage": 50,
      "CurrentPage": 1,
      "SortBy": "CreatedDate",
      "OrderBy": "desc",
      "DeliveryNumber": "",
      "UserId": this.userId,
      "RoleType": this.roleName,
      "flag": 1
    }

    this.caseService.GetCasesForRequestPickup(caseSearchData).subscribe((data: any) => {

      this.allCases = data.orderList;
      for (var i = 0; i < this.allCases.length; i++) {
        this.allCases[i].totalCost = this.allCases[i].totalCost.toLocaleString(this.allCases[i].countryCode, { style: 'currency', currency: this.allCases[i].shortName });
      }
      this.checkUnCheckAll();
    })
  }



  checkUncheckShipment(item) {

    if (this.Selectedorderids.includes(item.orderId)) {
      this.Selectedorderids = this.Selectedorderids.filter(x => x !== item.orderId)
    }
    else {
      this.Selectedorderids.push(item.orderId)
    }

  }

  checkUnCheckAll() {
    this.checked = !this.checked;
    if (this.checked) {
      this.checkALL = true;
      this.Selectedorderids = [];
      this.allCases.forEach(element => {
        this.Selectedorderids.push(element.orderId)
      })

    } else {
      this.checkALL = false;
      this.Selectedorderids = [];
    }
  }

  clearAll() {
    /*   this._commodities.Description = "";
      this._commodities.CustomsValue = "";
      this._requestedpackages.CustomerReferences = ""; */
  }

  downloadWoPdf(item) {
    //Download Service here
    this.caseService.getWorkOrderFile(item).subscribe((res: any) => {

      let workOrderFile = res.workOrderFile;
      const linkSource = 'data:application/pdf;base64,' + workOrderFile;
      const downloadLink = document.createElement("a");
      const fileName = item;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    })
  }
  GetRecentShipments() {
    this.caseService.GetRecentShipments(this.companyId, 2).subscribe((data: any) => {

      this.RecentShipments = data.recentShipment;
    })
  }
  downloaddhlpdf(index) {
    const linkSource = 'data:application/pdf;base64,' + this.RecentShipments[index].graphicIabel;
    const downloadLink = document.createElement("a");
    const fileName = this.RecentShipments[index].requestAWB;;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
