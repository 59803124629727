import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { formatDate, Location } from "@angular/common";
import { CRMTask } from "../../models/crmTask";
import { ReminderDate, TaskStatus,TaskStatusLabelMapping } from "../../enum/statusEnum";
import { ManageTasksService } from "../manage-tasks.service";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-add-task",
  templateUrl: "./add-task.component.html",
  styleUrls: ["../../home/home.component.css"],
})
export class AddTaskComponent implements OnInit {
    modalRef: BsModalRef;
    CRMTask:any = new CRMTask();
    statustaskLabelMapping = TaskStatusLabelMapping;
    statustask = Object.values(TaskStatus).filter(value => typeof value === 'number');
    userDetails: any = [];  
    type: any;
    sub: any;
    id: number;

    file: any = [];
    fileName: any;
    fileType: any;
    base64Data: any;
    fileId: any;
    fileAttachment: any=[];
    history: string[] = []
    // remove task attacxhment
    attachmentTaskId:any;
    // for comments section
    CommentId: any = 0;
    TaskComment: any = "";
    TaskCommentList: any = [];
    commentPageSize: any = 12;
    IsReminder: boolean = false;
    ReminderDate: any;
    DateForReminder: any;
    ReminderDay = ReminderDate.reminderday;
  constructor(private manageTasksService: ManageTasksService,private toastr:ToastrService,private route:ActivatedRoute,
    private modalService: BsModalService,private router: Router, private location:Location,private spinner: NgxSpinnerService) {
    this.CRMTask.status = 1;
    this.userDetails = JSON.parse(localStorage.getItem("UserDetails"));
    this.sub = this.route.params.subscribe((params) => {
        this.type = params["type"];
        this.id = params["id"];
      });
      if (this.type === "edit" && this.id != undefined) {
        let object = {
          Id: this.id,
        };
       this.GetCRMTaskById(object);
       this.GetCRMTaskCommentByTaskId(this.id);
      }
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects)
        }
      })
      let dt = new Date();
      dt.setDate( dt.getDate() + this.ReminderDay );
      this.ReminderDate = formatDate(dt,'yyyy-MM-dd','en_US');
      let dt1 = new Date();
      dt1.setDate( dt1.getDate() - this.ReminderDay );
      this.DateForReminder = formatDate(dt1,'yyyy-MM-dd','en_us');
  }

  ngOnInit() {}

  back(){
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl("managetasks/tasks")
    }
 }

 onChangeStatus($event) {
    this.CRMTask.status = $event;
  }

  GetCRMTaskById(object){
    this.spinner.show()
    this.manageTasksService.GetCRMTaskById(object).subscribe((data: any) => {
        this.CRMTask.id = data.id;
        this.CRMTask.title = data.title;
        this.CRMTask.description = data.description;
        this.CRMTask.status = data.status;
        this.fileAttachment = data.crmTaskAttachment;
        this.spinner.hide()
      });
  }

  removeTaskFile(taskid, fileid, filename, template: TemplateRef<any>){
    this.attachmentTaskId = taskid;
    this.fileId = fileid;
    this.fileName = filename;
    this.modalRef = this.modalService.show(template);
  }
  decline() {
    this.modalRef.hide();
  }

  confirm(){
    let obj = {
      "taskid": this.attachmentTaskId,
      "fileid": this.fileId,
      "filename": this.fileName
    }
    this.spinner.show()
    this.manageTasksService.removeTaskFile(obj).subscribe(
      (data:any)=>{
        if(data == 1){
          this.fileAttachment.splice(this.fileAttachment.indexOf(this.fileId), 1);
          this.toastr.success("Task attachment has been deleted Successfully");
        } 
        else{
          this.toastr.error("Something is wrong please contact to administration");
        } 
        this.spinner.hide()
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide()
      });
      this.modalRef.hide();
  }

 onSubmit(){    
    this.CRMTask.createdBy = this.userDetails.teethRUsUserId;
    this.CRMTask.crmTaskAttachment = [];   
    for (let x = 0; x < this.file.length; x++) {
      let obj = {
        "fileName": this.file[x].name.split('.')[0],
        "fileType": this.file[x].fileType,
        "base64Data": this.file[x].base64Data,
      }
      this.CRMTask.crmTaskAttachment.push(obj);
    }
    this.spinner.show()
    this.manageTasksService.AddandUpdateTasks(this.CRMTask).subscribe(
      (data:any)=>{        
        if (data.message == "1") {          
          this.toastr.success(
            "New CRM Task has been added successfully"
          );          
        } else if (data.message == "2") {
          this.toastr.success(
            "CRM Task has been updated successfully"
          );
        }
        this.spinner.hide()
        this.back();        
      },
      (err) => {
        this.toastr.error(err);
        this.spinner.hide()
      }
    );
  }

  submitComment(){
   if(this.TaskComment == "" || this.TaskComment == null){
      this.toastr.warning("Cannot submit empty comment !");
   } else{
      let obj = {
        Id: this.CommentId,
        TaskComment: this.TaskComment,
        UserId: this.userDetails.teethRUsUserId,
        TaskId: this.id,
        IsReminder: this.IsReminder
      }
      this.spinner.show()
      this.manageTasksService.AddandUpdateTaskComment(obj).subscribe(
        (data:any)=>{
          if(data.message == "1"){
            if(this.CommentId == 0){            
              this.toastr.success("Task comment added successfully");
            } else{
              this.toastr.success("Task comment updated successfully");
            }
          } else if(data.message == "2"){
            this.toastr.warning("Something went wrong please contact administration");
          } else if(data.message == "3"){
            if(this.CommentId == 0){            
              this.toastr.warning("Task comment added successfully. But eamil is null or invalid.");
            } else{
              this.toastr.warning("Task comment updated successfully. But eamil is null or invalid.");
            }
          } else if(data.message == "4"){
            if(this.CommentId == 0){            
              this.toastr.warning("Task comment added successfully. But eamil not sent due to network error.");
            } else{
              this.toastr.warning("Task comment updated successfully. But eamil not sent due to network error.");
            }
          }
          this.CommentId = 0;
          this.TaskComment = "";
          this.IsReminder = false;
          this.GetCRMTaskCommentByTaskId(this.id);
          this.spinner.hide()
        },
        (err) => {
          this.toastr.error(err);
          this.spinner.hide()
        }
      );
   }
  }
  
  editComment(item){
    if (item.postedUser !== this.userDetails.teethRUsUserId) {
      this.toastr.warning("You dont have permission to edit this comment");
    } else{
      this.CommentId = item.id;
      this.TaskComment = item.taskComment;
    }
  }

  deleteComment(item, templateTaskComment: TemplateRef<any>){
    if (item.postedUser !== this.userDetails.teethRUsUserId) {
      this.toastr.warning("You dont have permission to delete this comment");
    } else{
      this.CommentId = item.id;
      this.modalRef = this.modalService.show(templateTaskComment);
    } 
  }

  cancelDeleteComment() {
    this.CommentId = 0;
    this.modalRef.hide();
  }

  confirmDeleteComment(){
    this.manageTasksService.DeleteTaskComment(this.CommentId).subscribe((data:any)=>{      
      if(data == 1){
        this.CommentId = 0;
        this.GetCRMTaskCommentByTaskId(this.id)
        this.toastr.success("Task comment has been deleted Successfully");
      } 
      else{
        this.toastr.error("Something is wrong please contact to administration");
      } 
    },
    (err) => {
      this.toastr.error(err);
    });
    this.modalRef.hide();
  }

  GetCRMTaskCommentByTaskId(id){
    this.spinner.show()
    this.TaskCommentList = [];
    this.manageTasksService.GetCRMTaskCommentByTaskId(id).subscribe((data:any)=>{
      let taskCommentList = data;
      taskCommentList.forEach(element => {
        if(element.isReminder == true && (this.ReminderDate >= formatDate(element.postedDate,'yyyy-MM-dd','en_US') && formatDate(element.postedDate,'yyyy-MM-dd','en_US') >= this.DateForReminder)){
          element.isActive = true;         
        } else{
         element.isActive = false;
        }   
        this.TaskCommentList.push(element);   
      }); 
       this.TaskCommentList.sort((a, b) => (
        b.isActive - a.isActive
       ));
      this.spinner.hide()
    });
  }

}