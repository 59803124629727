import { ShipmentInfo } from "./ShipmentInfo";
import { InternationalDetail } from "./InternationalDetail";
import { Ship } from "./Ship";
import { Packages } from "./Packages";


export   class RequestedShipment {
    PaymentInfo: any; 
    ShipTimestamp: any; 
    ShipmentInfo:ShipmentInfo;
    InternationalDetail:InternationalDetail;
    Ship:Ship;
    Packages:Packages;
}