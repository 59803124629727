import { Component, ComponentRef, OnInit, ViewChild, TemplateRef, Inject, Injector, LOCALE_ID, ComponentFactoryResolver,
   ApplicationRef, ChangeDetectorRef } from "@angular/core";
import { startOfDay, addHours, endOfDay } from "date-fns";
import { CalendarService } from "../calendar.service";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DatepickerDateCustomClasses, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CRMCalendar } from "../../../models/crmcalendar";
import { RepeatPattern, monthRepeatPattern, yearmonthRepeatPattern } from "../../../enum/repeatCalendar";
import { MessageService } from "../../message/message.service";
import { NgxSpinnerService } from "ngx-spinner";
import { formatDate } from '@angular/common';
import { PaginationService } from "src/app/services/pagination.service";
import { CRMMessagePerPage } from "src/app/enums";
import { PopoverWrapperComponent } from "./popoverwrapper.component";

// full calendar
import * as moment from 'moment';
import { CalendarOptions, DateSelectArg, EventInput } from '@fullcalendar/angular';



@Component({
  selector: "app-view-calendar",
  templateUrl: "./view-calendar.component.html",
  styleUrls: ["../../../home/home.component.css"],
})
export class ViewCalendarComponent implements OnInit {  
  datePickerConfig: Partial<BsDatepickerConfig>
  mytime1: any;
  mytime2: any;
  dateCustomClasses: DatepickerDateCustomClasses[];
  modalRef: BsModalRef;
  view: any = "dayGridMonth";
  MeetingEvent:any = new CRMCalendar();
  pattern = RepeatPattern;
  patternKeys=[];
  UserId:any;
  fromDate:any;
  toDate:any;
  crmMeetingData:any=[];
  dayval: any = 7;
  fromHours:any = 1;
  Users = [];  
  dropdownSettings = {};
  mode:any = 'addedit';
  IsAdd:boolean = false;
  IsEdit:boolean=false;
  viewEventId:any;
  viewEventStartDate:any;
  viewStartMeeting:any;
  viewEndMeeting:any;
  IsDifferentDate:boolean = false;
  viewDescription:any;
  viewLocation:any;
  viewSubject:any;
  SalesRepId:any;
  meetingId:any;
  selectedItems:any=[];
  multiDropdownList:any=[];
  selectedData:any=[];
  calendarOptions: CalendarOptions;
  TODAY_STR = new Date().toString().replace(/T.*$/, '');
  myevents:EventInput[] = [];
  @ViewChild('template') template: TemplateRef<any>;
  //calendarVisible = false;
  counter:any=0;
  //for repeat pattern
  _repeatpattern:boolean=false;
  dailyPattern:boolean=false;
  weeklyPattern:boolean=false;
  monthlyPattern:boolean=false;
  yearlyPattern:boolean=false;
  repPattern:any=1;
  onlyNumber:boolean=false;
  stopCondition:boolean=false;
  condition:any="never";
  untilDate:any=new Date();
  untilCount:any=1;
  onlycountNumber:boolean=false;
  weekpattern:boolean=false;
  monthpattern:boolean=false;
  MRP = monthRepeatPattern;
  MRPKeys = [];
  monthModel:any = "1";
  yearlyMonthPattern = false;
  YRP = yearmonthRepeatPattern;
  YRPKeys = [];
  yearmonthModel:any = "1";
  repeatString:any;
  dtstart:any;
  weekRepeatPattern = [
    { id: 'SU', label: 'Sun', isChecked: false },
    { id: 'MO', label: 'Mon', isChecked: false },
    { id: 'TU', label: 'Tue', isChecked: false },
    { id: 'WE', label: 'Wed', isChecked: false },
    { id: 'TH', label: 'Thu', isChecked: false },
    { id: 'FR', label: 'Fri', isChecked: false },
    { id: 'SA', label: 'Sat', isChecked: false }
  ]
  color:any;
  @ViewChild('popoverTmpl') popoverTmpl: TemplateRef<any>;//, {static: true}

  popoversMap = new Map<any, ComponentRef<PopoverWrapperComponent>>();

  popoverFactory = this.resolver.resolveComponentFactory(
    PopoverWrapperComponent
  );
  //share Calendar variable
  searchText="";
  unsharedSearchText="";
  ShareUsers:any=[];
  checked:boolean=false;
  SharedToList:any=[];
  SharedUsersList:any=[];
  FilterUnSharedUsersList:any=[];
  sharevalue:any;
  IsAllow:boolean=true;
  CalendarSharedUsersList:any=[];
  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = 6;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  pageField = [];
  exactPageList: any;
  currentPage = 1;
  pageNumber: boolean[] = [];
  totalNumberOfUsers: any;
  paginationData: number;
  pageSize: any = CRMMessagePerPage.first;
  first = CRMMessagePerPage.first;
  second = CRMMessagePerPage.second;
  third = CRMMessagePerPage.third;
  firstRow = true;
  secondRow = false;
  thirdRow = false;
  pagenumberCount = 0;
  constructor(private calendarService: CalendarService,private modalService: BsModalService,private messageService: MessageService,
    private _toastr: ToastrService, private spinner: NgxSpinnerService, private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    public paginationService: PaginationService,
    public cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string) {
      const now = new Date();
    this.dateCustomClasses = [
      { date: now, classes: ['text-warning'] },
    ]
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false });

    }

  ngOnInit() {
    this.patternKeys = Object.keys(this.pattern);
    this.MRPKeys = Object.keys(this.MRP);
    this.YRPKeys = Object.keys(this.YRP);
    this.UserId=localStorage.getItem("userid");
    this.SalesRepId = localStorage.getItem("SalesRepId");
    this.sharevalue = "my";
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      idField: "RecieverId",
      textField: "FullName",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      disabled: true,
    };    
    this.getUsers();
    this.GetSharedUsers();
    this.GetCalendarSharedUsers();
    this.GetCRMMeetings();        
  } 
  
  ngAfterContentChecked(){
    this.cd.detectChanges();
  }

  GetCRMMeetings() {
    this.spinner.show();
    let object = {
      'UserId': this.UserId,
      'FromDate': (new Date().getFullYear() - 1) + '-01-01',
      'ToDate': (new Date().getFullYear() + 1) + '-12-31'
    }
    this.calendarService.GetCRMMeetings(object).subscribe((res: any) => {
      if(res == null || res.length == 0){
        this.crmMeetingData = []
      }
      else{
        this.crmMeetingData = res.filter((ele, index, array) => index === array.findIndex((findEle) => findEle.id === ele.id));
      }
      this.myevents=[];
      for (let i = 0; i < this.crmMeetingData.length; i++) {
        const fromdate = new Date(this.crmMeetingData[i].meetingFrom);
        const todate = new Date(this.crmMeetingData[i].meetingTo);
       if (this.crmMeetingData[i].repeatPattern === null || this.crmMeetingData[i].repeatPattern.toUpperCase() === "NEVER") {       
        this.myevents.push({
          title: moment(fromdate).format('hh:mm a') + ' \n ' + this.crmMeetingData[i].subject,
          id:this.crmMeetingData[i].id,
          start: addHours(fromdate, 0),
          end: addHours(todate, 0),
          backgroundColor: this.crmMeetingData[i].colorCode,
        });
      }
       else{
        let RR: any = JSON.parse(this.crmMeetingData[i].repeatPattern); 
        this.myevents.push({
          title: moment(fromdate).format('hh:mm a') + ' \n ' + this.crmMeetingData[i].subject,
          start: addHours(fromdate, 0),
          end: addHours(todate, 0),
          rrule: RR,
          id:this.crmMeetingData[i].id,
          backgroundColor: this.crmMeetingData[i].colorCode,
        });
       } 
      }
      this.calendarOptions = {
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        allDaySlot:false,
        //initialView: 'dayGridMonth',
        events: this.myevents,
        weekends: true,
        //editable: true,
        selectable: true,
        //selectMirror: true,
        eventDisplay: 'block',
        //dayMaxEvents: false,
        displayEventTime: false,
        eventDidMount: this.renderTooltip.bind(this),
        eventWillUnmount: this.destroyTooltip.bind(this),
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        //eventsSet: this.handleEvents.bind(this)
        //eventColor: 'red'
      };
      this.spinner.hide();
    });
  }  
  handleDateSelect(selectInfo: DateSelectArg) {
    if(this.UserId == localStorage.getItem("userid")){
    this.counter = this.counter + 1;
    this.MeetingEvent = new CRMCalendar();
    this.MeetingEvent.crmMeetingDetail = [];
    this.MeetingEvent.repeatPattern = "Never";    
    this.color = '#0000FF';
    this.MeetingEvent.colorCode = this.color;
    this._repeatpattern = false;
    this.dailyPattern=false;
    this.weeklyPattern=false;
    this.monthlyPattern=false;
    this.yearlyPattern=false;
    this.weekpattern = false;
    this.monthpattern = false;
    this.yearlyMonthPattern = false;
    this.stopCondition=false;
    this.selectedItems=[];
    this.selectedData=[];
    this.IsAdd = true;
    this.IsEdit = false;
    this.mode = 'addedit';
    this.dtstart=formatDate(selectInfo.startStr, 'yyyyMMdd', this.locale);
    if(selectInfo.view.type=="dayGridMonth"){
    this.MeetingEvent.meetingFrom = selectInfo.startStr;    
    this.modalRef = this.modalService.show(this.template);
    }
    else{
      this.MeetingEvent.meetingFrom = selectInfo.startStr;
      this.MeetingEvent.meetingTo = selectInfo.endStr;
      this.modalRef = this.modalService.show(this.template);
    }
  } else{
   this._toastr.warning("You can not add event");
  }
  }

  dateChangeEvent(event){
    if(event == null || event == undefined) { return; }
    if(this.counter>0){
    this.counter = this.counter+1;
    this.MeetingEvent.meetingFrom = event;
    this.MeetingEvent.meetingTo = event;
    } else {
      this.counter = this.counter+1;
      this.MeetingEvent.meetingFrom = event;
    }
  }
  dateChangeEventTo(event){
    if(event == null || event == undefined) { return; }
    this.MeetingEvent.meetingTo = event;
  }

  timeChange($event) {
    let hours = $event.getHours();
    hours = hours.toFixed();

    let minutes = $event.getMinutes();
    minutes = minutes.toFixed();
    if (hours.length == 1) {
      hours = 0 + hours;
    }
    if (minutes.length == 1) {
      minutes = 0 + minutes;
    }
    this.mytime1 = hours + ':' + minutes;
  }

  timeChange1($event) {
    let hours = $event.getHours();
    hours = hours.toFixed();

    let minutes = $event.getMinutes();
    minutes = minutes.toFixed();
    if (hours.length == 1) {
      hours = 0 + hours;
    }
    if (minutes.length == 1) {
      minutes = 0 + minutes;
    }
    this.mytime2 = hours + ':' + minutes;
  }


  handleEventClick(event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      for(let i=0; i<this.crmMeetingData.length; i++){
        if(event.event._def.publicId == this.crmMeetingData[i].id){
          this.viewEventId = this.crmMeetingData[i].id;
          this.viewEventStartDate = event.el.fcSeg.eventRange.range.start;
          this.viewStartMeeting = this.crmMeetingData[i].meetingFrom;
          this.viewEndMeeting = this.crmMeetingData[i].meetingTo;          
          this.viewDescription = this.crmMeetingData[i].description;
          this.viewLocation = this.crmMeetingData[i].location;
          this.viewSubject = this.crmMeetingData[i].subject;
          this.dtstart=formatDate(this.crmMeetingData[i].meetingFrom, 'yyyyMMdd', this.locale);
          if(moment(this.viewStartMeeting.split('T')[0]).isSame(this.viewEndMeeting.split('T')[0])){
            this.IsDifferentDate = false;
          } else {
            this.IsDifferentDate = true;
          }
        }
      }
      popover.instance.popover.open({ event: event.event });
    }
} 

  renderTooltip(event) {
    const projectableNodes = Array.from(event.el.childNodes);
    const compRef = this.popoverFactory.create(
      this.injector,
      [projectableNodes],
      event.el,
    );
    compRef.instance.template = this.popoverTmpl;
    this.appRef.attachView(compRef.hostView);
    this.popoversMap.set(event.el, compRef);
  }

  destroyTooltip(event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      this.appRef.detachView(popover.hostView);
      popover.destroy();
      this.popoversMap.delete(event.el);
    }
  }

  getUsers() {
    this.calendarService.GetUsers().subscribe((data: any) => {
      this.Users = data.filter((item) => {
        if (item.UserId !== this.UserId) {
          return item;
        }
      });
      for(let k=0; k<this.Users.length; k++){
        let obj = {
          "RecieverId":this.Users[k].UserId,
          "FullName":this.Users[k].FullName,
          "RecieverEmailId":this.Users[k].RecieverEmailId
        }
        this.multiDropdownList.push(obj);
      }
    });
  }  

  SubmitEvent(){
  //   if(this.selectedItems.length == 0 || this.selectedItems == null){
  //     this._toastr.error("Select atleast one user");
  //   }
  //  else{  
    this.MeetingEvent.crmMeetingDetail = [];  
    if(this.MeetingEvent.repeatPattern.toUpperCase() === "DAILY"){
      if(this.condition.toUpperCase()==="UNTILDATE"){
        this.repeatString ='"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()+';UNTIL='
        +formatDate(this.untilDate,'yyyyMMdd','en-US')+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
      else if(this.condition.toUpperCase()==="UNTILCOUNT"){
        this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()+';COUNT='+this.untilCount
        +';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
      else{
        this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
    }
    else if(this.MeetingEvent.repeatPattern.toUpperCase() === "WEEKLY"){      
        let weekstring = "";//weekDay
        for(let i=0; i<this.weekRepeatPattern.length; i++){
          if(this.weekRepeatPattern[i].isChecked === true){
            if(weekstring === "" || weekstring === null){
              weekstring = this.weekRepeatPattern[i].id;
            }
            else {
            weekstring = weekstring + ',' + this.weekRepeatPattern[i].id;
            }
          }
        }
        if(weekstring === "" || weekstring === null){
          this._toastr.warning("Checked atleast one week day");
          return false;
        }
        if(this.condition.toUpperCase()==="UNTILDATE"){
          this.repeatString ='"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()+';UNTIL='
          +formatDate(this.untilDate,'yyyyMMdd','en-US')+';BYDAY='+weekstring+';INTERVAL='+this.repPattern+'"';
          this.MeetingEvent.repeatPattern = this.repeatString;
        }
        else if(this.condition.toUpperCase()==="UNTILCOUNT"){
          this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()
          +';COUNT='+this.untilCount+';BYDAY='+weekstring+';INTERVAL='+this.repPattern+'"';
          this.MeetingEvent.repeatPattern = this.repeatString;
        }
        else{
          this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()+';BYDAY='
          +weekstring+';INTERVAL='+this.repPattern+'"';
          this.MeetingEvent.repeatPattern = this.repeatString;
        }
    }
    else if(this.MeetingEvent.repeatPattern.toUpperCase() === "MONTHLY"){
      if(this.condition.toUpperCase()==="UNTILDATE"){
        this.repeatString ='"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()+';UNTIL='
        +formatDate(this.untilDate,'yyyyMMdd','en-US')+';BYMONTHDAY='+this.monthModel+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
      else if(this.condition.toUpperCase()==="UNTILCOUNT"){
        this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()
        +';COUNT='+this.untilCount+';BYMONTHDAY='+this.monthModel+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
      else{
        this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()
        +';BYMONTHDAY='+this.monthModel+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
    }
    else if(this.MeetingEvent.repeatPattern.toUpperCase() === "YEARLY"){
      if(this.condition.toUpperCase()==="UNTILDATE"){
        this.repeatString ='"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()
        +';UNTIL='+formatDate(this.untilDate,'yyyyMMdd','en-US')+';BYMONTHDAY='+this.monthModel+';BYMONTH='+this.yearmonthModel
        +';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
      else if(this.condition.toUpperCase()==="UNTILCOUNT"){
        this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()
        +';COUNT='+this.untilCount+';BYMONTHDAY='+this.monthModel+';BYMONTH='+this.yearmonthModel+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
      else{
        this.repeatString = '"DTSTART:'+this.dtstart+'\\nRRULE:FREQ='+this.MeetingEvent.repeatPattern.toUpperCase()
        +';BYMONTHDAY='+this.monthModel+';BYMONTH='+this.yearmonthModel+';INTERVAL='+this.repPattern+'"';
        this.MeetingEvent.repeatPattern = this.repeatString;
      }
    }    
    for(let i=0; i<this.selectedItems.length; i++){
      for(let x=0; x<this.multiDropdownList.length; x++){
        if(this.selectedItems[i].RecieverId === this.multiDropdownList[x].RecieverId){
          this.MeetingEvent.crmMeetingDetail.push(this.multiDropdownList[x]);
        }
      }
    }
    this.MeetingEvent.senderId = this.UserId;
    this.MeetingEvent.colorCode = this.color;
    
    if(moment(this.MeetingEvent.meetingFrom).isAfter(this.MeetingEvent.meetingTo)) {
      //this.MeetingEvent.meetingFrom.toString() > this.MeetingEvent.meetingTo.toString()){
      this._toastr.warning("Meeting dates is not valid");
      return;
    }
    // || (this.mytime1.toString() > this.mytime2.toString())
    if(this.mytime1 == undefined || this.mytime2 == undefined || this.mytime1 == "" || this.mytime2 == "" ||
    this.mytime1 == null || this.mytime2 == null){
      this._toastr.warning("Meeting times is not valid");
      return;
    }
    if(moment(this.MeetingEvent.meetingFrom).isSame(this.MeetingEvent.meetingTo)){
      if(this.mytime1.toString() > this.mytime2.toString()){
        this._toastr.warning("Meeting times is not valid for same date");
        return;
      }
    } 
    // meeting from 
    let fyear = (this.MeetingEvent.meetingFrom.getFullYear()).toString();
    let fmonth = (this.MeetingEvent.meetingFrom.getMonth() + 1).toString();
    let fday = (this.MeetingEvent.meetingFrom.getDate()).toString();
    if (fmonth.toString().length == 1) {
      fmonth = 0 + fmonth.toString();
    }
    if (fday.toString().length == 1) {
      fday = 0 + fday.toString();
    }
    this.MeetingEvent.meetingFrom = fyear + '-' + fmonth + '-' + fday + ' ' + this.mytime1;
    // meeting to
    let year = (this.MeetingEvent.meetingTo.getFullYear()).toString();
    let month = (this.MeetingEvent.meetingTo.getMonth() + 1).toString();
    let day = (this.MeetingEvent.meetingTo.getDate()).toString();
    if (month.toString().length == 1) {
      month = 0 + month.toString();
    }
    if (day.toString().length == 1) {
      day = 0 + day.toString();
    }
    this.MeetingEvent.meetingTo = year + '-' + month + '-' + day + ' ' + this.mytime2;
    this.spinner.show();
    this.calendarService.AddUpdateEvent(this.MeetingEvent).subscribe((data: any) => {
      if (data != null) {
        this.cancel();
        this.spinner.hide();
        if(this.MeetingEvent.id == 0){
          this._toastr.success("New event has been added successfully"); 
        }
        else{
          this._toastr.success("Event has been updated successfully"); 
        }
        this.IsAdd = false;
        this.IsEdit = false;
        this.GetCRMMeetings();            
      } else {
        this._toastr.info("Something is wrong please contact administrator");
        this.spinner.hide();
      }      
    },
    (err) => {
      this._toastr.error(err);
      this.spinner.hide();
    });
   //}
  }

  editMeeting(id,template:TemplateRef<any>){
    this.counter = 0;
    this.IsAdd = false;
    this.IsEdit = true; 
    this.mode="addedit";
    this.MeetingEvent= new CRMCalendar();
    this.MeetingEvent.crmMeetingDetail
    this.selectedData=[];
    this.selectedItems=[];
    for(let j=0; j<this.weekRepeatPattern.length; j++){
      this.weekRepeatPattern[j].isChecked = false;
    }
    this.GetCRMMeetingById(id);
    this.modalRef = this.modalService.show(template);
  }
  GetCRMMeetingById(id){
    this.calendarService.GetCRMMeetingById(id).subscribe((res: any) => {
      this.MeetingEvent = res;
      this.color = res.colorCode;
      this.mytime1 = res.meetingFrom.split('T')[1].split(':')[0] + ':' + res.meetingFrom.split('T')[1].split(':')[1];      
      //moment(res.meetingFrom).format('hh:mm a');let splits2 = splits[1].split(';');
      this.mytime2 = res.meetingTo.split('T')[1].split(':')[0] + ':' + res.meetingTo.split('T')[1].split(':')[1]; 
      //moment(res.meetingTo).format('hh:mm a');  //this.viewEventStartDate
      this.MeetingEvent.meetingFrom = moment(res.meetingFrom).format('YYYY-MM-DD');      
      this.MeetingEvent.meetingTo = moment(res.meetingTo).format('YYYY-MM-DD');      
      if(this.MeetingEvent.repeatPattern.toUpperCase() === "NEVER" || this.MeetingEvent.repeatPattern === null){
        this.MeetingEvent.repeatPattern="Never";
        this._repeatpattern = false;
        this.dailyPattern=false;
        this.weeklyPattern=false;
        this.monthlyPattern=false;
        this.yearlyPattern=false;
        this.weekpattern = false;
        this.monthpattern = false;
        this.yearlyMonthPattern = false;
        this.stopCondition=false;
      }
      else{
        let splits = this.MeetingEvent.repeatPattern.split('RRULE:');
        let splits2 = splits[1].split(';');
        let splits3 = splits2[0].split('FREQ=');
        if(splits3[1] === "DAILY"){
          this.MeetingEvent.repeatPattern="Daily";          
          if(splits2[1].includes("UNTIL=")){
            this.condition = "untildate";
            this.untilDate = splits2[1].split("UNTIL=")[1];
            let splitInterval = splits2[2].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else if(splits2[1].includes("COUNT=")){
            this.condition = "untilcount";
            this.untilCount = splits2[1].split("COUNT=")[1];
            let splitInterval = splits2[2].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else{
            this.condition = "never";
            let splitInterval = splits2[1].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          this._repeatpattern = true;
          this.dailyPattern=true;
          this.weeklyPattern=false;
          this.monthlyPattern=false;
          this.yearlyPattern=false;
          this.weekpattern = false;
          this.monthpattern = false;
          this.yearlyMonthPattern = false;
          this.stopCondition=true;
        }
        else if(splits3[1] === "WEEKLY"){
          this.MeetingEvent.repeatPattern="Weekly";
          if(splits2[1].includes("UNTIL=")){
            this.condition = "untildate";
            this.untilDate = splits2[1].split("UNTIL=")[1];
            let chekedList:any = splits2[2].split("BYDAY=")[1].split(",");
            for(let i=0; i<chekedList.length; i++){
              for(let j=0; j<this.weekRepeatPattern.length; j++){
                if(chekedList[i] === this.weekRepeatPattern[j].id){
                  this.weekRepeatPattern[j].isChecked = true;
                }
              }
            }
            let splitInterval = splits2[3].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else if(splits2[1].includes("COUNT=")){
            this.condition = "untilcount";
            this.untilCount = splits2[1].split("COUNT=")[1];
            let chekedList:any = splits2[2].split("BYDAY=")[1].split(",");
            for(let i=0; i<chekedList.length; i++){
              for(let j=0; j<this.weekRepeatPattern.length; j++){
                if(chekedList[i] === this.weekRepeatPattern[j].id){
                  this.weekRepeatPattern[j].isChecked = true;
                }
              }
            }
            let splitInterval = splits2[3].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else{
            this.condition = "never";
            let chekedList:any = splits2[1].split("BYDAY=")[1].split(",");
            for(let i=0; i<chekedList.length; i++){
              for(let j=0; j<this.weekRepeatPattern.length; j++){
                if(chekedList[i] === this.weekRepeatPattern[j].id){
                  this.weekRepeatPattern[j].isChecked = true;
                }
              }
            }
            let splitInterval = splits2[2].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          this._repeatpattern = true;
          this.dailyPattern=false;
          this.weeklyPattern=true;
          this.monthlyPattern=false;
          this.yearlyPattern=false;
          this.weekpattern = true;
          this.monthpattern = false;
          this.yearlyMonthPattern = false;
          this.stopCondition=true;
        }
        else if(splits3[1] === "MONTHLY"){
          this.MeetingEvent.repeatPattern="Monthly";
          if(splits2[1].includes("UNTIL=")){
            this.condition = "untildate";
            this.untilDate = splits2[1].split("UNTIL=")[1];
            this.monthModel = splits2[2].split("BYMONTHDAY=")[1];
            let splitInterval = splits2[3].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else if(splits2[1].includes("COUNT=")){
            this.condition = "untilcount";
            this.untilCount = splits2[1].split("COUNT=")[1];
            this.monthModel = splits2[2].split("BYMONTHDAY=")[1];
            let splitInterval = splits2[3].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else{
            this.condition = "never";
            this.monthModel = splits2[1].split("BYMONTHDAY=")[1];
            let splitInterval = splits2[2].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          this._repeatpattern = true;
          this.dailyPattern=false;
          this.weeklyPattern=false;
          this.monthlyPattern=true;
          this.yearlyPattern=false;
          this.weekpattern = false;
          this.monthpattern = true;
          this.yearlyMonthPattern = false;
          this.stopCondition=true;
        }
        else{
          this.MeetingEvent.repeatPattern="Yearly";
          if(splits2[1].includes("UNTIL=")){
            this.condition = "untildate";
            this.untilDate = splits2[1].split("UNTIL=")[1];
            this.monthModel = splits2[2].split("BYMONTHDAY=")[1];
            this.yearmonthModel = splits2[3].split("BYMONTH=")[1];
            let splitInterval = splits2[4].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else if(splits2[1].includes("COUNT=")){
            this.condition = "untilcount";
            this.untilCount = splits2[1].split("COUNT=")[1];
            this.monthModel = splits2[2].split("BYMONTHDAY=")[1];
            this.yearmonthModel = splits2[3].split("BYMONTH=")[1];
            let splitInterval = splits2[4].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          else{
            this.condition = "never";
            this.monthModel = splits2[1].split("BYMONTHDAY=")[1];
            this.yearmonthModel = splits2[2].split("BYMONTH=")[1];
            let splitInterval = splits2[3].split("INTERVAL=");
            this.repPattern = splitInterval[1].split('"')[0];
          }
          this._repeatpattern = true;
          this.dailyPattern=false;
          this.weeklyPattern=false;
          this.monthlyPattern=false;
          this.yearlyPattern=true;
          this.weekpattern = false;
          this.monthpattern = true;
          this.yearlyMonthPattern = true;
          this.stopCondition=true;
        }
      }
      for(let i=0; i<this.MeetingEvent.crmMeetingDetail.length; i++){
        for(let j=0; j<this.multiDropdownList.length; j++){
          if(this.MeetingEvent.crmMeetingDetail[i].recieverId == this.multiDropdownList[j].RecieverId){
            //this.MeetingEvent.crmMeetingDetail[i].RecieverEmailId = this.multiDropdownList[j].RecieverEmailId;
             let obj = {
               "RecieverId":this.multiDropdownList[j].RecieverId,
               "FullName":this.multiDropdownList[j].FullName,
               "RecieverEmailId":this.multiDropdownList[j].RecieverEmailId
             }
             this.selectedData.push(obj);
          }
        }
      }
      this.selectedItems = this.selectedData;
      this.MeetingEvent.crmMeetingDetail = [];
    });
  }
  deleteMeeting(id,template: TemplateRef<any>){
    this.mode="deleteEvent";
    this.meetingId = id;
    this.modalRef = this.modalService.show(template);
  }
  confirmDelete(){
    this.calendarService.trashEvent(this.meetingId).subscribe(
      (response: any) => {
        if (response == this.meetingId) {
          this.GetCRMMeetings();
          this._toastr.success("Event has been deleted Successfully");
        } else {
          this._toastr.error("Something is wrong please contact to administration");
        }
        this.cancel();
      },(err) => {
        this._toastr.error(err);
      }
    );    
  }
  repeatPatternChange(item){
    if(item.toUpperCase() === "NEVER"){
      this.condition = 'never'
      this._repeatpattern = false;
      this.dailyPattern=false;
      this.weeklyPattern=false;
      this.monthlyPattern=false;
      this.yearlyPattern=false;
      this.weekpattern = false;
      this.monthpattern = false;
      this.yearlyMonthPattern = false;
      this.stopCondition=false;
      this.MeetingEvent.repeatPattern = item;
    }
    else if(item.toUpperCase() === "DAILY"){
      this._repeatpattern = true;
      this.dailyPattern=true;
      this.weeklyPattern=false;
      this.monthlyPattern=false;
      this.yearlyPattern=false;
      this.weekpattern = false;
      this.monthpattern = false;
      this.yearlyMonthPattern = false;
      this.stopCondition=true;
      this.MeetingEvent.repeatPattern = item;
    }
    else if(item.toUpperCase() === "WEEKLY"){
      this._repeatpattern = true;
      this.dailyPattern=false;
      this.weeklyPattern=true;
      this.monthlyPattern=false;
      this.yearlyPattern=false;
      this.weekpattern = true;
      this.monthpattern = false;
      this.yearlyMonthPattern = false;
      this.stopCondition=true;
      for(let j=0; j<this.weekRepeatPattern.length; j++){
        this.weekRepeatPattern[j].isChecked = false;
      }
      this.MeetingEvent.repeatPattern = item;
    }
    else if(item.toUpperCase() === "MONTHLY"){
      this._repeatpattern = true;
      this.dailyPattern=false;
      this.weeklyPattern=false;
      this.monthlyPattern=true;
      this.yearlyPattern=false;
      this.weekpattern = false;
      this.monthpattern = true;
      this.yearlyMonthPattern = false;
      this.stopCondition=true;
      this.MeetingEvent.repeatPattern = item;
    }
    else{
      this._repeatpattern = true;
      this.dailyPattern=false;
      this.weeklyPattern=false;
      this.monthlyPattern=false;
      this.yearlyPattern=true;
      this.weekpattern = false;
      this.monthpattern = true;
      this.yearlyMonthPattern = true;
      this.stopCondition=true;
      this.MeetingEvent.repeatPattern = item;
    }
  }
  keyPressNumbers() {
    if (this.repPattern > 0) {
      this.onlyNumber = false;
    }
    else{
      this.onlyNumber = true;
    }
  }
  keyPressCountNumbers() {
    if (this.untilCount > 0) {
      this.onlycountNumber = false;
    }
    else{
      this.onlycountNumber = true;
    }
  }
  cancel() {
    this.condition = 'never';
    this.mytime1 = '';
    this.mytime2 = '';
    this._repeatpattern = false;
    this.dailyPattern=false;
    this.weeklyPattern=false;
    this.monthlyPattern=false;
    this.yearlyPattern=false;
    this.onlyNumber = false;
    this.weekpattern = false;
    this.monthpattern = false;
    this.yearlyMonthPattern = false;
    this.stopCondition = false;
    for(let j=0; j<this.weekRepeatPattern.length; j++){
      this.weekRepeatPattern[j].isChecked = false;
    }
    this.modalRef.hide();
  }
  GetCalendarSharedUsers(){
    this.UserId=localStorage.getItem("userid")
    this.calendarService.GetCalendarSharedUsers(this.UserId).subscribe((data: any) => {
      this.CalendarSharedUsersList = data;
    });
  }
  GetSharedUsers(){
    this.UserId=localStorage.getItem("userid")
    this.calendarService.GetSharedUsers(this.UserId).subscribe((data: any) => {
      this.SharedUsersList = data;
      this.FilterUnSharedUsersList = this.SharedUsersList;
    });
  }
  showBySharedUser(value){
    this.sharevalue = value;
    if(this.sharevalue==undefined ||this.sharevalue == "my"){
      this.IsAllow = true;
      this.UserId=localStorage.getItem("userid");
      this.GetCRMMeetings();
    } else{
      this.IsAllow = false;
      this.UserId = value.userId;
      this.GetCRMMeetings();
    }
  }
  GetUsersForSharedCalendar(){
    this.spinner.show();
    this.ShareUsers = [];
    this.checked = false;
    let userObj = {
      PageNo: this.currentPage,
      PageSize: this.pageSize,
      SearchText: this.searchText,
    };
    this.calendarService.GetUsersForSharedCalendar(userObj).subscribe((data: any) => {
      this.ShareUsers = data.usersforshareList.filter((item) => {
        if (item.UserId !== this.UserId) {
          return item;
        }              
      });  
      //this.calendarService.DeleteSharedCalendar(this.UserId).subscribe((data)=>{});
      this.totalNumberOfUsers = data.noOfRec;
      this.totalNoOfPages(); 
      this.spinner.hide();  
    });
  }
  shareCalendar(){
    this.SharedToList = [];
    this.checked = false;
    this.searchText="";
    this.currentPage=1;
    this.pagenumberCount = 0;
    this.pageNumber[0] = true;
    this.paginationService.temppage = 0;
    this.paginationService.pageNumberShow = this.pageNumberShow;
    this.paginationService.currentPage = 1;
    this.paginationService.showNoOfCurrentPage = 1;
    this.paginationService.showPageOnlyOntabsChange = true;
    this.GetUsersForSharedCalendar();    
    this.mode = 'opensharecalendarpopup';
    this.modalRef = this.modalService.show(this.template);    
  }
  unShareCalendar(){
    this.SharedUsersList = this.FilterUnSharedUsersList;
    this.unsharedSearchText="";
    this.checked = false; 
    for(let l=0; l<this.SharedUsersList.length; l++){
      this.SharedUsersList[l].isChecked = false;
    }  
    this.mode = 'openunsharecalendarpopup';
    this.modalRef = this.modalService.show(this.template);    
  }
  
  checkUnCheckAll() {
    this.checked = !this.checked;
    if (this.checked) {
      this.SharedToList = [];
      for(let k=0; k<this.ShareUsers.length; k++){
        this.ShareUsers[k].isChecked = true;
        let object = {
          "SharedTo":this.ShareUsers[k].userId
        }
        this.SharedToList.push(object);
      }
    } else {
      for(let l=0; l<this.ShareUsers.length; l++){
        this.ShareUsers[l].isChecked = false;
      }
      this.SharedToList = [];
    }
  }
  checkUnCheckItem(isChecked,user){
    if(isChecked){
      let object = {
        "SharedTo":user.userId
      }
      this.SharedToList.push(object)
      if(this.SharedToList.length == this.pageSize){
        this.checked = true;
      }
    } else {
      this.checked = false;
      this.SharedToList.forEach((element,index)=>{
        if(element.SharedTo==user.userId) this.SharedToList.splice(index,1);
     });
    }
  }
  checkUnCheckUnshareAll() {
    this.checked = !this.checked;
    if (this.checked) {
      for(let k=0; k<this.SharedUsersList.length; k++){
        this.SharedUsersList[k].isChecked = true;
      }
    } else {
      for(let l=0; l<this.SharedUsersList.length; l++){
        this.SharedUsersList[l].isChecked = false;
      }
    }
  }
  OnSubmitShareCalendar(){
    if(this.SharedToList.length === 0){
      this._toastr.warning("Please select atleast one user");
      return;
    }
    this.spinner.show()
    let objects = {
      "SharedFrom":localStorage.getItem("userid"),
      "SharedToList":this.SharedToList
    }
    this.calendarService.AddSharedCalendar(objects).subscribe((data: any) => {
    if(data == 1){
      this._toastr.success("Calendar has been shared successfully");
      this.GetSharedUsers();
      this.cancel();
      this.spinner.hide();
    } else {
      this._toastr.info("Something is wrong please contact administrator");
      this.spinner.hide();
    }      
   },  (err) => {
    this._toastr.error(err);
    this.spinner.hide();
   });
  }
  OnSubmitUnshareCalendar(){
    this.SharedToList = [];
    for(let x=0; x<this.SharedUsersList.length; x++){
      if(this.SharedUsersList[x].isChecked == true){
        let object = {
          "SharedTo":this.SharedUsersList[x].userId
        }
        this.SharedToList.push(object)
      }
    }
    if(this.SharedToList.length === 0){
      this._toastr.warning("Please select atleast one user");
      return;
    }
    this.spinner.show()
    let objects = {
      "SharedFrom":localStorage.getItem("userid"),
      "SharedToList":this.SharedToList
    }
    this.calendarService.UnSharedCalendar(objects).subscribe((data: any) => {
    if(data == 1){
      this._toastr.success("Calendar has been unshared successfully");
      this.GetSharedUsers();
      this.UserId=localStorage.getItem("userid");
      this.GetCRMMeetings();
      this.cancel();
      this.spinner.hide();
    } else {
      this._toastr.info("Something is wrong please contact administrator");
      this.spinner.hide();
    }      
   },  (err) => {
    this._toastr.error(err);
    this.spinner.hide();
   });
  }
  searchUser() {
    this.GetUsersForSharedCalendar(); 
  }
  unsharedTextChange() {
    this.SharedUsersList = [];
    if (this.unsharedSearchText == undefined || this.unsharedSearchText == null) {
      this.SharedUsersList = this.FilterUnSharedUsersList;
    } else {
      this.SharedUsersList = this.FilterUnSharedUsersList.filter((res: any) => {
        return res.fullName.toLowerCase().includes(this.unsharedSearchText.toLowerCase());
      });
    }
  }
  //Pagination Start
  prevUsersForSharedCalendar() {
    if (this.paginationService.showNoOfCurrentPage != 1) {
      this.paginationService.pageNumberShow = this.pageNumberShow;
      this.paginationService.prevCase();
      this.pageNumber = [];
      this.pageNumber[0] = true;
      this.currentPage = this.paginationService.pageField[0];
      this.GetUsersForSharedCalendar(); 
    }
  }

  nextUsersForSharedCalendar() {
    if (this.paginationService.disabledNextBtn == false) {
      this.pageNumber = [];
      this.paginationService.pageNumberShow = this.pageNumberShow;
      this.paginationService.nextCases();
      this.pageNumber[0] = true;
      this.currentPage = this.paginationService.pageField[0];
      this.GetUsersForSharedCalendar();  
    }
  }

  //Method For Pagination
  totalNoOfPages() {
    this.pagenumberCount = this.pagenumberCount + 1;
    this.paginationData = Number(this.totalNumberOfUsers / this.pageSize);
    let tempPageData = this.paginationData.toFixed();
    if (Number(tempPageData) < this.paginationData) {
      this.exactPageList = Number(tempPageData) + 1;
      this.paginationService.exactPageList = this.exactPageList;
    } else {
      this.exactPageList = Number(tempPageData);
      this.paginationService.exactPageList = this.exactPageList;
    }
    this.paginationService.pageOnLoad();
    if(this.pagenumberCount == 1){
      for(let x=0; x<this.pageNumber.length; x++){
        if(x==0){
          this.pageNumber[x]=true;
        }
        else{
          this.pageNumber[x]=false;
        }
      }
    }
    this.pageField = this.paginationService.pageField;
  }
  showNextUsersForSharedCalendar(page, i) {
    this.ShareUsers = [];
    this.pageNumber = [];
    this.pageNumber[i] = true;
    this.currentPage = page;
    this.GetUsersForSharedCalendar();
  }
  //Setting the records per page -For ex 10,20,50 Active color of page
  setPageSize(size) {
    this.pageNumber = [];
    this.paginationService.temppage = 0;
    if (size == CRMMessagePerPage.first) {
      this.pageSize = size;
      this.firstRow = true;
      this.secondRow = false;
      this.thirdRow = false;
      this.currentPage = 1;
    } else if (size == CRMMessagePerPage.second) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = true;
      this.thirdRow = false;
      this.currentPage = 1;
    } else if (size == CRMMessagePerPage.third) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = false;
      this.thirdRow = true;
      this.currentPage = 1;
    }
    this.GetUsersForSharedCalendar();  
    this.pageNumber[0] = true;
  }

}
