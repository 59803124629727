import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CRMMessagePerPage } from "src/app/enums";
import { PaginationService } from "src/app/services/pagination.service";
import { MessageService } from "./message.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["message.component.css"],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '100px',
        opacity: 1,
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
      })),
      transition('open => closed', animate('0.2s')),
      transition('closed => open', animate('0.2s'))
    ])
  ]
})
export class MessageComponent implements OnInit {
  // dateCustomClasses: DatepickerDateCustomClasses[]; 
  datePickerConfig: Partial<BsDatepickerConfig>;
  enablePostMessage: boolean;
  postMessage = [];
  salesRepUsers = [];
  file: any = [];
  photoUrl: any;
  FileName: any;
  FileType: any;
  Base64Data: any;
  uploadedFiles = [];

  selectedItems = [];
  dropdownSettings = {};
  selectedSalesRepId = [];
  selectedSalesRepName: any;
  saleRepId: any = localStorage.getItem("userid");
  IsPriority: boolean;
  senderMessage: string;

  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = 10;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  pageField = [];
  exactPageList: any;
  currentPage = 1;
  pageNumber: boolean[] = [];
  totalMessages: any;
  paginationData: number;
  order: string = "PostedDate";
  sortBy: any = "PostedDate_DESC";//"PostedDate_ASC";
  searchText = "";

  //Variables for Companies per page
  pageSize: any = CRMMessagePerPage.first;
  first = CRMMessagePerPage.first;
  second = CRMMessagePerPage.second;
  third = CRMMessagePerPage.third;
  firstRow = true;
  secondRow = false;
  thirdRow = false;
  enableIsRead: boolean;
  senderName: any;
  ToDate: Date = new Date();
  FromDate = null;
  replyMsgId: any = 0;
  replyMessage: string;
  reply: boolean = false;
  selectedDate: string;
  IsInbox:boolean=true;
  IsShow:boolean=true;
  replyMsgDetail:any=[];
  selectedData:any=[];
  //variables for get sent message
  sentMessage:any=[];
  totalsentMessages:any;

  constructor(
    private messageService: MessageService,
    private _toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public paginationService: PaginationService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      {
        containerClass: "theme-dark-blue",
        rangeInputFormat: "YYYY-MM-DD",
        dateInputFormat: "YYYY-MM-DD",
        showWeekNumbers: false,
      }
    );
    const now = new Date();
    this.pageNumber[0] = true;
  }

  ngOnInit() {
    // Get message details
    this.getmessageDetails();
    this.getSalesRepUser();
    this.paginationService.temppage = 0;
    this.paginationService.currentPage = 1;
    this.paginationService.showNoOfCurrentPage = 1;
    this.paginationService.showPageOnlyOntabsChange = true;
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      idField: "userId",
      textField: "fullName",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      disabled: true,
    };
  }
  handleFileSelect(evt) {
    var File = evt.target.value;
    let subStringData = File.substr(12);
    var FileName = subStringData.split(".")[0];
    var FileType = subStringData.split(".")[1];

    this.FileName = FileName;
    this.FileType = FileType;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.Base64Data = btoa(binaryString);
  }

  getmessageDetails() {
    this.postMessage = [];
    this.spinner.show();
    let messageObj = {
      PageNo: this.currentPage,
      PageSize: this.pageSize,
      SenderId: localStorage.getItem("userid"),
      SearchText: this.searchText,
      SortOrder: this.sortBy,
      FromDate: this.FromDate,
      ToDate: this.ToDate,
    };
    this.messageService.GetMessageDetails(messageObj).subscribe(
      (data: any) => {
        this.postMessage = data.messageList.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
           findTest.messageId === test.messageId
          )
        );
        this.totalMessages = data.noOfRec;
        this.spinner.hide();
        this.totalNoOfPages();
      },
      (err) => {
        this._toastr.error(err);
        this.spinner.hide();
      }
    );
  }

  getSalesRepUser() {
    this.messageService.GetSalesRepUsers().subscribe((data: any) => {
      this.salesRepUsers = data.filter((item) => {
        if (item.UserId !== this.saleRepId) {
          return item;
        }
      });
    });
  }

  composeMessage() {
    this.enablePostMessage = true;
    this.enableIsRead = false;
    this.senderMessage = "";
    this.replyMsgId = 0;
    this.IsShow = true;
    this.selectedData = [];
    this.selectedItems = [];
  }

  onItemSelect($event) {
    this.selectedSalesRepId.push($event.companyId);
  }
  onItemUnSelect($event) {
    if (this.selectedSalesRepId.includes($event.companyId)) {
      this.selectedSalesRepId = this.selectedSalesRepId.filter(
        (x) => x !== $event.userId
      );
    }
  }
  priorityCheck() {
    this.IsPriority = !this.IsPriority;
  }

  createMessage() {
    const obj = {
      Message: this.senderMessage,
      IsPriority: this.IsPriority,
      SalesRepUsers: this.selectedItems,
      SenderId: this.saleRepId,
      ReplyMsgId: this.replyMsgId,
      UploadedFiles: this.uploadedFiles,
    };
    if (this.selectedItems.length === 0 && !this.reply) {
      this._toastr.warning("Please select sales rep user");
      return;
    } else if (this.senderMessage === "" || this.senderMessage === undefined || this.senderMessage === null) {
      this._toastr.warning("Text message can not be empty");
      return;
    }

    //this.reply ? "" : 
    this.uploadFiles();
    this.messageService.createMessage(obj).subscribe(
      (data: any) => {
        this.enablePostMessage = false;
        this.enableIsRead = false;
        this._toastr.success("Message sent successfully");
        this.resetData();
        //this.getmessageDetails();
        this.getSentMessageDetail();
      },
      (err) => {
        this._toastr.error(err);
        this.spinner.hide();
      }
    );
  }

  resetData() {
    this.selectedItems = [];
    this.selectedSalesRepId = [];
    this.IsPriority = false;
    this.file = [];
    this.uploadedFiles = [];
    this.senderMessage = "";
    this.replyMessage = "";
  }

  // Download Message uploaded files
  downloadUploadedFiles(item) {
    //Download Service here
    let object = {
      messageId: item.messageId
    };
    this.messageService
      .GetMessageUploadedFiles(object)
      .subscribe((data: any) => {
        if (data != null) {
          for (let a = 0; a < data.length; a++) {
            const linkSource = "data:application/pdf;base64," + data[a].file;
            const downloadLink = document.createElement("a");
            const fileName = data[a].fileName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        } else {
          this._toastr.info("File Path Not Found");
        }
      });
  }

  //Save Files
  uploadFiles() {
    for (let x = 0; x < this.file.length; x++) {
      let obj = {
        FileName: this.file[x].name,
        Id: 0,
        File: this.file[x].base64Data,
        FileExtension: this.file[x].name.substr(
          this.file[x].name.lastIndexOf(".") + 1
        ),
      };
      this.uploadedFiles.push(obj);
    }
  }

  //Pagination Start
  prevMessages() {
    if (this.paginationService.showNoOfCurrentPage != 1) {
      this.paginationService.prevCase();
      this.pageNumber = [];
      this.pageNumber[0] = true;
      this.currentPage = this.paginationService.pageField[0];
      if(this.IsInbox === true){
        this.getmessageDetails();
      }
      else{
        this.getSentMessageDetail();
      }  
    }
  }

  nextMessages() {
    if (this.paginationService.disabledNextBtn == false) {
      this.pageNumber = [];
      this.paginationService.nextCases();
      this.pageNumber[0] = true;
      this.currentPage = this.paginationService.pageField[0];
      if(this.IsInbox === true){
        this.getmessageDetails();
      }
      else{
        this.getSentMessageDetail();
      }  
    }
  }

  //Method For Pagination
  totalNoOfPages() {
    if(this.IsInbox === true){
      this.paginationData = Number(this.totalMessages / this.pageSize);
    }
    else{
      this.paginationData = Number(this.totalsentMessages / this.pageSize);
    }
    let tempPageData = this.paginationData.toFixed();
    if (Number(tempPageData) < this.paginationData) {
      this.exactPageList = Number(tempPageData) + 1;
      this.paginationService.exactPageList = this.exactPageList;
    } else {
      this.exactPageList = Number(tempPageData);
      this.paginationService.exactPageList = this.exactPageList;
    }
    this.paginationService.pageOnLoad();
    this.pageField = this.paginationService.pageField;
  }

  showNextMessages(page, i) {
    this.postMessage = [];
    this.pageNumber = [];
    this.pageNumber[i] = true;
    this.currentPage = page;
    if(this.IsInbox === true){
      this.getmessageDetails();
    }
    else{
      this.getSentMessageDetail();
    }
  }

  //Setting the records per page -For ex 10,20,50 Active color of page
  setPageSize(size) {
    this.pageNumber = [];
    this.paginationService.temppage = 0;

    if (size == CRMMessagePerPage.first) {
      this.pageSize = size;
      this.firstRow = true;
      this.secondRow = false;
      this.thirdRow = false;
      this.currentPage = 1;
    } else if (size == CRMMessagePerPage.second) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = true;
      this.thirdRow = false;
      this.currentPage = 1;
    } else if (size == CRMMessagePerPage.third) {
      this.pageSize = size;
      this.firstRow = false;
      this.secondRow = false;
      this.thirdRow = true;
      this.currentPage = 1;
    }
    if(this.IsInbox === true){
      this.getmessageDetails();
    }
    else{
      this.getSentMessageDetail();
    }    
    this.pageNumber[0] = true;
  }

  // Sort data ascending and descending order
  setOrder(value) {
    this.order = value;
    this.sortBy = this.sortBy.split("_")[1];
    if (this.sortBy == "DESC") {
      this.sortBy = "ASC";
    } else {
      this.sortBy = "DESC";
    }
    this.sortBy = value.concat("_", this.sortBy);
    if(this.IsInbox === true){
      this.getmessageDetails();
    }
    else{
      this.getSentMessageDetail();
    } 
  }

  //Filter data when user enters sales rep name
  /*onTextChange(e) {
    this.searchText = e;
    this.getmessageDetails();
  }*/

  resetFilter() {
    this.FromDate = null;
    this.ToDate = new Date();
    this.searchText = "";
    this.selectedDate = "";
    if(this.IsInbox === true){
      this.getmessageDetails();
    }
    else{
      this.getSentMessageDetail();
    }    
  }

  //Read Sender Message
  readMessageNow(textMessage, senderName, messageId,isread) {
    this.IsShow = false;
    this.enablePostMessage = true;
    this.enableIsRead = true;
    this.senderMessage = textMessage;
    this.senderName = senderName;
    this.replyMsgId = messageId;
    this.replyMessage = "";
    if(isread === false){
      this.updateMessageDetails(messageId);
    }    
    this.getMessageById(messageId);
  }
  readSentMessage(messageId){
    this.IsShow = true;
    this.enableIsRead = true;
    this.enablePostMessage = true;
    this.replyMsgId = messageId;
    this.replyMessage = "";
    this.getMessageById(messageId);
  }
  getMessageById(messageId){
    this.replyMsgDetail = [];
    this.selectedData = [];
    this.selectedItems = [];
    this.messageService.GetMessageById(messageId).subscribe(
      (data: any) => {
        this.replyMsgDetail = data.replyMessageDetail;
        for(let x=0; x<this.replyMsgDetail.length; x++){
          if(this.replyMsgDetail[x].messageId === messageId){
            for(let i=0; i<this.replyMsgDetail[x].sentMessageDetail.length; i++){
              for(let j=0; j<this.salesRepUsers.length; j++){
                if(this.replyMsgDetail[x].sentMessageDetail[i].userId == this.salesRepUsers[j].userId){
                   let obj = {
                     "userId":this.salesRepUsers[j].userId,
                     "fullName":this.salesRepUsers[j].fullName,
                   }
                   this.selectedData.push(obj);
                }
              }
            }
            this.selectedItems = this.selectedData;
            return;
          }
        }
      });
  }
  viewList(e, tabId) {
    let currentTab = this.replyMsgDetail.find(t => t.messageId == tabId);
    currentTab.show = !currentTab.show;
  }

  replyToSender() {
    this.senderMessage = this.replyMessage;
    this.reply = true;
    this.createMessage();
  }

  // Filter by Date Range
  dateRangeCreated($event) {
    if ($event) {
      this.FromDate = $event[0].toLocaleDateString();
      this.ToDate = $event[1].toLocaleDateString();
      if(this.IsInbox === true){
        this.getmessageDetails();
      }
      else{
        this.getSentMessageDetail();
      } 
    }
  }

  updateMessageDetails(messageId) {
    const obj = {
      IsRead: true,
      ReadOnDate: new Date(),
      MessageId: messageId,
    };
    this.messageService.markedMessageAsRead(obj).subscribe(
      (data: any) => {
        this.getmessageDetails();
      },
      (err) => {
        this._toastr.error(err);
      }
    );
  }
  // by fahimuddin khan - outbox message
  getSentMessageDetail(){
    this.spinner.show();
    this.sentMessage = [];
    let sentObj = {
      PageNo: this.currentPage,
      PageSize: this.pageSize,
      SenderId: localStorage.getItem("userid"),
      SortOrder: this.sortBy,
      FromDate: this.FromDate,
      ToDate: this.ToDate,
    };
    this.messageService.GetSentMessageDetails(sentObj).subscribe(
      (data: any) => {
        this.sentMessage = data.sentCRMMessageList;
        this.totalsentMessages = data.noOfRec;
        this.spinner.hide();
        this.totalNoOfPages();        
      },
      (err) => {
        this._toastr.error(err);
        this.spinner.hide();
      }
    );
  }
  inbox(){
    this.IsInbox = true;
    this.paginationService.temppage = 0;
    this.composeMessage();
    this.getmessageDetails();
  }
  outbox(){
    this.IsInbox = false;
    this.paginationService.temppage = 0;
    this.composeMessage();
    this.getSentMessageDetail();
  }
  

}
