import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConfigService } from '../app.config';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RoleService {
//  private loggedInStatus:false;
  Username: any = localStorage.getItem('username');
  private url="";
  private baseUrl="";
  public isLogin=true;
  
  constructor(public http: HttpClient,private configService:ConfigService) {
    this.baseUrl=configService.getServiceBaseUrl();
}
  // setLoggedIn(value: any) {
  //   this.loggedInStatus = value
  // }
  // get isLoggedIn() {
  //   return this.loggedInStatus
  // }
  addUserRegistration(data): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/AddUser';
    return this.http.post(this.url, data);
  }
  userLogin(object): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/UserLogin';
    return this.http.post(this.url,object);
  }
  // forgotPasswordDetails(data) {
  //   this.url = this.baseUrl + 'api/AppUser/GetForgotPasswordDetails?Username=' + data;
  //   return this.http.get(this.url);
  // }
  changePassword(object): Observable<any> {

    this.url = this.baseUrl + 'api/AppUser/ChangePassword';
    return this.http.post(this.url,object);
  }
  getUserDetailsByUsername(username): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/GetUserDetailsByUsername?Username=' + username;
    return this.http.get(this.url);
  }
  getDentAppUsers(data): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/GetDentAppUsers';
    return this.http.post(this.url,data);
  }
  getDentAppUsersById(id): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/GetDentAppUsersById/?TeethRUsUserId=' + id;
    return this.http.get(this.url);
  }
  getDentAppUsersByIdUpdateProfile(id): Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/GetDentAppUsersByIdUpdateProfile/?TeethRUsUserId=' + id;
    return this.http.get(this.url);
  }
  updateProfile(data): Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/UpdateProfile';
    return this.http.post(this.url,data);
  }
  updateDentAppUserDetailsById(data): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/UpdateDentAppUserDetailsById';
    return this.http.post(this.url, data);
  }
  deleteDentAppUser(object): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/DeleteDentAppUser';
    return this.http.post(this.url,object);
  }
  checkValiedUserOrNot(username): Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/CheckValiedUserOrNot?Username=' + username;
    return this.http.get(this.url);
  }
  getRolePermissions(userid): Observable<any> {
    this.url = this.baseUrl + 'api/AppUser/GetRolePermissions/?TeethRUsUserId=' + userid;
    return this.http.get(this.url);
  }
  getForgotPasswordDetails(username): Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/GetForgotPasswordDetails?Username=' + username;
    return this.http.get(this.url);
  }
  updateForgotPassword(object): Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/UpdateForgotPassword';
    return this.http.post(this.url,object);
  }
  getRoles(type): Observable<any>{
    this.url=this.baseUrl +'api/AppUser/GetRoles?CompanyType='+type;
    return this.http.get(this.url);
  }
  // sendResetPasswordLink(data){
  // this.url = this.baseUrl + 'api/AppUser/SendResetPasswordLink';
  //   return this.http.post(this.url, data);
  // }
  unlockUser(object):Observable<any>{
    this.url = this.baseUrl + 'api/AppUser/unlockUser';
    return this.http.post(this.url,object);
  }
}
