import { Component, OnInit } from '@angular/core';
import {ManagementService} from '../management.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwIfEmpty } from 'rxjs/operators';
@Component({
  selector: 'app-showjobs',
  templateUrl: './showjobs.component.html',
  styleUrls: ['../../home/home.component.css']
})
export class ShowjobsComponent implements OnInit {
  invoicejobs:any=[];
  teethRUsUserId:any;
  Rolename:any;
  constructor(private managementService:ManagementService,public router: Router,private spinner:NgxSpinnerService,) {
   
   }

  ngOnInit() {
if(this.Rolename=localStorage.getItem('RoleName') =='DEALER_ADMIN'){
  this.teethRUsUserId=localStorage.getItem('userid');
}
else{
  this.teethRUsUserId=null;
}
  
   this.showInvoiceJobs();
  }
  showInvoiceJobs(){

    this.spinner.show();
    let object={
      'TeethRUsUserId':this.teethRUsUserId
    }
    this.managementService.showInvoiceJobs(object).subscribe((data:any)=>{   
      this.invoicejobs=data;
      this.spinner.hide();
    })

  }
  viewJobDetails(item){
    this.router.navigate(['management/invoice/invoiceHistory/jobdetails/'+ item.invoiceJobId]);
  }
  restartjob(item){
    this.invoicejobs=[];
    this.spinner.show();
    this.managementService.restartJob(item.invoiceJobId).subscribe((data:any)=>{
     
      this.showInvoiceJobs();
    })
  }
  deletenotinvoicedjob(invoiceJobId)
  {
    
    this.spinner.show();
    this.managementService.deletenotinvoicedjob(invoiceJobId).subscribe((data:any)=>{
     
      this.showInvoiceJobs();
    })
  }
}
